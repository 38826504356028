import React from 'react';

// Define the type for the levels object
const levels: { [key in 1 | 2 | 3 | 4 | 5]: string } = {
    1: '▮▯▯▯▯',
    2: '▮▮▯▯▯',
    3: '▮▮▮▯▯',
    4: '▮▮▮▮▯',
    5: '▮▮▮▮▮'
};

interface BatteryProps {
    level: number; // Accept level as a number
}

const Battery: React.FC<BatteryProps> = ({ level }) => {
    // Ensure level is within the valid range (1 to 5)
    const validLevel = Math.max(1, Math.min(5, Math.round(level))) as 1 | 2 | 3 | 4 | 5;
    const levelString = levels[validLevel];

    // Split the string to apply different colors
    const filledBlocks = levelString.split('').map((block, index) => (
        <span
            key={index}
            style={{
                color: block === '▮' ? 'green' : 'lightgray',
                fontSize: '24px',
                fontFamily: 'monospace'
            }}
        >
            {block}
        </span>
    ));

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {filledBlocks}
        </div>
    );
};

export default Battery;
