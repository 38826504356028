// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-center-loader {
    width: 100%;
    position: relative;
    height: 100%;
}

.spinner-border {
    display: block;
    position: fixed;
    top: calc(50% - (58px / 2));
    right: calc(50% - (58px / 2));
    color: #0d6efd;
}

#backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgb(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/ui/components/mymonx-loader/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,2BAA2B;IAC3B,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mCAAmC;AACvC","sourcesContent":[".text-center-loader {\n    width: 100%;\n    position: relative;\n    height: 100%;\n}\n\n.spinner-border {\n    display: block;\n    position: fixed;\n    top: calc(50% - (58px / 2));\n    right: calc(50% - (58px / 2));\n    color: #0d6efd;\n}\n\n#backdrop {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    width: 100vw;\n    height: 100vh;\n    z-index: 9999;\n    background-color: rgb(0, 0, 0, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
