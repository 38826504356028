import { createAsyncThunk } from "@reduxjs/toolkit";
import MedicalServiceApi from "../../../infrastructure/medical-service";
import { getErrorPayload } from "../../common/helpers/error-helper";
import { ApiError } from "../../common/models/api-error";
import { MedicationResultDto } from "./models/medicationresultdto";
import { ConditionResultDto, CreateUpdateConditionDto } from "./models/conditionresultdto";


export const getMedicationAsync = createAsyncThunk<MedicationResultDto[], string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getMedication",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getMedication(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const postMedicationAsync = createAsyncThunk<string, MedicationResultDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/postMedication",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.postMedication(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const putMedicationAsync = createAsyncThunk<string, MedicationResultDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/putMedication",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.putMedication(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const deleteMedicationAsync = createAsyncThunk<boolean, string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/deleteMedication",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.deleteMedication(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getConditionAsync = createAsyncThunk<ConditionResultDto[], string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/getCondition",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.getCondition(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const postConditionAsync = createAsyncThunk<string, CreateUpdateConditionDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/postCondition",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.postCondition(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const putConditionAsync = createAsyncThunk<string, CreateUpdateConditionDto,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/putCondition",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.putCondition(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const deleteConditionAsync = createAsyncThunk<boolean, string,
    { rejectValue: ApiError | undefined }
>(
    "medicalService/deleteCondition",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await MedicalServiceApi.deleteCondition(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)