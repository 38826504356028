import { Form } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import './style.css';

interface MymonXValidationProps {
    fieldError?: FieldError;
}

const MymonXValidation = ({fieldError}: MymonXValidationProps) => {
    return (
        <>
            {
                fieldError && <Form.Control.Feedback type="invalid">
                                {fieldError.message}
                            </Form.Control.Feedback>
            }
        </>
    )
};

export default MymonXValidation;