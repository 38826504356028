import { useParams } from 'react-router-dom';
//import BpGraph from './bp-graph';
import OxygenGraph from './oxygen-graph';
import TempGraph from './temp-graph';
import GlucoseGraph from './glucose-graph';
import RespiratoryRateGraph from './rr-graph';
import HeartRateGraph from './hr-graph';
import { useEffect, useState } from 'react';
import BpGraph from './bp-graph';
import HealthGraph from './health-graph';
import EcgGraph from './ecg-graph';
import StepGraph from './step-graph';
import SleepGraph from './sleep-graph';

interface HealthMetricsPageProps {
    id?: string;
}


const HealthMetricsPage = ({ id }: HealthMetricsPageProps) => {

    const [refreshKey, setRefreshKey] = useState(0);

    return (
        <>
            {/* <br />
            {
                id && <HeartRateGraph key={`hr-graph-${id}-${refreshKey}`} id={`hr-graph-${id}`} userId={id} />
            } */}
            {
                id && <HealthGraph key={`health-graph-${id}-${refreshKey}`} id={`health-graph-${id}`} userId={id} />
            }
            <br />
            {
                id && <BpGraph key={`bp-graph-${id}-${refreshKey}`} id={`bp-graph-${id}`} userId={id} />
            }
            <br />
            {
                id && <OxygenGraph key={`oxygen-graph-${id}-${refreshKey}`} id={`oxygen-graph-${id}`} userId={id} />
            }
            <br />
            {
                id && <TempGraph key={`temp-graph-${id}-${refreshKey}`} id={`temp-graph-${id}`} userId={id} />
            }
            <br />
            {
                id && <GlucoseGraph key={`glucose-graph-${id}-${refreshKey}`} id={`glucose-graph-${id}`} userId={id} />
            }
            <br />
            {
                id && <RespiratoryRateGraph key={`respiratory-graph-${id}-${refreshKey}`} id={`respiratory-graph-${id}`} userId={id} />
            }
            <br />
            {
                id && <EcgGraph key={`ecg-graph-${id}-${refreshKey}`} id={`ecg-graph-${id}`} userId={id} />
            }
            <br />
            {
                id && <StepGraph key={`step-graph-${id}-${refreshKey}`} id={`step-graph-${id}`} userId={id} />
            }
            <br />
            {
                id && <SleepGraph key={`sleep-graph-${id}-${refreshKey}`} id={`sleep-graph-${id}`} userId={id} />
            }
        </>
    );
};

export default HealthMetricsPage;