import Chart from 'react-apexcharts';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../../../../application/store/useStore';
import { RootState } from '../../../../../../../application/store';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { resetSleepVitalsGraph, setVitalsGraphRequest } from '../../../../../../../application/features/health-service/healthServiceSlice';
import { GraphFilterDto } from '../../../../../../../application/features/health-service/models/graph-filter-dto';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getsleepVitalsGraphAsync } from '../../../../../../../application/features/health-service/healthServiceThunk';
import { VitalsGraphRequestDto } from '../../../../../../../application/features/health-service/models/graph-request-dto';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import { SleepData, SleepStage, SleepStageGraph } from '../../../../../../../application/features/health-service/models/bp-graph-dto';

interface SleepGraphProps {
    id: string;
    userId: string;
}
// Define the type for color mappings
export interface ColorMappings {
    [key: string]: string; // Allows any string as a key
}

const SleepGraph = ({ id, userId }: SleepGraphProps) => {
    const dispatch = useAppDispatch();
    const vitalsGraphRequest = useAppSelector((state: RootState) => state.healthService.vitalsGraphRequest['SLEEP']);
    // const vitalsGraphDto = useAppSelector((state: RootState) => state.healthService.sleepData);
    const { sleepDataDto } = useAppSelector((state: RootState) => state.healthService);
    const graphFilterDto = useAppSelector((state: RootState) => state.healthService.graphFilterDto);
    const [startDate, setStartDate] = useState<Date | null>(moment().startOf('day').toDate());//moment(moment().diff(6, 'days')).startOf('day').toDate());
    const [endDate, setEndDate] = useState<Date | null>(moment().endOf('day').toDate());
    const [selectedPeriod, setSelectedPeriod] = useState('DAY');
    const [xAxisCategories, setxAxisCategories] = useState<string>('');
    // const [deepCategories, setdeepCategories] = useState<number>(0);
    // const [awakeCategories, setAwakeCategories] = useState<number>(0);
    // const [lightCategories, setLightCategories] = useState<number>(0);

    const [sleepData, setSleepData] = useState<SleepData>();
    const [processedData, setProcessedData] = useState<SleepStageGraph[]>([]);
    const [series, setSeries] = useState([]);
    const {
        control,
        handleSubmit,
        setValue,
    } = useForm<VitalsGraphRequestDto>({
        defaultValues: {
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            vital_type: 'SLEEP'

        }
    });

    useEffect(() => {
        dispatch(resetSleepVitalsGraph(''))
    }, [dispatch]);

    const handleFilterClick = (period: any) => {
        if (userId) {
            let start_date = moment().startOf('day').toDate();
            let end_date = moment().endOf('day').toDate();
            let graphType = 'DAY';

            switch (period) {
                case 'DAY':
                    start_date = moment().startOf('day').toDate();
                    graphType = 'DAY';
                    break;
                case 'WEEK':
                    start_date = moment().subtract(6, 'day').startOf('day').toDate();
                    graphType = 'WEEK';
                    break;
                case 'MONTH':
                    start_date = moment().subtract(29, 'day').startOf('day').toDate();
                    graphType = 'MONTH';
                    break;
                case 'YEAR':
                    start_date = moment().subtract(364, 'day').startOf('day').toDate();
                    graphType = 'YEAR';
                    break;
                case 'RANGE':
                    graphType = 'RANGE';

                    break;
                default:
                    break;
            }

            setStartDate(start_date);
            setEndDate(end_date);
            setSelectedPeriod(period);

            if (graphType !== 'RANGE') {
                const request = {
                    userId: userId,
                    vital_type: vitalsGraphRequest.vital_type,
                    graphType: graphType,
                    startDate: moment(start_date).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(end_date).format('YYYY-MM-DD HH:mm:ss'),
                };
                dispatch(setVitalsGraphRequest({ vital_type: request.vital_type, request }));

            }
        }

    };

    useEffect(() => {
        // Reset to initial state when component mounts
        setStartDate(moment().startOf('day').toDate());
        setEndDate(moment().endOf('day').toDate());
        setSelectedPeriod('DAY');

        const defaultRequest = {
            userId: userId,
            vital_type: 'SLEEP',
            graphType: 'DAY',
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch(setVitalsGraphRequest({ vital_type: defaultRequest.vital_type, request: defaultRequest }));

        return () => {
            dispatch(resetSleepVitalsGraph('SLEEP'));
        };
    }, [dispatch, userId]);



    useEffect(() => {
        fetchGraphData();
    }, [dispatch, vitalsGraphRequest])



    const fetchGraphData = () => {
        if (vitalsGraphRequest && vitalsGraphRequest.userId !== undefined && vitalsGraphRequest.userId !== '') {
            const startDate = new Date(vitalsGraphRequest.startDate);
            const endDate = new Date(vitalsGraphRequest.endDate);
            dispatch(getsleepVitalsGraphAsync(vitalsGraphRequest));
        }
    };
    const handleStartDateChange = (dateChange: Date) => {
        setValue("startDate", moment(dateChange).startOf('day').format('YYYY-MM-DD HH:mm:ss'), {
            shouldDirty: true
        });
        setStartDate(dateChange);
    };

    const handleEndDateChange = (dateChange: Date) => {
        setValue("endDate", moment(dateChange).endOf('day').format('YYYY-MM-DD HH:mm:ss'), {
            shouldDirty: true
        });
        setEndDate(dateChange);
    };

    const onSubmit = (requestObj: VitalsGraphRequestDto) => {

        const sDate = moment(requestObj.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const eDate = moment(requestObj.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        if (userId) {

            const request = {
                userId: userId,
                vital_type: requestObj.vital_type,
                graphType: selectedPeriod,
                startDate: sDate,
                endDate: eDate,
            };
            dispatch(setVitalsGraphRequest({ vital_type: requestObj.vital_type, request }));

        }

    }
    function getTimeInSeconds(timeStr: string) {
        const [hours, minutes, seconds] = timeStr.split(':').map(Number);
        return (hours * 3600) + (minutes * 60) + seconds;
    }

    const convertTimeStringToNumber = (timeString: string) => {
        // Remove colons from the string
        const formattedString = timeString.replace(/:/g, '');
        // Convert the resulting string to a number
        return parseInt(formattedString, 10);
    };
    const colorMappings: ColorMappings = {
        DEEP: '#3498db',
        LIGHT: '#e74c3c',
        AWAKE: '#14cc3f'
    };
    useEffect(() => {

        if (sleepDataDto) {
            const sleepDataGraph: SleepStage[] = sleepDataDto[1];
            const sleepDataCards: SleepData = sleepDataDto[0];
            const xAxisCategories = moment(sleepDataCards.sleep_datetime).format('YYYY-MM-DD');
            setSleepData(sleepDataCards);
            setxAxisCategories(xAxisCategories);
            const processedData: SleepStageGraph[] = [];

            // Process the data to get durations for each stage
            for (let i = 0; i < sleepDataGraph.length - 1; i++) {
                const current = sleepDataGraph[i];
                // const next = sleepDataGraph[i + 1];
                // const duration = getTimeInSeconds(next.stage_start_time) - getTimeInSeconds(current.stage_start_time);
                const yaxis = convertTimeStringToNumber(current.stage_start_time);
                processedData.push({
                    stage: current.sleep_stage,
                    duration: yaxis,
                    time: moment(current.stage_start_time).format('HH:mm:ss'),
                });
            }
            setProcessedData(processedData);
            // Prepare the data for the chart
            const processedSeries: any = [];
            const stages = ['AWAKE', 'LIGHT', 'DEEP'];

            processedSeries.push({
                name: 'Sleep Cycle',
                data: processedData.map(item => ({
                    x: item.stage, // X-axis value
                    y: item.duration, // Y-axis value
                    fillColor: colorMappings[item.stage] || '#000000' // Color based on stage
                }))
            })
            setSeries(processedSeries);
            console.log("xaxis : " + xAxisCategories);
            console.log(JSON.stringify(processedData));
        }
    }, [dispatch, sleepDataDto]);
    console.log('Series Data:', JSON.stringify(series));


    const options: ApexOptions = {
        chart: {
            type: 'bar',
            stacked: true,
        },
        xaxis: {
            type: 'category', // Set x-axis to handle categorical data (time)
            categories: ['AWAKE', 'LIGHT', 'DEEP'],  // Leave this empty; categories will be set dynamically by series data
            title: {
                text: 'Time (HH:mm:ss)'
            },
            labels: {
                formatter: function (value) {
                    return value;
                }
            }
        },
        yaxis: {
            title: {
                text: 'Duration (seconds)'
            },
            labels: {
                formatter: (val: number) => {
                    // const hours = Math.floor(val / 3600);
                    // const minutes = Math.floor((val % 3600) / 60);
                    // const seconds = Math.floor(val % 60);

                    // return `${hours}h ${minutes}m ${seconds}s`;
                    return val.toString();
                }
            }
        },
        tooltip: {
            y: {
                formatter: (val: number) => {
                    // const hours = Math.floor(val / 3600);
                    // const minutes = Math.floor((val % 3600) / 60);
                    // const seconds = Math.floor(val % 60);
                    // return `${hours}h ${minutes}m ${seconds}s`;
                    return val.toString();
                }
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '100%',
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'right',
            horizontalAlign: 'center',
        },
        // colors: ['#14cc3f', '#e74c3c', '#3498db'],
        colors: []
    };


    const isGraphDataEmpty = (data: any): boolean => {
        return data === null || data === undefined;
    };
    const formatDuration = (totalMinutes: number) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours} hour(s) ${minutes} minute(s)`;
    };
    return (
        <>
            <div><h2>Sleep </h2></div>
            <div className="d-flex">
                <ButtonGroup>
                    {
                        graphFilterDto && graphFilterDto.map((item: GraphFilterDto, index: number) => {
                            return (
                                <Button
                                    key={`btn-${item.key}-${index}`}
                                    style={{ margin: '1 rem' }}
                                    onClick={() => handleFilterClick(item.value)}
                                    variant={selectedPeriod === item.key ? 'primary' : 'secondary'}
                                >
                                    {item.key}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>

                {
                    selectedPeriod === 'RANGE' ? <>
                        <form className="ms-2 d-flex" id="filter-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue={moment(startDate).format('YYYY-MM-DD')}
                                render={() => (
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={'dd/MM/yyyy'}
                                        maxDate={moment().toDate()}
                                        selected={startDate}
                                        placeholderText="dd/mm/yyyy"
                                        onChange={handleStartDateChange}
                                    />
                                )}
                            />
                            {false && (
                                <Controller
                                    name="endDate"
                                    control={control}
                                    defaultValue={moment(endDate).format('YYYY-MM-DD')}
                                    render={() => (
                                        <DatePicker
                                            className="form-control"
                                            dateFormat={'dd/MM/yyyy'}
                                            maxDate={moment().toDate()}
                                            selected={endDate}
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleEndDateChange}
                                        />
                                    )}
                                />
                            )}

                            <Button variant="primary" type="submit" >
                                Filter
                            </Button>
                        </form>
                    </> : <>

                    </>
                }
            </div>
            {
                sleepData && isGraphDataEmpty(sleepData.deep_sleep_duration || []) ?
                    (<div className="col-12 text-center">
                        <div className="alert alert-warning" role="alert">
                            No data found
                        </div>
                    </div>) :
                    (
                        <>

                            {
                                sleepData && <div>

                                    <div id="chart">
                                        <Chart options={options} series={series} type="bar" height={350} />
                                    </div>
                                    <div id="html-dist"></div>
                                </div>
                            }

                            {
                                <div className="row">
                                    {/* Awake Card */}
                                    <div key='awake' className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                        <div className="card shadow-sm">
                                            <div className="card-header">
                                                <div className="align-items-center d-flex justify-content-between">
                                                    <h5>Awake</h5>

                                                </div>
                                            </div>
                                            <div className="card-body" style={{ backgroundColor: '#14cc3f' }}>
                                                <div className="row">
                                                    <div key='col-awake' className="col-md-12 text-center">
                                                        <p className="small mb-0 bold-heading-large-text" >Total Duration</p>
                                                        <small className="bold-large-text">{formatDuration(sleepData?.awake_duration ?? 0)}</small>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Deep Sleep Card */}
                                    <div key='deep-sleep' className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                        <div className="card shadow-sm">
                                            <div className="card-header">
                                                <div className="align-items-center d-flex justify-content-between">
                                                    <h5>Deep Sleep</h5>
                                                </div>
                                            </div>
                                            <div className="card-body" style={{ backgroundColor: '#3498db' }}>
                                                <div className="row" >
                                                    <div key='col-deep' className="col-md-12 text-center" >
                                                        <p className="small mb-0 bold-heading-large-text">Total Duration</p>
                                                        <small className="bold-large-text">{formatDuration(sleepData?.deep_sleep_duration ?? 0)}</small>
                                                        {/* <div className="border p-3 rounded-3 text-center" >
                                                          
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Light Sleep Card */}
                                    <div key='light-sleep' className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                        <div className="card shadow-sm">
                                            <div className="card-header">
                                                <div className="align-items-center d-flex justify-content-between">
                                                    <h5>Light Sleep</h5>
                                                </div>
                                            </div>
                                            <div className="card-body" style={{ backgroundColor: '#e74c3c' }}>
                                                <div className="row">
                                                    <div key='col-light' className="col-md-12 text-center">
                                                        <p className="small mb-0 bold-heading-large-text">Total Duration</p>
                                                        <small className="bold-large-text">{formatDuration(sleepData?.light_sleep_duration ?? 0)}</small>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </>
                    )
            }
        </>
    )
}

export default SleepGraph;