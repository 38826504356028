import { Badge, Col, Form, FormControl, InputGroup, Row, Stack, Table } from 'react-bootstrap';
import { useEffect } from 'react';
import * as _ from "lodash";
import { useAppDispatch, useAppSelector } from '../../../../../../application/store/useStore';
import { RootState } from '../../../../../../application/store';
import { getUserAlertsAsync } from '../../../../../../application/features/user-profile-service/userProfileServiceThunk';
import { setAlertRequest } from '../../../../../../application/features/user-profile-service/userProfileServiceSlice';
import { UserAlertGridDto } from '../../../../../../application/features/user-profile-service/models/user-profile-grid-dto';
import moment from 'moment';
import { capitalizeWords } from '../../../../../../application/common/helpers/text-helper';
interface ClientAlertsPageProps {
    id?: string;
}


const ClientAlertsPage = ({ id }: ClientAlertsPageProps) => {

    const dispatch = useAppDispatch();
    const { userAlerts, userAlertRequest } = useAppSelector((state: RootState) => state.userProfileService);

    useEffect(() => {
        if (id) {
            dispatch(setAlertRequest({...userAlertRequest, user_id: id}))
        }
    }, [dispatch, id])

    useEffect(() => {
        if (userAlertRequest && userAlertRequest.user_id) {
            dispatch(getUserAlertsAsync(userAlertRequest))
        }
    }, [dispatch, userAlertRequest])

    const _handleSearch = _.debounce((search: string) => {
        dispatch(setAlertRequest({ ...userAlertRequest, filter_by: search }))
    }, 1500, { maxWait: 1500 });

    const getHealthStatus = (health_status: string) => {
        if(health_status.toLowerCase() === 'low risk') 
            return 'success';
        return 'danger';
    }

    return (
        <>
            <div className="Cleintsmain">
                <Row className="mb-3">
                    <Col sm md="6">
                        
                    </Col>
                    <Col sm md="6">
                        <InputGroup>
                            <FormControl
                                type="text"
                                id="searchTerm"
                                name="searchTerm"
                                placeholder="Search user"
                                onChange={(e) => _handleSearch(e.target.value)}
                            />
                            <div className="search-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                            </div>
                        </InputGroup>
                    </Col>
                </Row>

                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Time of Alert</th>
                            <th>Alert Type</th>
                            <th>Health Status</th>
                            <th>Care Location</th>
                            <th>Resolved</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userAlerts && userAlerts.data && userAlerts.data.map((row: UserAlertGridDto, index: number) => (
                            <tr key={index}>
                                <td key={`check-${row.sid}`}>
                                    {index + 1}
                                </td>
                                <td key={`name-${row.sid}`}>
                                    {row.name}
                                </td>
                                <td key={`time-alert-${row.sid}`}>
                                    {moment(row.alert_time).format('HH:mm')}
                                </td>
                                <td key={`alert-type-${row.sid}`}>
                                    {row.alert_type}
                                </td>
                                <td key={`status-td-${row.sid}`}>
                                    <Stack direction="horizontal" gap={2}>
                                        {
                                            <Badge bg={getHealthStatus(row.health_status)} key={`status-${row.sid}`}>
                                                {capitalizeWords(row.health_status)}
                                            </Badge>
                                        }
                                    </Stack>
                                </td>
                                <td key={`location-${row.sid}`}>
                                    {row.location}
                                </td>
                                <td key={`resolved-${row.sid}`}>
                                    <Form.Check
                                        type={"checkbox"}
                                        id={`default-checkbox${row.sid}`}
                                        checked={row.resolved}
                                        //onChange={() => handleCheckboxChange(row)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
};

export default ClientAlertsPage;