import { ConditionResultDto, CreateUpdateConditionDto } from "../application/features/medical-service/models/conditionresultdto";
import { MedicationResultDto } from "../application/features/medical-service/models/medicationresultdto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";


const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);


const getMedication = (userId: string) => {
    return organisationPortalApi.get<MedicationResultDto[]>(`/api/v0/medical/medication?user_profile_sid=${userId}`);
}

const postMedication = (medication: MedicationResultDto) => {
    return organisationPortalApi.post<string>(`/api/v0/medical/medication`, medication);
}

const putMedication = (medication: MedicationResultDto) => {
    return organisationPortalApi.put<string>(`/api/v0/medical/medication`, medication);
}

const deleteMedication = (medication_sid: string) => {
    return organisationPortalApi.delete<boolean>(`/api/v0/medical/medication/${medication_sid}`);
}

const getCondition = (userId: string) => {
    return organisationPortalApi.get<ConditionResultDto[]>(`/api/v0/medical/condition?user_profile_sid=${userId}`);
}
const postCondition = (condition: CreateUpdateConditionDto) => {
    return organisationPortalApi.post<string>(`/api/v0/medical/condition`, condition);
}
const putCondition = (condition: CreateUpdateConditionDto) => {
    return organisationPortalApi.put<string>(`/api/v0/medical/condition`, condition);
}
const deleteCondition = (condition_sid: string) => {
    return organisationPortalApi.delete<boolean>(`/api/v0/medical/condition/${condition_sid}`);
}
const MedicalServiceApi = {
    getMedication,
    postMedication,
    putMedication,
    deleteMedication,
    getCondition,
    postCondition,
    putCondition,
    deleteCondition
}

export default MedicalServiceApi;