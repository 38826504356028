import { Card, Col, Row, Table } from 'react-bootstrap';
import { IoWatchOutline } from "react-icons/io5";
import Image from 'react-bootstrap/Image';
import moment from 'moment';
import { UserProfileDto } from '../../../../../../application/features/user-profile-service/models/user-profile-dto';
import { UserAlertGridDto } from '../../../../../../application/features/user-profile-service/models/user-profile-grid-dto';
import { DeviceResultDto } from '../../../../../../application/features/device-service/models/DeviceResultDto';
import { CreateContactDto } from '../../../../../../application/features/contact-service/models/create-contact-dto';
import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import MymonXDialog from '../../../../../components/mymonx-dialog';
import DevicesConfiguration from '../../../devices/device-configuration';
import { useAppDispatch, useAppSelector } from '../../../../../../application/store/useStore';
import { getUserProfileImageAsync } from '../../../../../../application/features/user-profile-service/userProfileServiceThunk';
import { RootState } from '../../../../../../application/store';


interface ClientOverViewPageProps {
    client: UserProfileDto;
    userAlert: UserAlertGridDto;
    device: DeviceResultDto | null;
    contactList?: CreateContactDto[] | undefined;
}


const ClientOverviewPage = ({ client, userAlert, device, contactList }: ClientOverViewPageProps) => {
    const [isClose, setisClose] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [deviceConfigureAddForm, setdeviceConfigureAddForm] = useState<boolean>(false);
    const { userGetProfileImage } = useAppSelector((state: RootState) => state.userProfileService);
    useEffect(() => {
        if (client.sid !== undefined && client.sid !== '') {
            dispatch(getUserProfileImageAsync(client.sid ?? ""))
        }
    }, [dispatch, client.sid]);
    const handleComplete = async () => {
        setdeviceConfigureAddForm(false);
    };


    return (
        <>
            <Row>
                <Col sm={12} md={3} lg={3}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Health Status</Card.Title>
                            <Card.Text>
                                {client.health_status}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Date of Birth</Card.Title>
                            <Card.Text>
                                {moment(client.date_of_birth).format('DD-MM-yyyy')} {(moment().diff(client.date_of_birth, 'years'))}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Nationality</Card.Title>
                            <Card.Text>
                                {client.nationality_name}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    {/* <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Diabetes Status</Card.Title>
                            <Card.Text>
                                Diabetic (Type 1)
                            </Card.Text>
                        </Card.Body>
                    </Card> */}
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Height & Weight</Card.Title>
                            <Card.Text>
                                {`${client.height} ${client.height_unit}  ${client.weight} ${client.weight_unit}`}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Location</Card.Title>
                                    <Card.Text>
                                        {client.location_name}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Care Team</Card.Title>
                                    <Card.Text>
                                        {client.care_team_name}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Emergency Contacts</Card.Title>
                                    <Card.Text>
                                        <Row>
                                            <Col>
                                                <Table responsive bordered>
                                                    <thead >
                                                        <tr>

                                                            <th>
                                                                Name
                                                            </th>
                                                            <th>
                                                                Phone Number
                                                            </th>
                                                            <th>
                                                                Sos Contact
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* .filter((contact: CreateContactDto) => !contact.is_sos) */}
                                                        {
                                                            contactList && contactList
                                                                .map((contact: CreateContactDto, index: number) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{`${contact.first_name} ${contact.last_name}`}</td>
                                                                            <td>{contact.mobile_prefix} {contact.mobile_number}</td>
                                                                            <td>{capitalize(contact.is_sos.toString())}</td>
                                                                            {/* <td align="center">
                                                                                <span className="pe-2">
                                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUpdate(contact)} >
                                                                                        <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                                                    </svg>
                                                                                </span>
                                                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(contact.sid)} >
                                                                                    <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                                                </svg>
                                                                            </td> */}
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            {/* {
                                                contacts && contacts.map((contact: CreateContactDto, index: number) => {
                                                    return (
                                                        <Col sm={12} md={6} lg={6} key={index}>
                                                            <div>
                                                                {`${contact.first_name} ${contact.last_name}`}
                                                                <p key={`mobile-${index}`}>
                                                                    <span>
                                                                        {`${contact.mobile_prefix} ${contact.mobile_number}`}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            } */}
                                            {/* <Col sm={12} md={6} lg={6}>
                                                <div>
                                                    Paul Ngo
                                                    <p>
                                                        <span>Relationship - Son</span>
                                                    </p>
                                                    <p>
                                                        <span>paul.ngo@gmail.com</span>
                                                    </p>
                                                    <p>
                                                        <span>+ 44 7656390136</span>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <div>
                                                    Sarah Ngo
                                                    <p>
                                                        <span>Relationship - Wife</span>
                                                    </p>
                                                    <p>
                                                        <span>sarah.ngo@gmail.com</span>
                                                    </p>
                                                    <p>
                                                        <span>+ 44 7545289025</span>
                                                    </p>
                                                </div>
                                            </Col> */}
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>
                                        <Row>
                                            <Col sm={12} md={11} lg={11}>
                                                mymonX Device
                                            </Col>
                                            <Col sm={12} md={1} lg={1}>
                                                <IoWatchOutline size={32} />
                                            </Col>
                                        </Row>
                                    </Card.Title>
                                    <Card.Text>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <table>
                                                    <tr>
                                                        <td style={{ padding: 10 }}>
                                                            Serial number:
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            {
                                                                device && device.serial_no && <span>
                                                                    {device.serial_no}
                                                                </span>
                                                            }

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: 10 }}>
                                                            Set-up date:
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            {
                                                                device && device.setup_date && <span>
                                                                    {moment(device.setup_date).format('DD/MM/YYYY')}
                                                                </span>
                                                            }

                                                        </td>
                                                    </tr>
                                                    <button className='btn btn-success me-2 mb-2' onClick={() => setdeviceConfigureAddForm(true)}>Configure</button>
                                                </table>
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '180px', maxHeight: '171px', overflow: 'hidden' }}>
                                                    <Image src={"https://i.imgur.com/aV8MzJ4.png"} className='img-fluid fit-image' rounded />
                                                </div> */}
                                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '180px', maxHeight: '171px', overflow: 'hidden' }}>
                                                    <Image
                                                        src={`data:image/png;base64,${userGetProfileImage?.avatar}`}
                                                        className="img-fluid fit-image"
                                                        rounded
                                                    />
                                                </div> */}
                                                {userGetProfileImage?.avatar ? (
                                                    <Image
                                                        src={`data:image/png;base64,${userGetProfileImage?.avatar}`}
                                                        className="img-fluid fit-image"
                                                        rounded
                                                        alt="Profile"
                                                    />
                                                ) : (
                                                    <span>No profile image</span>
                                                )}

                                            </Col>
                                        </Row>

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Recent Alerts</Card.Title>
                                    <Card.Text>
                                        <table>
                                            <tr>
                                                <td style={{ padding: 10 }}>
                                                    {userAlert && userAlert.alert_type && <span>
                                                        {userAlert.alert_type}
                                                    </span>}
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    {userAlert && userAlert.alert_time && (
                                                        <>
                                                            <span>
                                                                {moment(userAlert.alert_time).format('DD/MM/YYYY')}
                                                            </span>
                                                            <br />
                                                            <span>
                                                                {moment(userAlert.alert_time).format('HH:mm')}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        </table>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {client?.first_name && client?.last_name ? (
                <MymonXDialog

                    title="Device Configuration"
                    show={deviceConfigureAddForm}
                    size="xl"
                    onHide={() => {
                        setisClose(true);
                        setdeviceConfigureAddForm(false);
                    }}
                >
                    <DevicesConfiguration
                        onHide={() => {
                            setisClose(true);
                            setdeviceConfigureAddForm(false);
                        }}
                        onComplete={handleComplete}
                        device={device}
                        isClose={isClose}

                    />
                </MymonXDialog>
            ) : (<MymonXDialog

                title="Device Configuration"
                show={deviceConfigureAddForm}
                size="lg"
                onHide={() => {
                    // setisClose(true);
                    setdeviceConfigureAddForm(false);
                }}
            >
                <div>This device is not assigned to any user</div>
            </MymonXDialog>
            )}
        </>
    );
};

export default ClientOverviewPage;
