import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../common/enums/api-status";
import { BaseState } from "../../common/models/base-state";
import { VitalsGraphRequestDto } from "../health-service/models/graph-request-dto";
import { getCurrentDateStartOfDay, getCurrentDateTime } from "../../common/helpers/date-helper";
import { GraphFilterDto } from "../health-service/models/graph-filter-dto";
import { ApiError } from "../../common/models/api-error";

import { getActivityGraphAsync } from "./activityServiceThunk";
import { StepDataResponse } from "./models/steps-graph-dto";


export interface HealthServiceState extends BaseState {
    vitalsGraphRequest: Record<string, VitalsGraphRequestDto>;
    graphFilterDto: GraphFilterDto[];
    stepDataDto?: StepDataResponse;
}

const initialState: HealthServiceState = {
    status: ApiStatus.IDLE,
    stepDataDto: undefined,
    vitalsGraphRequest: {
        'STEPS': {
            userId: '',
            vital_type: 'STEPS',
            graphType: 'DAY',
            startDate: getCurrentDateStartOfDay(),
            endDate: getCurrentDateTime(),
        }
    },
    graphFilterDto: [
        {
            key: 'DAY',
            value: 'DAY'
        },
        {
            key: 'WEEK',
            value: 'WEEK'
        },
        {
            key: 'MONTH',
            value: 'MONTH'
        },
        {
            key: 'YEAR',
            value: 'YEAR'
        },
        {
            key: 'RANGE',
            value: 'RANGE'
        }
    ]
}

export const ActivityServiceSlice = createSlice({
    name: 'activityService',
    initialState,
    reducers: {
        setVitalsGraphRequest(state, action: PayloadAction<{ vital_type: string, request: VitalsGraphRequestDto }>) {
            state.vitalsGraphRequest[action.payload.vital_type] = action.payload.request;
        },
        resetStepVitalsGraph(state, action: PayloadAction<string>) {
            delete state.stepDataDto;
        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(getActivityGraphAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getActivityGraphAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.stepDataDto = action.payload;
            })
            .addCase(getActivityGraphAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload as ApiError;
            });
    }
})


export const {
    setVitalsGraphRequest, resetStepVitalsGraph
} = ActivityServiceSlice.actions;
export default ActivityServiceSlice.reducer;