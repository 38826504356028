import { createAsyncThunk } from "@reduxjs/toolkit";
import { GlucoseStatsDto } from "./models/glucose-stats-dto";
import { ApiError } from "../../common/models/api-error";
import HealthServiceApi from "../../../infrastructure/health-service";
import { getErrorPayload } from "../../common/helpers/error-helper";
import { GlucoseGraphRequestDto, GraphRequestDto, OxygenGraphRequestDto, RespiratoryRateGraphRequestDto, TempGraphRequestDto, VitalsGraphRequestDto } from "./models/graph-request-dto";
import { BpGraphDto, EcgResponseDto, GlucoseGraphDto, HrGraphDto, OxygenGraphDto, RespiratoryRateGraphDto, SleepData, SleepDataResponse, SleepStage, TempGraphDto, VitalsGraphDto, VitalsSummaryDto } from "./models/bp-graph-dto";

export const getGlucoseStatsAsync = createAsyncThunk<GlucoseStatsDto, string,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getGlucose",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getGlucoseStats(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)


export const getBpGraphAsync = createAsyncThunk<BpGraphDto, GraphRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getBpGraph",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getBpGraphStats(credentials.userId, credentials.startDate, credentials.endDate, credentials.graphType);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getOxygenGraphAsync = createAsyncThunk<OxygenGraphDto, OxygenGraphRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getOxygenGraph",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getOxygenGraphStats(credentials.userId, credentials.startDate, credentials.endDate, credentials.graphType);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getHrGraphAsync = createAsyncThunk<HrGraphDto, GraphRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getHrGraph",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getHrGraphStats(credentials.userId, credentials.startDate, credentials.endDate, credentials.graphType);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getTempGraphAsync = createAsyncThunk<TempGraphDto, TempGraphRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getTempGraph",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getTempGraphStats(credentials.userId, credentials.startDate, credentials.endDate, credentials.graphType);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getGlucoseGraphAsync = createAsyncThunk<GlucoseGraphDto, GlucoseGraphRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getGlucoseGraph",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getGlucoseGraph(credentials.userId, credentials.startDate, credentials.endDate, credentials.graphType);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)

export const getRespiratoryRateGraphAsync = createAsyncThunk<RespiratoryRateGraphDto, RespiratoryRateGraphRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getRespiratoryRateGraph",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getRespiratoryRateGraph(credentials.userId, credentials.startDate, credentials.endDate, credentials.graphType);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const getVitalsSummaryAsync = createAsyncThunk<VitalsSummaryDto[], string,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getVitalsSummary",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getVitalsSummary(credentials);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
)
export const getVitalsGraphAsync = createAsyncThunk<
    { vital_type: string; data: VitalsGraphDto },
    VitalsGraphRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "healthService/getVitals",
    async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getVitals(
                credentials.userId,
                credentials.vital_type,
                credentials.graphType,
                credentials.startDate,
                credentials.endDate
            );
            return fulfillWithValue({ vital_type: credentials.vital_type, data: response.data });
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
// export const gethrVitalsGraphAsync = createAsyncThunk<VitalsGraphDto, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
//     "healthService/gethrVitals",
//     async (credentials, { rejectWithValue, fulfillWithValue }) => {
//         try {
//             const response = await HealthServiceApi.gethrVitals(credentials.userId, credentials.vital_type, credentials.graphType, credentials.startDate, credentials.endDate);
//             return fulfillWithValue(response.data);
//         } catch (error) {
//             const apiError = getErrorPayload(error);
//             return rejectWithValue(apiError);
//         }
//     }
// );
// export const getbpSysVitalsGraphAsync = createAsyncThunk<VitalsGraphDto, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
//     "healthService/getbpSysVitals",
//     async (credentials, { rejectWithValue, fulfillWithValue }) => {
//         try {
//             const response = await HealthServiceApi.getbpSysVitals(credentials.userId, credentials.vital_type, credentials.graphType, credentials.startDate, credentials.endDate);
//             return fulfillWithValue(response.data);
//         } catch (error) {
//             const apiError = getErrorPayload(error);
//             return rejectWithValue(apiError);
//         }
//     }
// );
// export const getbpDysVitalsGraphAsync = createAsyncThunk<VitalsGraphDto, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
//     "healthService/getbpDysVitals",
//     async (credentials, { rejectWithValue, fulfillWithValue }) => {
//         try {
//             const response = await HealthServiceApi.getbpDysVitals(credentials.userId, credentials.vital_type, credentials.graphType, credentials.startDate, credentials.endDate);
//             return fulfillWithValue(response.data);
//         } catch (error) {
//             const apiError = getErrorPayload(error);
//             return rejectWithValue(apiError);
//         }
//     }
//);
// export const getrespiratoryVitalsGraphAsync = createAsyncThunk<VitalsGraphDto, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
//     "healthService/getrespiratoryVitals",
//     async (credentials, { rejectWithValue, fulfillWithValue }) => {
//         try {
//             const response = await HealthServiceApi.getrespiratoryVitals(credentials.userId, credentials.vital_type, credentials.graphType, credentials.startDate, credentials.endDate);
//             return fulfillWithValue(response.data);
//         } catch (error) {
//             const apiError = getErrorPayload(error);
//             return rejectWithValue(apiError);
//         }
//     }
// );
// export const gettempVitalsGraphAsync = createAsyncThunk<VitalsGraphDto, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
//     "healthService/gettempVitals",
//     async (credentials, { rejectWithValue, fulfillWithValue }) => {
//         try {
//             const response = await HealthServiceApi.gettempVitals(credentials.userId, credentials.vital_type, credentials.graphType, credentials.startDate, credentials.endDate);
//             return fulfillWithValue(response.data);
//         } catch (error) {
//             const apiError = getErrorPayload(error);
//             return rejectWithValue(apiError);
//         }
//     }
// );
// export const getglucoseVitalsGraphAsync = createAsyncThunk<VitalsGraphDto, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
//     "healthService/getglucoseVitals",
//     async (credentials, { rejectWithValue, fulfillWithValue }) => {
//         try {
//             const response = await HealthServiceApi.getglucoseVitals(credentials.userId, credentials.vital_type, credentials.graphType, credentials.startDate, credentials.endDate);
//             return fulfillWithValue(response.data);
//         } catch (error) {
//             const apiError = getErrorPayload(error);
//             return rejectWithValue(apiError);
//         }
//     }
// );
// export const getoxygenVitalsGraphAsync = createAsyncThunk<VitalsGraphDto, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
//     "healthService/getoxygenVitals",
//     async (credentials, { rejectWithValue, fulfillWithValue }) => {
//         try {
//             const response = await HealthServiceApi.getoxygenVitals(credentials.userId, credentials.vital_type, credentials.graphType, credentials.startDate, credentials.endDate);
//             return fulfillWithValue(response.data);
//         } catch (error) {
//             const apiError = getErrorPayload(error);
//             return rejectWithValue(apiError);
//         }
//     }
// );
export const getsleepVitalsGraphAsync = createAsyncThunk<any, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
    "healthService/getsleepVitals",
    async (credentials, { rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getsleepVitals(credentials.userId, credentials.startDate);
            // Assuming response.data has to be transformed into SleepDataResponse

            // const transformedResponse: SleepDataResponse = transformResponseToSleepDataResponse(response.data);

            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const getecgVitalsGraphAsync = createAsyncThunk<EcgResponseDto, VitalsGraphRequestDto, { rejectValue: ApiError | undefined }>(
    "healthService/getEcgVitals",
    async (credentials, { rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await HealthServiceApi.getEcgVitals(credentials.userId);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
