import React, { useEffect, useState } from 'react';

import * as _ from "lodash";
import { Row, Col, InputGroup, FormControl, Table, Form, Stack, Badge, Button } from "react-bootstrap";
import DevicesConfiguration from './device-configuration';
import { useAppDispatch, useAppSelector } from '../../../../application/store/useStore';
import { RootState } from '../../../../application/store';
import { getOrgFromJwt } from '../../../../application/common/helpers/jwt-helper';
import { disableDeviceAsync, getDevicesAsync, unAssignDeviceAsync } from '../../../../application/features/device-service/deviceServiceThunk';
import { DeviceResultDto } from '../../../../application/features/device-service/models/DeviceResultDto';
import { Link } from 'react-router-dom';
import { setdevicesResult } from '../../../../application/features/device-service/deviceServiceSlice';
import { PageAndFilterRequestDto } from '../../../../application/common/models/page-and-filter-request-dto';
import MymonXDialog from '../../../components/mymonx-dialog';
import MapDeviceForm from '../client-users/map-device-form/map-device-form';
import DeviceAssignForm from './device-assign';
import { addWarningNotification } from '../../../../application/features/common/notificationSlice';


const DevicesComp = () => {
    const dispatch = useAppDispatch();
    const { devices } = useAppSelector((state: RootState) => state.deviceService);
    const [selectedRow, setSelectedRow] = useState<DeviceResultDto | null>(null);
    const [deviceConfigureAddForm, setdeviceConfigureAddForm] = useState<boolean>(false);
    const [showAssignDialog, setShowAssignDialog] = useState<boolean>(false);
    const [isClose, setisClose] = useState<boolean>(false);



    const org_id = getOrgFromJwt();

    useEffect(() => {
        fetchDevicesData();
    }, [dispatch, org_id])

    const fetchDevicesData = async () => {
        if (org_id) {
            const deviceDto = { org_id: org_id }; // Create DeviceDto object
            try {
                await dispatch(getDevicesAsync(deviceDto)).unwrap();
            } catch (error) {
                console.error('Error fetching devices:', error);
            }
        }
    };

    const handleComplete = async () => {
        setdeviceConfigureAddForm(false);
        await fetchDevicesData(); // Assuming fetchDevicesData is an async function
    };
    const handleCheckboxChange = (row: any) => {

        if (selectedRow?.sid === row.sid) {
            setSelectedRow(null); // Unselect the row if it is already selected
        } else {
            setSelectedRow(row); // Select the new row
        }
    }
    const unAssignDevice = async () => {
        await dispatch(unAssignDeviceAsync(selectedRow?.imei ?? '')).unwrap();
        await fetchDevicesData();
    };
    const disableDevice = async () => {
        await dispatch(disableDeviceAsync(selectedRow?.imei ?? '')).unwrap();
        await fetchDevicesData();
    };

    return (
        <>
            <div className="Cleintsmain">
                <Row className="mb-3">
                    <Col sm md="6">
                        <div>
                            <button className='btn btn-success me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false} onClick={() => setdeviceConfigureAddForm(true)}>Configure</button>
                            {/* <button className='btn btn-primary me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false}>Edit</button> */}
                            <button className='btn btn-secondary me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false} onClick={() => setShowAssignDialog(true)}>Assign</button>
                            <button className='btn btn-danger me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false} onClick={() => unAssignDevice()} >Un-Assign</button>
                            <button className='btn btn-danger me-2 mb-2' disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false} onClick={() => disableDevice()}>Disable</button>

                        </div>
                    </Col>
                    <Col sm md="6">
                        <InputGroup>
                            <FormControl
                                type="text"
                                id="searchTerm"
                                name="searchTerm"
                                placeholder="Search..."

                            />
                            <div className="search-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                            </div>
                        </InputGroup>
                    </Col>
                </Row>
                <div>
                    <MymonXDialog
                        title="Map Device"
                        show={showAssignDialog}
                        size="lg"
                        onHide={() => setShowAssignDialog(false)}
                    >
                        <DeviceAssignForm
                            onHideUpdateForm={() => setShowAssignDialog(false)}
                            onCompleteUpdateForm={() => {
                                setShowAssignDialog(false);
                                dispatch(fetchDevicesData);
                            }}
                            device_id={selectedRow?.sid}
                        />
                    </MymonXDialog>
                </div>
                <Table responsive bordered>
                    <thead key={`client-header`}>
                        <tr key={`client-header-row`}>
                            <th></th>
                            <th>
                                Name
                            </th>
                            <th>
                                Device Type
                            </th>
                            <th>
                                Serial No.
                            </th>
                            <th>
                                IMEI
                            </th>
                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody key={`client-body`}>
                        {devices?.devices &&
                            devices.devices?.map((row: DeviceResultDto, index: number) => (
                                <tr key={`row-${index}`}>
                                    <td key={`action-${index}`}>
                                        <Form>
                                            <Form.Check
                                                type={"checkbox"}
                                                id={`default-checkbox${row.sid}`}
                                                checked={row.sid === selectedRow?.sid}
                                                onChange={() => handleCheckboxChange(row)}
                                            />
                                        </Form>
                                    </td>
                                    <td key={`name-${index}`}>

                                        {row.first_name && row.last_name && (
                                            <Link to={`/portal/Client/`}>
                                                {`${row.first_name} ${row.last_name}`}
                                            </Link>
                                        )}
                                    </td>
                                    <td key={`devicetype-${index}`}>
                                        {row.device_type}
                                    </td>
                                    <td key={`serial-no-${index}`}>
                                        {row.serial_no}
                                    </td>

                                    <td key={`imei-${index}`}>
                                        {row.imei}
                                    </td>
                                    <td key={`status-${index}`}>
                                        {row.status}
                                    </td>
                                    {/* <td key={`setup-date-${index}`}>
                                        {moment(row.setup_date).format('DD/MM/YYYY HH:mm:ss')}
                                    </td>
                                     */}

                                </tr>
                            ))}
                    </tbody>
                </Table>

            </div>
            {selectedRow?.first_name && selectedRow?.last_name ? (
                <MymonXDialog

                    title="Device Configuration"
                    show={deviceConfigureAddForm}
                    size="xl"
                    onHide={() => {
                        setisClose(true);
                        setdeviceConfigureAddForm(false);
                    }}
                >
                    <DevicesConfiguration
                        onHide={() => {
                            setisClose(true);
                            setdeviceConfigureAddForm(false);
                        }}
                        onComplete={handleComplete}
                        device={selectedRow}
                        isClose={isClose}

                    />
                </MymonXDialog>
            ) : (<MymonXDialog

                title="Device Configuration"
                show={deviceConfigureAddForm}
                size="lg"
                onHide={() => {
                    // setisClose(true);
                    setdeviceConfigureAddForm(false);
                }}
            >
                <div>This device is not assigned to any user</div>
            </MymonXDialog>
            )}

        </>
    );
}

export default DevicesComp;

