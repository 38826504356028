import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../../application/store";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { useEffect } from "react";
import { Alert, Button, Col, Form, Row, Card } from "react-bootstrap";
import { LoginCredentialsDto } from "../../../../application/features/security-service/models/login-credentials-dto";
import { loginAsync } from "../../../../application/features/security-service/securityServiceThunk";
import "../Auth.css";
import logo from "../../../../asstes/images/logo.svg";

const Login = () => {

    const { currentUser, apiError } = useAppSelector((state: RootState) => state.securityService);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser !== undefined && currentUser.email != '') {
            if (currentUser.roles[0].name === 'org_client') {
                navigate('/consumer/');
            } else {
                navigate('/portal/');
            }

        }
    }, [currentUser]);
    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm<LoginCredentialsDto>({
        defaultValues: {
            username: '',
            password: ''
        }
    })
    const onSubmit = (loginCredentials: LoginCredentialsDto) => {
        dispatch(loginAsync(loginCredentials)).then((res: any) => {
            if (res && res.payload) {
                window.location.reload()
            }
        })
    };
    return (
        <>
            <Row className="h-100">
                <Col className="col-12" >
                    <Row className="justify-content-end">
                        <Col className="col-lg-4 col-md-5 text-end">
                            <a href="#"><img className="img-fluid" src={logo} alt="Logo" /></a>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-12" >
                    <Row>
                        <Col className="col-lg-4 col-md-6">
                            <Card >
                                <Card.Body>
                                    <h3 className="text-center my-3">Login</h3>
                                    <Form onSubmit={handleSubmit(onSubmit)} id="loginForm">
                                        <Form.Group className="mb-3" controlId="loginForm.username">
                                            <Form.Label>Email address</Form.Label>
                                            <Controller
                                                name="username"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'username is required'
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control type="email" onChange={onChange} value={value} placeholder="name@example.com" />
                                                )}
                                            />

                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="loginForm.password">
                                            <Form.Label>Password </Form.Label>
                                            <Controller
                                                name="password"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'password is required'
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Form.Control type="password" onChange={onChange} value={value} placeholder="*******" />
                                                )}
                                            />
                                        </Form.Group>
                                        <Button as="input" className="w-100 mb-3" variant="primary" type="submit" value="Login" />{' '}
                                    </Form>
                                    <div className="text-center">
                                        <a href="/auth/forgot-password" className="text-center text-decoration-none my-2 d-block fs-6"> Forgot Username or Password</a>
                                        <a href="/auth/register" className="text-center text-decoration-none my-2 d-block fs-5">Register New Organisation</a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Login;