import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../application/store/useStore";
import MymonXValidation from "../../../../components/mymonx-validation";
import './style.css';
import { useEffect } from "react";
import { getRoleDropdownAsync } from "../../../../../application/features/role-service/roleServiceThunk";
import { getRoleFromJwt } from "../../../../../application/common/helpers/jwt-helper";
import { RootState } from "../../../../../application/store";
import { getCountriesAsync } from "../../../../../application/features/staticData-service/saticDataServiceThunk";
import { RoleDto } from "../../../../../application/features/role-service/models/role-grid-dto";
import { CreateUserDto } from "../../../../../application/features/user-profile-service/models/create-user-dto";
import { RegisterClientResultDto, RegisterUserCredentialsDto } from "../../../../../application/features/security-service/models/register-credentials-dto";
import { registerUserAsync } from "../../../../../application/features/security-service/securityServiceThunk";
import MymonXReqAsteric from "../../../../components/mymonx-req-asteric";


interface CreateUserFormProps {
    onHide?(): void;
    onComplete?(): void;
}

const CreateUserForm = ({ onHide, onComplete }: CreateUserFormProps) => {
    const dispatch = useAppDispatch();
    const role_id = getRoleFromJwt();
    const { roleDropdown } = useAppSelector((state: RootState) => state.roleService);
    const { currentUser } = useAppSelector((state: RootState) => state.securityService);


    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<CreateUserDto>({
        defaultValues: {
            sid: "",
            email: "",
            first_name: "",
            last_name: "",
            role: "",
            org_id: ""
        }
    });

    useEffect(() => {
        if (role_id) {
            dispatch(getRoleDropdownAsync(role_id));
        }
    }, [dispatch, role_id]);

    useEffect(() => {
        dispatch(getCountriesAsync({ page: 1, per_page: 100 }))
    }, [dispatch])

    const onSubmit = (request: CreateUserDto) => {
        const randomFourDigitNumber = Math.floor(1000 + Math.random() * 9000);
        const registerObj = {
            username: request.email,
            first_name: request.first_name,
            last_name: request.last_name,
            password: `Secu${request.first_name}@${randomFourDigitNumber}P`,
            organisation_id: currentUser?.org_id,
            role_id: request.role
        } as RegisterUserCredentialsDto;

        dispatch(registerUserAsync(registerObj)).then((res: any) => {
            if (res && res.payload) {
                const userId = (res.payload as RegisterClientResultDto).user_sid;
                if (userId) {
                    if (onComplete) {
                        onComplete();
                    }
                }
            }
        })
    }

    return (
        <>
            <Form id="client-create-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Row} className="mb-3" controlId="formFirstName">
                    <Form.Label column sm="3">
                        First Name <MymonXReqAsteric />
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            placeholder="first name"
                            {...register('first_name', {
                                required: {
                                    value: true,
                                    message: 'first name is required'
                                }
                            })}
                            className={`${!!errors.first_name ? 'has-validation' : ''}`}
                        />
                        <MymonXValidation fieldError={errors.first_name} ></MymonXValidation>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formLastName">
                    <Form.Label column sm="3">
                        Last Name <MymonXReqAsteric />
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            placeholder="last name"
                            {...register('last_name', {
                                required: {
                                    value: true,
                                    message: 'last name is required'
                                }
                            })}
                            className={`${!!errors.last_name ? 'has-validation' : ''}`}
                        />
                        <MymonXValidation fieldError={errors.last_name} ></MymonXValidation>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId={`client-create-form.email`}>
                    <Form.Label column sm={12} md={3}>
                        Email <MymonXReqAsteric />
                    </Form.Label>
                    <Col sm={12} md={9}>
                        <Form.Control
                            type="text"
                            placeholder="email"
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: 'email is required'
                                }
                            })}
                            className={`${!!errors.email ? 'has-validation' : ''}`}
                        />
                        <MymonXValidation fieldError={errors.email} ></MymonXValidation>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId={`client-create-form.location`}>
                    <Form.Label column sm={12} md={3}>
                        User Role: <MymonXReqAsteric />
                    </Form.Label>
                    <Col sm={12} md={9}>
                        <Controller
                            name="role"
                            control={control}
                            rules={{ required: "role is required" }}
                            render={({ field }) => (
                                <Form.Select {...field} aria-label="Default select role">
                                    <option>Select Role</option>
                                    {roleDropdown.map((item: RoleDto) => (
                                        <option key={item.sid} value={item.sid}>{item.name}</option>
                                    ))}
                                </Form.Select>
                            )}
                        />
                        <MymonXValidation fieldError={errors.role} />
                    </Col>
                </Form.Group>
                
                <Form.Group as={Row} className="mb-3" controlId="formSubmitButtons">

                    <Col sm="12" className="text-end">
                        <Button variant="secondary" type="button" onClick={onHide} >
                            Cancel
                        </Button>{' '}
                        <Button variant="primary" type="submit" >
                            Create
                        </Button>
                    </Col>

                </Form.Group>
            </Form>
        </>
    )
}

export default CreateUserForm;