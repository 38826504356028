import { Badge, Col, Form, FormControl, InputGroup, Row, Stack, Table } from "react-bootstrap";
import * as _ from "lodash";
import MymonXDialog from "../../../components/mymonx-dialog";
import MymonXConfirmationDialog from "../../../components/mymonx-confirmation-dialog";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { RootState } from "../../../../application/store";
import { setUsersRequest } from "../../../../application/features/users-service/usersServiceSlice";
import { deleteUserAsync, getUserAsync, getUsersAsync } from "../../../../application/features/users-service/usersServiceThunk";
import { UsersGridDto } from "../../../../application/features/users-service/models/users-dto";
import CreateUserForm from "./create-user";
import UpdateUserForm from "./update-user";

const Users = () => {
    const dispatch = useAppDispatch();
    const { users, usersRequest } = useAppSelector((state: RootState) => state.usersService);
    const [userAddForm, setUserAddForm] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<UsersGridDto | null>(null);
    const [showClientEditForm, setShowClientEditForm] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    useEffect(() => {
        if (usersRequest) {
            dispatch(getUsersAsync(usersRequest))
        }
    }, [dispatch, usersRequest])

    const handleCheckboxChange = (row: any) => {

        if (selectedRow?.sid === row.sid) {
            setSelectedRow(null); // Unselect the row if it is already selected
        } else {
            setSelectedRow(row); // Select the new row
        }
    }

    const _handleSearch = _.debounce((search: string) => {
        dispatch(setUsersRequest({ ...usersRequest, filter_by: search }))
    }, 1500, { maxWait: 1500 });

    const editForm = () => {
        if (selectedRow && selectedRow.sid) {
            dispatch(getUserAsync(selectedRow.sid));
            setShowClientEditForm(true)
        }
    }

    const onDeleteHide = () => {
        setShowDeleteDialog(false);
    }

    const onDeleteConfirm = () => {
        var request = { ...selectedRow };

        if (request.sid) {
            dispatch(deleteUserAsync(request.sid)).then(res => {
                if (res && res.payload && res.payload) {
                    if ((res.payload as boolean) === true) {
                        setShowDeleteDialog(false);
                        dispatch(getUsersAsync(usersRequest));
                    }
                }
            });
        }
    }

    const getStatus = (inputRole: string) => {
        let status = 'primary';
        if (inputRole === 'org_admin' || inputRole === 'super_admin' || inputRole === 'admin') {
            status = 'primary';
        } else if (inputRole === 'org_user') {
            status = 'warning';
        } else {
            status = 'danger';
        }

        return status;
    }

    return (
        <>
            <div className="Cleintsmain">
                <Row className="mb-3">
                    <Col sm md="6">
                        <div>
                            <button className='btn btn-success me-2 mb-2'
                                onClick={() => setUserAddForm(true)}
                            >Add User</button>
                            <button
                                className='btn btn-primary me-2 mb-2'
                                disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false}
                                onClick={() => editForm()}
                            >Edit User</button>
                            <button
                                className='btn btn-danger me-2 mb-2'
                                disabled={(selectedRow === null || selectedRow?.sid === '') ? true : false}
                                onClick={() => setShowDeleteDialog(true)}
                            >Remove User</button>
                        </div>
                    </Col>
                    <Col sm md="6">
                        <InputGroup>
                            <FormControl
                                type="text"
                                id="searchTerm"
                                name="searchTerm"
                                placeholder="Search user"
                                onChange={(e) => _handleSearch(e.target.value)}
                            />
                            <div className="search-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                            </div>
                        </InputGroup>
                    </Col>
                </Row>

                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>User Role</th>
                            <th>Care Location</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users && users.data && users.data.map((row: UsersGridDto, index: number) => (
                            <tr key={index}>
                                <td key={`check-${row.sid}`}>
                                    <Form>
                                        <Form.Check
                                            type={"checkbox"}
                                            id={`default-checkbox${row.sid}`}
                                            checked={row.sid === selectedRow?.sid}
                                            onChange={() => handleCheckboxChange(row)}
                                            className="custom-checkbox"
                                        />
                                    </Form>
                                </td>
                                <td key={`name-${row.sid}`}>
                                    {`${row.first_name} ${row.last_name}`}
                                </td>
                                <td key={`roles-${row.sid}`}>
                                    <Stack direction="horizontal" gap={2}>
                                        {
                                            row.roles && row.roles.split(',').map((role: string, index2: number) => (
                                                <Badge bg={getStatus(role)} key={`role-${role}-${index2}`}>
                                                    {role}
                                                </Badge>
                                            ))
                                        }
                                    </Stack>
                                </td>
                                <td key={`location-${row.sid}`}>
                                    {row.location}
                                </td>
                                <td key={`status-${row.sid}`}>
                                   {row.is_active ? 'Active' : 'Inactive'} 
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <MymonXDialog
                    title="Add User"
                    show={userAddForm}
                    size="lg"
                    onHide={() => setUserAddForm(false)}
                >
                    <CreateUserForm onHide={() => setUserAddForm(false)}
                        onComplete={() => {
                            setUserAddForm(false);
                            dispatch(getUsersAsync(usersRequest));
                        }}
                    />
                </MymonXDialog>

                <MymonXDialog
                    title="Update Client"
                    show={showClientEditForm}
                    size="lg"

                    onHide={() => setShowClientEditForm(false)}
                >
                    <UpdateUserForm
                        onHide={() => setShowClientEditForm(false)}
                        onComplete={() => {
                            setShowClientEditForm(false);
                            dispatch(getUsersAsync(usersRequest));
                        }}
                    />

                </MymonXDialog>


                <MymonXConfirmationDialog
                    show={showDeleteDialog}
                    title="Delete Client"
                    description={`Are you sure you want to delete user`}
                    cancelButtonText="Cancel"
                    confirmButtonText="Confirm"
                    onHide={onDeleteHide}
                    onConfirm={onDeleteConfirm}
                />
            </div>
        </>
    )
}

export default Users;