import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../application/store/useStore";
import { MapDeviceDto } from "../../../../../application/features/device-service/models/MapDeviceDto";
import { RootState } from "../../../../../application/store";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import { MapDeviceResultDto } from "../../../../../application/features/device-service/models/MapDeviceResultDto";
import { getDevicesAsync, assignDeviceAsync } from "../../../../../application/features/device-service/deviceServiceThunk";
import MymonXValidation from "../../../../components/mymonx-validation";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getOrgFromJwt } from "../../../../../application/common/helpers/jwt-helper";
import { getProfilesAsync } from "../../../../../application/features/user-profile-service/userProfileServiceThunk";
import MymonXReqAsteric from "../../../../components/mymonx-req-asteric";

interface DeviceAssignFormProps {
    onHideUpdateForm?(): void;
    onCompleteUpdateForm?(): void;
    device_id: string | undefined;
}

const DeviceAssignForm = ({ onHideUpdateForm, onCompleteUpdateForm, device_id }: DeviceAssignFormProps) => {

    const { devices } = useAppSelector((state: RootState) => state.deviceService);
    const { user_profiles, userProfileRequest } = useAppSelector((state: RootState) => state.userProfileService);
    const [setupDate, setSetupDate] = useState<Date | undefined>(undefined);
    const dispatch = useAppDispatch();
    const org_id = getOrgFromJwt();
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<MapDeviceDto>();

    useEffect(() => {
        if (org_id !== undefined) {
            const deviceDto = { org_id: org_id };
            dispatch(getDevicesAsync(deviceDto));
            dispatch(getProfilesAsync(userProfileRequest));

        }
    }, [dispatch, org_id, userProfileRequest]);

    const handleSetupDateChange = (dateChange: Date) => {
        setValue("setup_date", dateChange, {
            shouldDirty: true
        });
        setSetupDate(dateChange);
    };

    const onSubmit = (request: MapDeviceDto) => {
        debugger;
        request.sid = device_id ?? '';
        const selectedUser = user_profiles?.data.find((item: any) => item.sid === request.assigned_user_sid);
        const names = selectedUser?.name.split(' ');
        request.first_name = names?.[0] ?? '';
        request.last_name = names?.[1] ?? '';
        dispatch(assignDeviceAsync(request)).then(res => {
            if (onCompleteUpdateForm) {
                onCompleteUpdateForm();
            }
            // if (res && res.payload) {
            //     if ((res.payload as MapDeviceResultDto).device_Sid) {
            //         if (onCompleteUpdateForm) {
            //             onCompleteUpdateForm();
            //         }
            //     }
            // }
        });
    };

    return (
        <Form id="client-create-form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={12}>
                            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.user_id`}>
                                <Col sm={12} md={4}>
                                    <Form.Label>
                                        Client
                                        <MymonXReqAsteric />
                                    </Form.Label>
                                </Col>
                                <Col sm={12} md={8}>
                                    <Controller
                                        name="assigned_user_sid"
                                        control={control}
                                        rules={{ required: "Client is required" }}
                                        render={({ field }) => (
                                            <Form.Select {...field} aria-label="Default select Device">
                                                <option>Select Client</option>
                                                {user_profiles && user_profiles.data &&
                                                    user_profiles.data.map((item: any) => (
                                                        <option key={item.sid} value={item.sid}>{item.name}</option>
                                                    ))}
                                            </Form.Select>
                                        )}
                                    />
                                    <MymonXValidation fieldError={errors.assigned_user_sid} />
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3" controlId={`client-create-form.serial_number`}>
                                <Col sm={12} md={4}>
                                    <Form.Label>
                                        Serial number <MymonXReqAsteric />
                                    </Form.Label>
                                </Col>
                                <Col sm={12} md={8}>
                                    <Controller
                                        name="sid"
                                        control={control}
                                        rules={{ required: "Device is required" }}
                                        render={({ field }) => (
                                            <Form.Select {...field} aria-label="Default select Device">
                                                <option>Select Device</option>
                                                {devices?.devices &&
                                                    devices.devices.map((item: any) => (
                                                        <option key={item.sid} value={item.sid}>{item.imei}</option>
                                                    ))}
                                            </Form.Select>
                                        )}
                                    />
                                    <MymonXValidation fieldError={errors.sid} />
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.setup_date`}>
                                <Col sm={12} md={4}>
                                    <Form.Label>
                                        Setup Date <MymonXReqAsteric />
                                    </Form.Label>
                                </Col>
                                <Col sm={12} md={8}>
                                    <Controller
                                        name="setup_date"
                                        control={control}
                                        defaultValue={setupDate}
                                        render={() => (
                                            <DatePicker
                                                dateFormat={'dd/MM/yyyy'}
                                                maxDate={moment().toDate()}
                                                selected={setupDate}
                                                placeholderText="dd/mm/yyyy"
                                                onChange={handleSetupDateChange}
                                                className={`form-control w-100 ${!!errors.setup_date ? 'has-validation' : ''}`}
                                            />
                                        )}
                                    />
                                    <MymonXValidation fieldError={errors.setup_date} />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form.Group as={Row} className="mb-3" controlId="formSubmitButtons">
                <Col sm="12" className="text-end">
                    <Button variant="secondary" type="button" onClick={onHideUpdateForm}>
                        Cancel
                    </Button>{' '}
                    <Button variant="primary" type="submit">
                        Assign
                    </Button>
                </Col>
            </Form.Group>
        </Form>
    );
}

export default DeviceAssignForm;