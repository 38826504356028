import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../../../asstes/images/logo.svg";
import LocalStorageService from "../../../../infrastructure/local-storage-service";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { getOrganisationAsync, updateOrganisationAsync } from "../../../../application/features/org-service/orgServiceThunk";
import { OrganisationDto } from "../../../../application/features/org-service/models/organisation-dto";
import { Controller, useForm } from "react-hook-form";
import { RootState } from "../../../../application/store";
import MymonXValidation from "../../../components/mymonx-validation";
import { getCountriesAsync } from "../../../../application/features/staticData-service/saticDataServiceThunk";
import { RegisterOrganisationResultDto } from "../../../../application/features/org-service/models/organisation-result-dto";
import MymonXReqAsteric from "../../../components/mymonx-req-asteric";

const CompleteRegisteration = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { organisation } = useAppSelector((state: RootState) => state.orgService);
    const { contry_data } = useAppSelector((state: RootState) => state.staticDataService);

    useEffect(() => {
        dispatch(getCountriesAsync({ page: 1, per_page: 100 }))
    }, [dispatch])

    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit
    } = useForm<OrganisationDto>({
        defaultValues: organisation
    })

    useEffect(() => {
        const org_id = LocalStorageService.getOrg();

        if (org_id !== null && org_id !== '')
            dispatch(getOrganisationAsync(org_id))
    }, [dispatch]);

    useEffect(() => {
        debugger;
        if (organisation) {
            setValue('sid', organisation.sid);
            setValue('name', organisation.name);
            setValue('location', organisation.location);
            setValue('jurisdiction', organisation.jurisdiction);
        }
    }, [organisation]);

    const onSubmit = (registerCredentials: OrganisationDto) => {
        dispatch(updateOrganisationAsync(registerCredentials)).then((res: any) => {
            if (res.payload) {
                const org = res.payload as RegisterOrganisationResultDto;
                if (org.organisation_id) {
                    LocalStorageService.removeOrg();
                    navigate('/auth/login');
                }
            }
        })
    };

    return (
        <>
            <Row className="h-100">
                <Col className="col-12" >
                    <Row className="justify-content-end">
                        <Col className="col-lg-4 col-md-5 text-end">
                            <a href="#"><img className="img-fluid" src={logo} alt="Logo" /></a>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-12" >
                    <Row>
                        <Col className="col-lg-6 col-md-6">
                            <Card >
                                <Card.Body>
                                    <h3 className="text-center my-3">Complete Registeration v3</h3>
                                    <Form onSubmit={handleSubmit(onSubmit)} id="completeRegisterForm">
                                        <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.name`}>
                                            <Form.Label column sm={2} md={5}>
                                                Organisation Name:
                                                <MymonXReqAsteric />
                                            </Form.Label>
                                            <Col sm={10} md={7}>
                                                <Controller
                                                    name="name"
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'organisation name is required'
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Form.Control type="text" onChange={onChange} value={value} placeholder="Organisation Name" />
                                                    )}
                                                />
                                                <MymonXValidation fieldError={errors.name} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.location`}>
                                            <Form.Label column sm={2} md={5}>
                                                Location:
                                                <MymonXReqAsteric />
                                            </Form.Label>
                                            <Col sm={10} md={7}>
                                                <Controller
                                                    name="location"
                                                    control={control}
                                                    rules={{ required: "Location is required" }}
                                                    render={({ field }) => (
                                                        <Form.Select {...field} aria-label="Default select example">
                                                            <option>Select Location</option>
                                                            {contry_data.map((item: any) => (
                                                                <option key={item.sid} value={item.sid}>{item.iso_name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    )}
                                                />
                                                <MymonXValidation fieldError={errors.location} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.jurisdiction`}>
                                            <Form.Label column sm={2} md={5}>
                                                Data Protection Jurisdiction:
                                                <MymonXReqAsteric />
                                            </Form.Label>
                                            <Col sm={10} md={7}>
                                                <Controller
                                                    name="jurisdiction"
                                                    control={control}
                                                    rules={{ required: "Jurisdiction is required" }}
                                                    render={({ field }) => (
                                                        <Form.Select {...field} aria-label="Default select example">
                                                            <option>Select Jurisdiction</option>
                                                            {contry_data.map((item: any) => (
                                                                <option key={item.sid} value={item.sid}>{item.iso_name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    )}
                                                />
                                                <MymonXValidation fieldError={errors.jurisdiction} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.address`}>
                                            <Form.Label column sm={2} md={5}>
                                                Address:
                                                <MymonXReqAsteric />
                                            </Form.Label>
                                            <Col sm={10} md={7}>
                                                <Controller
                                                    name="address"
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Address is required'
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Form.Control type="text" onChange={onChange} value={value} placeholder="Address" />
                                                    )}
                                                />
                                                <MymonXValidation fieldError={errors.address} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.postal_code`}>
                                            <Form.Label column sm={2} md={5}>
                                                Post Code:
                                                <MymonXReqAsteric />
                                            </Form.Label>
                                            <Col sm={10} md={7}>
                                                <Controller
                                                    name="postal_code"
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Postal code is required'
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Form.Control type="text" onChange={onChange} value={value} placeholder="Postal code" />
                                                    )}
                                                />
                                                <MymonXValidation fieldError={errors.postal_code} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.city`}>
                                            <Form.Label column sm={2} md={5}>
                                                City:
                                                <MymonXReqAsteric />
                                            </Form.Label>
                                            <Col sm={10} md={7}>
                                                <Controller
                                                    name="city"
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'city is required'
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Form.Control type="text" onChange={onChange} value={value} placeholder="City" />
                                                    )}
                                                />
                                                <MymonXValidation fieldError={errors.city} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId={`completeRegisterForm.state`}>
                                            <Form.Label column sm={2} md={5}>
                                                State:
                                                <MymonXReqAsteric />
                                            </Form.Label>
                                            <Col sm={10} md={7}>
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'State is required'
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Form.Control type="text" onChange={onChange} value={value} placeholder="State" />
                                                    )}
                                                />
                                                <MymonXValidation fieldError={errors.state} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId="formTerms">
                                            <Col sm={12} md={12}>
                                                <Form.Check label="Please check to receive important updates and news about mymonX" />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Col sm={{ span: 10, offset: 2 }}>
                                                <Button type="submit">Register</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    )
}

export default CompleteRegisteration;
