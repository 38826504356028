import { useEffect, useState } from "react";
import { ConditionResultDto, CreateUpdateConditionDto } from "../../../../../../../application/features/medical-service/models/conditionresultdto";
import { useAppDispatch } from "../../../../../../../application/store/useStore";
import { Controller, useForm } from "react-hook-form";
import { postConditionAsync, putConditionAsync } from "../../../../../../../application/features/medical-service/medicalServiceThunk";
import { setSelectedCondition, setShowConditionForm } from "../../../../../../../application/features/medical-service/medicalServiceSlice";
import MymonXReqAsteric from "../../../../../../components/mymonx-req-asteric";
import MymonXValidation from "../../../../../../components/mymonx-validation";
import { Button, Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

interface CreateOrUpdateConditionFormProps {
    user_profile_sid: string;
    selectedCondition?: ConditionResultDto;
}


const CreateOrUpdateConditionPage = ({ user_profile_sid, selectedCondition }: CreateOrUpdateConditionFormProps) => {
    const dispatch = useAppDispatch();
    const [diagnosisDate, setDiagnosisDate] = useState<Date | undefined>(undefined);
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<CreateUpdateConditionDto>({
        defaultValues: selectedCondition
    });
    const onSubmit = (request: CreateUpdateConditionDto) => {
       
        const conditionReq = {
            sid: request.sid,
            user_profile_sid: request.user_profile_sid,
            condition: request.condition,
            diagnosis_date: request.diagnosis_date, // Ensure this matches the expected property name
        } as ConditionResultDto;

        if (request.sid !== '' && request.sid !== undefined) {
            dispatch(putConditionAsync(conditionReq)).then(res => {
                if (res && res.payload) {
                    dispatch(setSelectedCondition(undefined));
                    dispatch(setShowConditionForm(false));
                }
            });
        } else {
            conditionReq.sid = ""; // Initialize sid with an empty string for new conditions
            conditionReq.user_profile_sid = user_profile_sid;
            dispatch(postConditionAsync(conditionReq)).then(res => {
                if (res && res.payload) {
                    dispatch(setSelectedCondition(undefined));
                    dispatch(setShowConditionForm(false));
                }
            });
        }

    }

    useEffect(() => {
        if (selectedCondition) {
            setValue('sid', selectedCondition.sid ?? "");
            setValue('user_profile_sid', selectedCondition.user_profile_sid ?? "");
            setValue('condition', selectedCondition?.condition ?? "");
            setValue('diagnosis_date', selectedCondition.diagnosis_date);
            setDiagnosisDate(selectedCondition.diagnosis_date);

        }
    }, [selectedCondition])


    const handleDIagnosisDateChange = (dateChange: Date) => {
        setValue("diagnosis_date", dateChange, {
            shouldDirty: true
        });
        setDiagnosisDate(dateChange);
    };



    return (
        <>
            <Form id="create-or-update-condition-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Form.Group as={Row} className="mb-3" controlId={`create-or-update-medication-form.medication`}>
                            <Form.Label column sm={12} md={4}>
                                Condition<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="condition"
                                    {...register('condition', {
                                        required: {
                                            value: true,
                                            message: 'condition is required'
                                        }
                                    })}
                                    className={`${!!errors.condition ? 'has-validation' : ''}`}
                                />
                                <MymonXValidation fieldError={errors.condition} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`create-or-update-condition-form.diagnosis_date`}>
                            <Form.Label column sm={12} md={4}>
                                Start Date <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="diagnosis_date"
                                    control={control}
                                    defaultValue={diagnosisDate}
                                    render={() => (
                                        <DatePicker
                                            className={`form-control w-100 ${!!errors.diagnosis_date ? 'has-validation' : ''}`}
                                            dateFormat={'dd/MM/yyyy'}
                                            maxDate={moment().toDate()}
                                            selected={diagnosisDate}
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleDIagnosisDateChange}
                                        />
                                    )}
                                />
                                <MymonXValidation fieldError={errors.diagnosis_date} ></MymonXValidation>
                            </Col>
                        </Form.Group>

                    </Col>
                </Row>
                <Form.Group as={Row} className="mb-3" controlId="formSubmitButtons">
                    <Col sm="12" className="text-end">
                        <Button variant="secondary" type="button" onClick={() => {
                            dispatch(setSelectedCondition(undefined))
                            dispatch(setShowConditionForm(false))
                        }} >
                            Cancel
                        </Button>{' '}
                        <Button variant="primary" type="submit" >
                            Create
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        </>
    )
};

export default CreateOrUpdateConditionPage;