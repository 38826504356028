import { Button, CloseButton, Modal } from "react-bootstrap"

interface MymonXDialogProps {
    show: boolean;
    children: JSX.Element;
    title?: string;
    onHide?(): void;
    contentClassName?: string;
    size: "sm" | "lg" | "xl" | undefined;
}


const MymonXDialog = ({ show, children, title, onHide, size, contentClassName}: MymonXDialogProps) => {
    return (
        <Modal
            show={show}
            onhid
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName={contentClassName}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
                <CloseButton onClick={onHide} />
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default MymonXDialog;