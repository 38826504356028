import { useEffect, useState } from "react";
import { RootState } from "../../../../../../application/store";
import { useAppDispatch, useAppSelector } from "../../../../../../application/store/useStore";
import { getMonthlyReportsAsync, getMonthlyReportsByVitalAsync } from "../../../../../../application/features/report-service/reportServiceThunk";

interface ClientMonthlyReportsPageProps {
    user_profile_sid?: string;
}

const ClientMonthlyReportsPage = ({ user_profile_sid }: ClientMonthlyReportsPageProps) => {
    const dispatch = useAppDispatch();
    const { monthlyReportsResult } = useAppSelector((state: RootState) => state.reportService);
    const [htmlContent, setHtmlContent] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (user_profile_sid) {
            dispatch(getMonthlyReportsAsync(user_profile_sid));
        }
    }, [dispatch, user_profile_sid]);

    useEffect(() => {
        if (monthlyReportsResult) {
            setHtmlContent(monthlyReportsResult);
            setLoading(false);
        }
    }, [monthlyReportsResult]);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <div className="row">
            {loading && (
                <div className="col-12 text-center">
                    <div className="alert alert-warning" role="alert">
                        Loading...
                    </div>
                </div>
            )}
            {htmlContent && (
                <iframe
                    srcDoc={htmlContent}
                    title="Monthly Reports"
                    onLoad={handleLoad}
                    style={{ width: '100%', height: '100vh', border: 'none' }}
                />
            )}
        </div>
    );
};

export default ClientMonthlyReportsPage;
