import { Navigate, useLocation } from "react-router-dom";
import LocalStorageService from "../../../infrastructure/local-storage-service";

export interface ProtectedRouteProps {
    children: JSX.Element;
}

const ProtectedRoute = ({children}: ProtectedRouteProps) => {
    const validUser = LocalStorageService.getAccessToken();
    const location = useLocation();
    if (!validUser)
        return <Navigate to="/auth/login" state={{from: location}} />;
    return children;
}

export default ProtectedRoute;