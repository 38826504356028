import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../../../../application/store/useStore';
import { RootState } from '../../../../../../../application/store';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { DataItem, SeriesData, VitalsGraphRequestDto } from '../../../../../../../application/features/health-service/models/graph-request-dto';
import { resetVitalsGraph, setVitalsGraphRequest } from '../../../../../../../application/features/health-service/healthServiceSlice';
import { getVitalsGraphAsync } from '../../../../../../../application/features/health-service/healthServiceThunk';
import { GraphFilterDto } from '../../../../../../../application/features/health-service/models/graph-filter-dto';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import { Console } from 'console';

interface BpGraphProps {
    id: string;
    userId: string;
}

const BpGraph = ({ id, userId }: BpGraphProps) => {
    const dispatch = useAppDispatch();

    const vitalsGraphRequest = useAppSelector((state: RootState) => state.healthService.vitalsGraphRequest['BLOOD_PRESSURE']);
    const vitalsGraphDto = useAppSelector((state: RootState) => state.healthService.vitalsGraphDto['BLOOD_PRESSURE']);

    const graphFilterDto = useAppSelector((state: RootState) => state.healthService.graphFilterDto);

    const [startDate, setStartDate] = useState<Date | null>(moment().startOf('day').toDate());//moment(moment().diff(6, 'days')).startOf('day').toDate());
    const [endDate, setEndDate] = useState<Date | null>(moment().endOf('day').toDate());
    const [selectedPeriod, setSelectedPeriod] = useState('DAY');
    const [sameday, setSameDay] = useState<boolean>(false)
    const bpSysGraphDtoV1 = _.cloneDeep(vitalsGraphDto);
    const { control, handleSubmit, setValue } = useForm<VitalsGraphRequestDto>({
        defaultValues: {
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }
    });

    useEffect(() => {
        dispatch(resetVitalsGraph('BLOOD_PRESSURE'))
    }, [dispatch]);

    const handleFilterClick = (period: any) => {
        if (userId) {
            let start_date = moment().startOf('day').toDate();
            let end_date = moment().endOf('day').toDate();
            let graphType = 'DAY';
            console.log("perios" + period);
            switch (period) {
                case 'DAY':
                    start_date = moment().startOf('day').toDate();
                    graphType = 'DAY';
                    break;
                case 'WEEK':
                    start_date = moment().subtract(6, 'day').startOf('day').toDate();
                    graphType = 'WEEK';
                    break;
                case 'MONTH':
                    start_date = moment().subtract(29, 'day').startOf('day').toDate();
                    graphType = 'MONTH';
                    break;
                case 'YEAR':
                    start_date = moment().subtract(364, 'day').startOf('day').toDate();
                    graphType = 'YEAR';
                    break;
                case 'RANGE':
                    graphType = 'RANGE';

                    break;
                default:
                    break;
            }

            setStartDate(start_date);
            setEndDate(end_date);
            setSelectedPeriod(period);

            if (graphType !== 'RANGE') {
                const request = {
                    userId: userId,
                    vital_type: vitalsGraphRequest.vital_type,
                    graphType: graphType,
                    startDate: moment(start_date).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(end_date).format('YYYY-MM-DD HH:mm:ss'),
                };
                dispatch(setVitalsGraphRequest({ vital_type: request.vital_type, request }));

            }
        }

    };

    useEffect(() => {
        // Reset to initial state when component mounts
        setStartDate(moment().startOf('day').toDate());
        setEndDate(moment().endOf('day').toDate());
        setSelectedPeriod('DAY');

        const defaultRequest = {
            userId: userId,
            vital_type: 'BLOOD_PRESSURE',
            graphType: 'DAY',
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch(setVitalsGraphRequest({ vital_type: defaultRequest.vital_type, request: defaultRequest }));

        return () => {
            dispatch(resetVitalsGraph('BLOOD_PRESSURE'));
        };
    }, [dispatch, userId]);



    useEffect(() => {
        fetchGraphData();
    }, [dispatch, vitalsGraphRequest])



    const fetchGraphData = () => {
        if (vitalsGraphRequest && vitalsGraphRequest.userId !== undefined && vitalsGraphRequest.userId !== '') {
            const startDate = new Date(vitalsGraphRequest.startDate);
            const endDate = new Date(vitalsGraphRequest.endDate);
            dispatch(getVitalsGraphAsync(vitalsGraphRequest));
            console.log(JSON.stringify(bpSysGraphDtoV1));
        }
    };

    useEffect(() => {
        const interval = setInterval(fetchGraphData, 300000); // 60000ms = 1 minute
        return () => clearInterval(interval); // Cleanup on unmount
    }, [vitalsGraphRequest]);

    const xAxisFormatter = (value: string) => {
        const date = moment(value);

        if (selectedPeriod === 'DAY') {
            return date.format('HH:mm').toString();;
        } else if (selectedPeriod === 'WEEK' || selectedPeriod === 'MONTH' || selectedPeriod === 'YEAR') {
            return date.format('DD/MM').toString();;
        } else if (selectedPeriod === 'RANGE') {
            const startDate = moment(vitalsGraphRequest.startDate);
            const endDate = moment(vitalsGraphRequest.endDate);

            if (startDate.isSame(endDate, 'day')) {
                return date.format('HH:mm').toString();;
            } else if (endDate.diff(startDate, 'days') > 1 && endDate.diff(startDate, 'days') <= 365) {
                return date.format('DD/MM').toString();;
            } else {
                return date.format('YYYY').toString();;
            }
        } else {
            return date.format('YYYY').toString();;
        }
    };



    const handleStartDateChange = (dateChange: Date) => {
        setValue("startDate", moment(dateChange).startOf('day').format('YYYY-MM-DD HH:mm:ss'), {
            shouldDirty: true
        });
        setStartDate(dateChange);
    };

    const handleEndDateChange = (dateChange: Date) => {
        setValue("endDate", moment(dateChange).endOf('day').format('YYYY-MM-DD HH:mm:ss'), {
            shouldDirty: true
        });
        setEndDate(dateChange);
    };

    const onSubmit = (requestObj: VitalsGraphRequestDto) => {

        const sDate = moment(requestObj.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const eDate = moment(requestObj.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        if (userId) {

            const request = {
                userId: userId,
                vital_type: requestObj.vital_type,
                graphType: selectedPeriod,
                startDate: sDate,
                endDate: eDate,
            };
            dispatch(setVitalsGraphRequest({ vital_type: requestObj.vital_type, request }));

        }

    }
    const optionsSystolic: ApexOptions = {
        chart: {
            id: "systolic"
        },
        xaxis: {
            type: 'datetime', // Set x-axis to datetime type
            categories: bpSysGraphDtoV1?.vital_datetime, // Ensure categories are in datetime format
            tickAmount: 30, // Show only 6 ticks/labels on the x-axis
            labels: {
                formatter: xAxisFormatter, // Use the formatter function
                rotate: 0,
                style: {
                    fontSize: '12px',
                    cssClass: 'apexcharts-xaxis-label'
                }
            }
        },

        colors: ["#21609a", "#e97232", "#219a35"],
        stroke: {
            curve: 'smooth',
            width: 3 // Set the width of the lines to 1 to make them thinner
        },
        yaxis: {
            title: {
                text: 'Systolic'
            }
        },

    };

    const optionsDiastolic: ApexOptions = {
        chart: {
            id: "diastolic"
        },
        xaxis: {
            type: 'datetime', // Set x-axis to datetime type
            categories: bpSysGraphDtoV1?.vital_datetime, // Ensure categories are in datetime format
            tickAmount: 30, // Show only 6 ticks/labels on the x-axis
            labels: {
                formatter: xAxisFormatter, // Use the formatter function
                rotate: 0,
                style: {
                    fontSize: '12px',
                    cssClass: 'apexcharts-xaxis-label'
                }
            }
        },

        colors: ["#21609a", "#e97232", "#219a35"],
        stroke: {
            curve: 'smooth',
            width: 3
        },
        yaxis: {
            title: {
                text: 'Diastolic'
            }
        },
    };
    const formatSeriesDataOneDayDia = (): SeriesData[] => {
        if (!bpSysGraphDtoV1?.vital_data?.VITAL_BPD) return [];

        const xAxisValues = bpSysGraphDtoV1.vital_datetime || [];
        const vitalValues = bpSysGraphDtoV1.vital_data.VITAL_BPD || [];

        if (vitalsGraphRequest.graphType === 'DAY') {
            const hourlyData = groupDataByHour(xAxisValues, vitalValues);

            return [
                {
                    name: 'Avg',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.avg === 'number' ? item.avg : undefined, // Ensure `y` is a number or undefined
                    })),
                },
                {
                    name: 'Max',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.max === 'number' ? item.max : undefined, // Ensure `y` is a number or undefined
                    })),
                },
                {
                    name: 'Min',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.min === 'number' ? item.min : undefined, // Ensure `y` is a number or undefined
                    })),
                },
            ];
        }

        return []; // Return empty if `graphType` is not 'DAY'
    };
    const formatSeriesDataOneDaySia = (): SeriesData[] => {
        if (!bpSysGraphDtoV1?.vital_data?.VITAL_BPS) return [];

        const xAxisValues = bpSysGraphDtoV1.vital_datetime || [];
        const vitalValues = bpSysGraphDtoV1.vital_data.VITAL_BPS || [];

        if (vitalsGraphRequest.graphType === 'DAY') {
            const hourlyData = groupDataByHour(xAxisValues, vitalValues);

            return [
                {
                    name: 'Avg',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.avg === 'number' ? item.avg : undefined, // Ensure `y` is a number or undefined
                    })),
                },
                {
                    name: 'Max',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.max === 'number' ? item.max : undefined, // Ensure `y` is a number or undefined
                    })),
                },
                {
                    name: 'Min',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.min === 'number' ? item.min : undefined, // Ensure `y` is a number or undefined
                    })),
                },
            ];
        }

        return []; // Return empty if `graphType` is not 'DAY'
    };
    const groupDataByHour = (dates: Date[], values: number[]) => {
        const hourlyData: any = {};

        dates.forEach((date, index) => {
            const hour = moment(date).startOf('hour').format('YYYY-MM-DD HH:mm');
            if (!hourlyData[hour]) {
                hourlyData[hour] = {
                    values: [],
                };
            }
            hourlyData[hour].values.push(values[index]);
        });

        const result = Object.keys(hourlyData).map(hour => {
            const data = hourlyData[hour].values;
            const avg = _.mean(data);
            const min = _.min(data);
            const max = _.max(data);

            return {
                hour,
                avg: Math.round(avg * 100) / 100,  // Round to 2 decimal places
                min,
                max,
            };
        });

        return result;
    };
    const formatSeriesData = () => {
        if (!bpSysGraphDtoV1?.vital_data?.VITAL_BPS) return [];

        // Example x-axis values. Adjust as needed to match the x-axis categories.
        const xAxisValues = bpSysGraphDtoV1.vital_datetime || [];
        // const vitalValues = bpSysGraphDtoV1.vital_data.VITAL_BPS || [];

        // if (vitalsGraphRequest.graphType === 'DAY') {
        //     const hourlyData = groupDataByHour(xAxisValues, vitalValues);

        //     return [
        //         {
        //             name: 'Avg',
        //             data: hourlyData.map(item => {
        //                 const nextHour = moment(item.hour, 'YYYY-MM-DD HH:mm').subtract(1, 'hour').format('YYYY-MM-DD HH:mm');
        //                 item.hour = nextHour;
        //                 // Return the adjusted data object
        //                 return {
        //                     x: item.hour,
        //                     y: typeof item.avg === 'number' ? item.avg : undefined, // Ensure `y` is a number or undefined
        //                 };
        //             }),
        //         },

        //     ];
        // }
        const series = [
            {
                name: 'Avg',
                data: bpSysGraphDtoV1.vital_data.VITAL_BPS
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: bpSysGraphDtoV1?.vital_data?.VITAL_BPS?.[index] // y-axis value for Heart Rate
                // }))
            }
        ];

        // Add the VITAL_MAX series if it exists

        if (Array.isArray(bpSysGraphDtoV1.vital_data.VITAL_BPS_MAX) && bpSysGraphDtoV1.vital_data.VITAL_BPS_MAX?.length > 0) {

            series.push({
                name: 'Max',
                data: bpSysGraphDtoV1.vital_data.VITAL_BPS_MAX
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: bpSysGraphDtoV1?.vital_data?.VITAL_BPS_MAX?.[index] // y-axis value for Max Heart Rate
                // }))
            });
        }

        // Add the VITAL_MIN series if it exists
        if (Array.isArray(bpSysGraphDtoV1.vital_data.VITAL_BPS_MIN) && bpSysGraphDtoV1.vital_data.VITAL_BPS_MIN?.length > 0) {
            series.push({
                name: 'Min',
                data: bpSysGraphDtoV1.vital_data.VITAL_BPS_MIN
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: bpSysGraphDtoV1?.vital_data?.VITAL_BPS_MIN?.[index] // y-axis value for Min Heart Rate
                // }))
            });
        }

        return series;
    };
    const formatDIASeriesData = () => {
        if (!bpSysGraphDtoV1?.vital_data?.VITAL_BPD) return [];

        // Example x-axis values. Adjust as needed to match the x-axis categories.
        const xAxisValues = bpSysGraphDtoV1.vital_datetime || [];
        // const vitalValues = bpSysGraphDtoV1.vital_data.VITAL_BPD || [];

        // if (vitalsGraphRequest.graphType === 'DAY') {
        //     const hourlyData = groupDataByHour(xAxisValues, vitalValues);

        //     return [
        //         {
        //             name: 'Avg',
        //             data: hourlyData.map(item => {
        //                 const nextHour = moment(item.hour, 'YYYY-MM-DD HH:mm').subtract(1, 'hour').format('YYYY-MM-DD HH:mm');
        //                 item.hour = nextHour;
        //                 // Return the adjusted data object
        //                 return {
        //                     x: item.hour,
        //                     y: typeof item.avg === 'number' ? item.avg : undefined, // Ensure `y` is a number or undefined
        //                 };
        //             }),
        //         },

        //     ];
        // }
        const series = [
            {
                name: 'Avg',
                data: bpSysGraphDtoV1.vital_data.VITAL_BPD
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: bpSysGraphDtoV1?.vital_data?.VITAL_BPD?.[index] // y-axis value for Heart Rate
                // }))
            }
        ];

        // Add the VITAL_MAX series if it exists

        if (Array.isArray(bpSysGraphDtoV1.vital_data.VITAL_BPD_MAX) && bpSysGraphDtoV1.vital_data.VITAL_BPD_MAX?.length > 0) {

            series.push({
                name: 'Max',
                data: bpSysGraphDtoV1.vital_data.VITAL_BPD_MAX
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: bpSysGraphDtoV1?.vital_data?.VITAL_BPD_MAX?.[index] // y-axis value for Max Heart Rate
                // }))
            });
        }

        // Add the VITAL_MIN series if it exists
        if (Array.isArray(bpSysGraphDtoV1.vital_data.VITAL_BPD_MIN) && bpSysGraphDtoV1.vital_data.VITAL_BPD_MIN?.length > 0) {
            series.push({
                name: 'Min',
                data: bpSysGraphDtoV1.vital_data.VITAL_BPD_MIN
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: bpSysGraphDtoV1?.vital_data?.VITAL_BPD_MIN?.[index] // y-axis value for Min Heart Rate
                // }))
            });
        }

        return series;
    };

    const seriesData = formatSeriesData();
    const seriesDIAData = formatDIASeriesData();
    const seriesV2Data = formatSeriesDataOneDaySia();
    const seriesV2DataDia = formatSeriesDataOneDayDia();
    const hours = seriesV2Data[0]?.data.map(item => item.x.toString()) || [];
    const hoursDia = seriesV2DataDia[0]?.data.map(item => item.x.toString()) || [];

    const findData = (name: string): number[] =>
        (seriesV2Data.find((d: SeriesData) => d.name === name)?.data.map((item: DataItem) => Number(item.y ?? 0))) || [];
    const findDataDia = (name: string): number[] =>
        (seriesV2DataDia.find((d: SeriesData) => d.name === name)?.data.map((item: DataItem) => Number(item.y ?? 0))) || [];

    const minValues = findData('Min');
    const maxValues = findData('Max');
    const avgValues = findData('Avg');
    const minValuesDia = findDataDia('Min');
    const maxValuesDia = findDataDia('Max');
    const avgValuesDia = findDataDia('Avg');
    const isGraphDataEmpty = (data: number[]): boolean => {
        if (data) {
            return data.length === 0;
        }
        else return false;
    };
    const getColorSys = (value: number): string => {
        let color = '#c00000';

        if (value < 40)
            color = '#74a5d5';
        else if (value > 40 && value <= 110)
            color = '#58B4E0';
        else if (value > 110 && value <= 135)
            color = '#00b18c';
        else if (value > 135 && value <= 140)
            color = '#e08458';
        else if (value > 140 && value <= 160)
            color = '#ffcdbd';
        else if (value > 160 && value <= 999)
            color = '#ff9675';
        return color;
    }
    const getColorDia = (value: number): string => {
        let color = '#c00000';

        if (value < 40)
            color = '#74a5d5';
        else if (value > 40 && value <= 60)
            color = '#58B4E0';
        else if (value > 60 && value <= 85)
            color = '#00b18c';
        else if (value > 85 && value <= 90)
            color = '#e08458';
        else if (value > 90 && value <= 100)
            color = '#ffcdbd';
        else if (value > 100 && value <= 999)
            color = '#ff9675';
        return color;
    }

    return (
        <>
            <div><h2>Blood Pressure</h2></div>
            <div className="d-flex" key={`filter-div-${id}`}>
                <ButtonGroup>
                    {
                        graphFilterDto && graphFilterDto.map((item: GraphFilterDto, index: number) => {
                            return (
                                <Button
                                    key={`btn-${item.key}-${index}`}
                                    style={{ margin: '1 rem' }}
                                    onClick={() => handleFilterClick(item.value)}
                                    variant={selectedPeriod === item.key ? 'primary' : 'secondary'}
                                >
                                    {item.key}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>

                {
                    selectedPeriod === 'RANGE' ? <>
                        <form className="ms-2 d-flex" id="filter-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue={moment(startDate).format('YYYY-MM-DD')}
                                render={() => (
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={'dd/MM/yyyy'}
                                        maxDate={moment().toDate()}
                                        selected={startDate}
                                        placeholderText="dd/mm/yyyy"
                                        onChange={handleStartDateChange}
                                    />
                                )}
                            />
                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue={moment(endDate).format('YYYY-MM-DD')}
                                render={() => (
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={'dd/MM/yyyy'}
                                        maxDate={moment().toDate()}
                                        selected={endDate}
                                        placeholderText="dd/mm/yyyy"
                                        onChange={handleEndDateChange}
                                    />
                                )}
                            />
                            <Button variant="primary" type="submit" >
                                Filter
                            </Button>
                        </form>
                    </> : <>
                    </>
                }
            </div>
            {
                !bpSysGraphDtoV1 || isGraphDataEmpty(bpSysGraphDtoV1?.vital_data?.VITAL_BPS ?? []) ? (

                    <div className="col-12 text-center">
                        <div className="alert alert-warning" role="alert">
                            No data found
                        </div>
                    </div>
                ) : (
                    <>
                        {bpSysGraphDtoV1 && <div>
                            <div id="chart">
                                <Chart key={`Systolic-1`} options={optionsSystolic} series={seriesData} type="line" height={350} />
                            </div>
                            <div id="html-dist"></div>
                            <div id="chart">
                                <Chart key={`Dystolic-1`} options={optionsDiastolic} series={seriesDIAData} type="line" height={350} />
                            </div>
                            <div id="html-dist"></div>
                        </div>}
                        <br />
                        {bpSysGraphDtoV1 && bpSysGraphDtoV1.vital_data.VITAL_BPD?.length &&
                            <div style={{ overflowX: 'auto' }} key={`main-table-div`}>
                                <Table striped bordered hover key={`bp-table-${1}`}>
                                    {vitalsGraphRequest?.graphType === 'DAY' ?
                                        (
                                            <>
                                                <thead key={`bp-first-thead`}>
                                                    <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                                                        Hours

                                                    </th>
                                                    {hours.map((hour: string, index: number) => {
                                                        // Use moment to format the date-time string
                                                        const formattedHour = moment(hour).format('HH');
                                                        // Calculate the next hour and format it
                                                        const nextHour = moment(hour).add(-1, 'hour').format('HH');

                                                        return (
                                                            <th key={`day-month-year-${index}`} style={{ border: '1px solid black', padding: '8px', textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                                {nextHour} - {formattedHour}
                                                            </th>
                                                        );
                                                    })}


                                                </thead>
                                                <tbody>
                                                    <tr key={`row-sys-avg`}>
                                                        <td key={`col-sys-avg`} className='w-25'>
                                                            Average (Systolic)
                                                        </td>

                                                        {avgValues.map((value: number, index: number) => (
                                                            <td key={`col-${value}-sys-avg-${index}`} style={{ background: getColorSys(value), color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}

                                                    </tr>
                                                    <tr key={`row-dia-avg`}>
                                                        <td key={`col-dia-avg`} className='w-25'>
                                                            Average (Diastolic)
                                                        </td>

                                                        {avgValuesDia.map((value: number, index: number) => (
                                                            <td key={`col-${value}-dia-avg-${index}`} style={{ background: getColorDia(value), color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}

                                                    </tr>
                                                    <tr key={`row-sys-max`}>
                                                        <td key={`col-sys-max`} className='w-25'>
                                                            Max(Systolic)
                                                        </td>
                                                        {maxValues.map((value: number, index: number) => (
                                                            <td key={`col-${value}-sys-max-${index}`} style={{ background: getColorSys(value), color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr key={`row-dia-max`}>
                                                        <td key={`col-dia-max`} className='w-25'>
                                                            Max(Diastolic)
                                                        </td>
                                                        {maxValuesDia.map((value: number, index: number) => (
                                                            <td key={`col-${value}-dia-max-${index}`} style={{ background: getColorDia(value), color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr key={`row-sys-min`}>
                                                        <td key={`col-sys-min`} className='w-25'>
                                                            Min(Systolic)
                                                        </td>
                                                        {minValues.map((value: number, index: number) => (
                                                            <td key={`col-${value}-sys-min-${index}`} style={{ background: getColorSys(value), color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr key={`row-dia-min`}>
                                                        <td key={`col-dia-min`} className='w-25'>
                                                            Min(Diastolic)
                                                        </td>
                                                        {minValuesDia.map((value: number, index: number) => (
                                                            <td key={`col-${value}-dia-min-${index}`} style={{ background: getColorDia(value), color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}
                                                    </tr>


                                                </tbody>

                                            </>
                                        ) :
                                        (
                                            <>
                                                <thead key={`sys-first-thead`}>
                                                    <tr key={`first-row`}>
                                                        <th key={`first-column`}></th>
                                                        {bpSysGraphDtoV1 && bpSysGraphDtoV1.vital_datetime.map((item: Date, index: number) => {
                                                            return (
                                                                <th key={`day-month-year-${item}`}>

                                                                    {
                                                                        xAxisFormatter(item.toString())
                                                                    }
                                                                </th>
                                                            )
                                                        })}

                                                    </tr>
                                                </thead>
                                                <tbody key={`first-tbody`}>

                                                    <tr key={`row-sys-avg`}>
                                                        <td key={`col-systolic`} className='w-25'>
                                                            Avg (Systolic)
                                                        </td>
                                                        {
                                                            bpSysGraphDtoV1 && bpSysGraphDtoV1.vital_data.VITAL_BPS && bpSysGraphDtoV1.vital_data.VITAL_BPS?.map((item: number, index: number) => (
                                                                <td key={`col-${item}-systolic-${index}`} style={{ background: getColorSys(item), color: '#000' }}>
                                                                    {item}
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    <tr key={`row-diastolic-avg`}>
                                                        <td key={`col-diastolic`} className='w-25'>
                                                            Avg(Diastolic)                                            </td>
                                                        {
                                                            bpSysGraphDtoV1 && bpSysGraphDtoV1.vital_data && bpSysGraphDtoV1.vital_data.VITAL_BPD?.map((item: number, index: number) => (
                                                                <td key={`col-${item}-diastolic-${index}`} style={{ background: getColorDia(item), color: '#000' }}>
                                                                    {item}
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                    {
                                                        bpSysGraphDtoV1 && bpSysGraphDtoV1.vital_data.VITAL_BPS_MAX && bpSysGraphDtoV1.vital_data.VITAL_BPS_MAX?.length > 0 && (
                                                            <tr key={`row-sys-max`}>
                                                                <td key={`col-sys-max`} className='w-25'>
                                                                    Max(Systolic)
                                                                </td>
                                                                {bpSysGraphDtoV1.vital_data.VITAL_BPS_MAX?.map((item: number, index: number) => {
                                                                    return (
                                                                        <td key={`col-${item}-sys-max-${index}`} style={{ background: getColorSys(item), color: '#000' }}>
                                                                            {item}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        bpSysGraphDtoV1 && bpSysGraphDtoV1.vital_data.VITAL_BPD_MAX && bpSysGraphDtoV1.vital_data.VITAL_BPD_MAX?.length > 0 && (
                                                            <tr key={`row-dia-max`}>
                                                                <td key={`col-dia-max`} className='w-25'>
                                                                    Max(Diastolic)
                                                                </td>
                                                                {bpSysGraphDtoV1.vital_data.VITAL_BPD_MAX?.map((item: number, index: number) => {
                                                                    return (
                                                                        <td key={`col-${item}-dia-max-${index}`} style={{ background: getColorDia(item), color: '#000' }}>
                                                                            {item}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        bpSysGraphDtoV1 && bpSysGraphDtoV1.vital_data?.VITAL_BPS_MIN && bpSysGraphDtoV1.vital_data.VITAL_BPS_MIN?.length > 0 && (
                                                            <tr key={`row-sys-min`}>
                                                                <td key={`col-sys-min`} className='w-25'>
                                                                    Min(Systolic)
                                                                </td>
                                                                {bpSysGraphDtoV1.vital_data.VITAL_BPS_MIN?.map((item: number, index: number) => {
                                                                    return (
                                                                        <td key={`col-${item}-sys-min-${index}`} style={{ background: getColorSys(item), color: '#000' }}>
                                                                            {item}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )
                                                    }

                                                    {
                                                        bpSysGraphDtoV1 && bpSysGraphDtoV1.vital_data.VITAL_BPD_MIN && bpSysGraphDtoV1.vital_data.VITAL_BPD_MIN?.length > 0 && (
                                                            <tr key={`row-dia-min`}>
                                                                <td key={`col-dia-min`} className='w-25'>
                                                                    Min(Diastolic)
                                                                </td>
                                                                {bpSysGraphDtoV1.vital_data.VITAL_BPD_MIN?.map((item: number, index: number) => {
                                                                    return (
                                                                        <td key={`col-${item}-dia-min-${index}`} style={{ background: getColorDia(item), color: '#000' }}>
                                                                            {item}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )
                                                    }

                                                </tbody>
                                            </>
                                        )

                                    }


                                </Table>
                            </div>
                        }
                    </>
                )
            }
        </>
    )
}

export default BpGraph;
