import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../application/store/useStore";
import { RootState } from "../../../../../application/store";
import { Button, Col, Form, Row } from "react-bootstrap";
import MymonXReqAsteric from "../../../../components/mymonx-req-asteric";
import { CreateContactDto } from "../../../../../application/features/contact-service/models/create-contact-dto";
import { setContactList, setSelectedContact } from "../../../../../application/features/contact-service/contactServiceSlice";
import MymonXValidate from "../../../../components/mymonx-validate";
import { v4 as uuidv4 } from 'uuid';

interface ContactFormProps {
    id: string;
}

const ContactForm = ({ id }: ContactFormProps) => {
    const dispatch = useAppDispatch();
    const { contactList, selectedContact } = useAppSelector((state: RootState) => state.contactService);

    const initialData = {
        sid: "",
        user_sid: "",
        first_name: "",
        last_name: "",
        is_sos: false,
        mobile_prefix: "",
        mobile_number: ""
    };

    const [formData, setFormData] = useState<CreateContactDto>(initialData);

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));

        // Clear the error for the specific field
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};
        if (!formData.first_name) newErrors.first_name = 'First name is required';
        if (!formData.last_name) newErrors.last_name = 'Last name is required';
        if (!formData.mobile_prefix) newErrors.mobile_prefix = 'Prefix mobile is required';
        if (!formData.mobile_number) newErrors.mobile_number = 'Mobile number is required';
        return newErrors;
    };

    const handleSubmit = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const newContact = {
                ...formData,
                sid: uuidv4(), // Generate a unique UUID for the new contact
                is_sos: false
            };
            dispatch(setContactList([...contactList, newContact]));
            setFormData(initialData);
        }
    };

    const handleUpdateSubmit = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const newContact = {
                ...formData,
                sid: formData.sid || uuidv4(), // Use existing sid if available, otherwise generate a new one
                is_sos: false
            };

            const updatedContactList = contactList.map(contact =>
                contact.sid === newContact.sid ? newContact : contact
            );

            // Check if the contact already exists in the contact list
            const contactExists = contactList.some(contact => contact.sid === newContact.sid);

            // If the contact does not exist, add it to the list
            if (!contactExists) {
                updatedContactList.push(newContact);
            }

            dispatch(setContactList([...updatedContactList]));
            setFormData(initialData);
            dispatch(setSelectedContact(undefined));
        }
    };

    useEffect(() => {
        if (selectedContact) {
            setFormData(
                {
                    sid: selectedContact.sid,
                    user_sid: selectedContact.user_sid,
                    first_name: selectedContact.first_name,
                    last_name: selectedContact.last_name,
                    mobile_prefix: selectedContact.mobile_prefix,
                    mobile_number: selectedContact.mobile_number,
                    is_sos: false
                }
            )
        }
    }, [selectedContact]);

    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                <Form.Label column sm={12} md={4}>
                    First Name <MymonXReqAsteric />
                </Form.Label>
                <Col sm={12} md={8}>
                    <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className={`${errors.first_name ? 'has-validation' : ''}`}
                    />
                    <MymonXValidate fieldError={errors.first_name}></MymonXValidate>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.last_name`}>
                <Form.Label column sm={12} md={4}>
                    Last Name <MymonXReqAsteric />
                </Form.Label>
                <Col sm={12} md={8}>
                    <Form.Control
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        value={formData.last_name}
                        onChange={handleChange}
                        className={`${errors.last_name ? 'has-validation' : ''}`}
                    />
                    <MymonXValidate fieldError={errors.last_name}></MymonXValidate>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.mobile_number`}>
                <Form.Label column sm={12} md={4}>
                    Mobile Number <MymonXReqAsteric />
                </Form.Label>
                <Col sm={12} md={8}>
                    <Row>
                        <Col sm="4">
                            <Form.Control
                                type="text"
                                name="mobile_prefix"
                                placeholder="+44"
                                value={formData.mobile_prefix}
                                onChange={handleChange}
                                className={`${errors.mobile_prefix ? 'has-validation' : ''}`}
                            />
                            <MymonXValidate fieldError={errors.mobile_prefix}></MymonXValidate>
                        </Col>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name="mobile_number"
                                placeholder="Mobile Number"
                                value={formData.mobile_number}
                                onChange={handleChange}
                                className={`${errors.mobile_number ? 'has-validation' : ''}`}
                            />
                            <MymonXValidate fieldError={errors.mobile_number}></MymonXValidate>
                        </Col>
                    </Row>
                </Col>
            </Form.Group>
            <Col sm="12" className="text-end">
                {
                    selectedContact === undefined && <Button variant="primary" type="button" onClick={handleSubmit}>
                        Add
                    </Button>
                }

                {
                    selectedContact !== undefined && <Button variant="primary" type="button" onClick={handleUpdateSubmit}>
                        Update
                    </Button>
                }
            </Col>
        </>

    );
};

export default ContactForm;
