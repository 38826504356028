// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  

.tabs {
  display: flex;
}

.tabs button {
  background-color: #f2f2f2;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color:var(--color-blue);
  font-weight:bold;
  font-size:14px;
}

.tabs button:hover {
  background-color: var(--color-blue);
    color:var(--color-whhite);
}
.nav-link button:hover {
  background-color: var(--color-blue)!important;
    color:var(--color-whhite)!important;
}
.tabs button.active {
  background-color: var(--color-blue);
  color:var(--color-whhite);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  background-color: var(--color-blue)!important;
  color:var(--color-whhite)!important;
}

.tab-content {
  margin-top: 20px;
}
.healthMetrics{
    max-width: auto;
    max-height: auto;
}
canvas{
    width:100% !important;
    height:auto !important;
}
small{
    font-size:10px;
}
.card-header{
    padding:15px;
}
.card-header h5{
    margin-bottom:0px;
}`, "",{"version":3,"sources":["webpack://./src/ui/containers/portal/client-users/client-detail/style.css"],"names":[],"mappings":";;;AAGA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mCAAmC;IACjC,yBAAyB;AAC7B;AACA;EACE,6CAA6C;IAC3C,mCAAmC;AACvC;AACA;EACE,mCAAmC;EACnC,yBAAyB;AAC3B;AACA;EACE,6CAA6C;EAC7C,mCAAmC;AACrC;;AAEA;EACE,gBAAgB;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B;AACA;IACI,cAAc;AAClB;AACA;IACI,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB","sourcesContent":["\n  \n\n.tabs {\n  display: flex;\n}\n\n.tabs button {\n  background-color: #f2f2f2;\n  border: none;\n  padding: 10px 10px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  color:var(--color-blue);\n  font-weight:bold;\n  font-size:14px;\n}\n\n.tabs button:hover {\n  background-color: var(--color-blue);\n    color:var(--color-whhite);\n}\n.nav-link button:hover {\n  background-color: var(--color-blue)!important;\n    color:var(--color-whhite)!important;\n}\n.tabs button.active {\n  background-color: var(--color-blue);\n  color:var(--color-whhite);\n}\n.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{\n  background-color: var(--color-blue)!important;\n  color:var(--color-whhite)!important;\n}\n\n.tab-content {\n  margin-top: 20px;\n}\n.healthMetrics{\n    max-width: auto;\n    max-height: auto;\n}\ncanvas{\n    width:100% !important;\n    height:auto !important;\n}\nsmall{\n    font-size:10px;\n}\n.card-header{\n    padding:15px;\n}\n.card-header h5{\n    margin-bottom:0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
