import { Form } from "react-bootstrap";
import './style.css';

interface MymonXValidateProps {
    fieldError?: string;
}

const MymonXValidate = ({fieldError}: MymonXValidateProps) => {
    return (
        <>
            {
                fieldError && <Form.Control.Feedback type="invalid">
                                {fieldError}
                            </Form.Control.Feedback>
            }
        </>
    )
};

export default MymonXValidate;