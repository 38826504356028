import { Form } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import './style.css';

interface MymonXReqAstericProps {
    id?: string;
}

const MymonXReqAsteric = ({id}: MymonXReqAstericProps) => {
    return (
        <span className="ml-5" style={{color: 'red'}}>
            *
        </span>
    )
};

export default MymonXReqAsteric;