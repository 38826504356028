import { Col, Dropdown, Pagination, Row } from "react-bootstrap";
import "./style.css";

export interface MymonXPaginationProps {
    totalPages: number;
    currentPage: number;
    recordPerPage: number;
    onPageChange: (page: number) => void;
    onRecordPerPageChange: (page_size: number) => void;
}

const MymonXPagination = ({ totalPages, currentPage, recordPerPage, onPageChange, onRecordPerPageChange }: MymonXPaginationProps) => {

    const pagesToShow = 5;
    const halfPagesToShow = Math.floor(pagesToShow / 2);

    let startPage = currentPage - halfPagesToShow;
    if (startPage < 1) {
        startPage = 1;
    }

    let endPage = startPage + pagesToShow - 1;
    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <Dropdown>
                    <Dropdown.Toggle variant="default" id="dropdown-basic">
                        Rows per page: {recordPerPage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => onRecordPerPageChange(5)}>5</Dropdown.Item>
                        <Dropdown.Item onClick={() => onRecordPerPageChange(10)}>10</Dropdown.Item>
                        <Dropdown.Item onClick={() => onRecordPerPageChange(25)}>25</Dropdown.Item>
                        <Dropdown.Item onClick={() => onRecordPerPageChange(50)}>50</Dropdown.Item>
                        <Dropdown.Item onClick={() => onRecordPerPageChange(100)}>100</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Pagination>
                    <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
                    <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
                    {currentPage > halfPagesToShow + 1 && <Pagination.Ellipsis />}
                    {pageNumbers.map((page) => (
                        <Pagination.Item key={page} active={page === currentPage} onClick={() => onPageChange(page)}>
                            {page}
                        </Pagination.Item>
                    ))}
                    {currentPage < totalPages - halfPagesToShow && <Pagination.Ellipsis />}
                    <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                    <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
                </Pagination>
            </div>
        </>
    )
}

export default MymonXPagination;