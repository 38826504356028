import { CreateUpdateMedicationDto, MedicationResultDto } from "../../../../../../../application/features/medical-service/models/medicationresultdto";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import MymonXReqAsteric from "../../../../../../components/mymonx-req-asteric";
import MymonXValidation from "../../../../../../components/mymonx-validation";
import { useAppDispatch } from "../../../../../../../application/store/useStore";
import { setSelectedMedication, setShowMedicationForm } from "../../../../../../../application/features/medical-service/medicalServiceSlice";
import { postMedicationAsync, putMedicationAsync } from "../../../../../../../application/features/medical-service/medicalServiceThunk";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import moment from "moment";

interface CreateOrUpdateMedicationFormProps {
    user_profile_sid: string;
    selectedMedication?: MedicationResultDto;
}


const CreateOrUpdateMedicationForm = ({ user_profile_sid, selectedMedication }: CreateOrUpdateMedicationFormProps) => {
    const dispatch = useAppDispatch();
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
    } = useForm<CreateUpdateMedicationDto>({
        defaultValues: selectedMedication
    });

    const onSubmit = (request: CreateUpdateMedicationDto) => {
        if (request.sid !== '') {
            const medicationReq = {
                sid: request.sid,
                user_profile_sid: request.user_profile_sid,
                medication: request.medication,
                start_date: request.start_date,
                end_date: request.end_date,
                deleted: false,
                deleted_datetime: new Date(),
                created_datetime: new Date(),
                updated_datetime: new Date()

            } as MedicationResultDto;

            dispatch(putMedicationAsync(medicationReq)).then(res => {
                if (res && res.payload) {
                    dispatch(setSelectedMedication(undefined));
                    dispatch(setShowMedicationForm(false));
                }
            });
        } else {
            const medicationReq = {
                sid: "",
                user_profile_sid: user_profile_sid,
                medication: request.medication,
                start_date: request.start_date,
                end_date: request.end_date,
                deleted: false,
                deleted_datetime: new Date(),
                created_datetime: new Date(),
                updated_datetime: new Date()

            } as MedicationResultDto;

            dispatch(postMedicationAsync(medicationReq)).then(res => {
                if (res && res.payload) {
                    dispatch(setSelectedMedication(undefined));
                    dispatch(setShowMedicationForm(false));
                }
            });
        }

    }

    useEffect(() => {
        if (selectedMedication) {
            setValue('sid', selectedMedication.sid);
            setValue('user_profile_sid', selectedMedication.user_profile_sid);
            setValue('medication', selectedMedication.medication);
            setValue('start_date', selectedMedication.start_date);
            setValue('end_date', selectedMedication.end_date);
            setStartDate(selectedMedication.start_date);
            setEndDate(selectedMedication.end_date);
        }
    }, [selectedMedication])


    const handleStartDateChange = (dateChange: Date) => {
        setValue("start_date", dateChange, {
            shouldDirty: true
        });
        setStartDate(dateChange);
    };

    const handleEndDateChange = (dateChange: Date) => {
        setValue("end_date", dateChange, {
            shouldDirty: true
        });
        setEndDate(dateChange);
    };

    return (
        <>
            <Form id="create-or-update-medication-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Form.Group as={Row} className="mb-3" controlId={`create-or-update-medication-form.medication`}>
                            <Form.Label column sm={12} md={4}>
                                Medication<MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="medication"
                                    {...register('medication', {
                                        required: {
                                            value: true,
                                            message: 'medication is required'
                                        }
                                    })}
                                    className={`${!!errors.medication ? 'has-validation' : ''}`}
                                />
                                <MymonXValidation fieldError={errors.medication} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`create-or-update-medication-form.start_date`}>
                            <Form.Label column sm={12} md={4}>
                                Start Date <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="start_date"
                                    control={control}
                                    defaultValue={startDate}
                                    render={() => (
                                        <DatePicker
                                            className={`form-control w-100 ${!!errors.start_date ? 'has-validation' : ''}`}
                                            dateFormat={'dd/MM/yyyy'}
                                            maxDate={moment().toDate()}
                                            selected={startDate}
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleStartDateChange}
                                        />
                                    )}
                                />
                                <MymonXValidation fieldError={errors.start_date} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`create-or-update-medication-form.date_of_birth`}>
                            <Form.Label column sm={12} md={4}>
                                End Date <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="end_date"
                                    control={control}
                                    defaultValue={endDate}
                                    render={() => (
                                        <DatePicker
                                            className={`form-control w-100 ${!!errors.end_date ? 'has-validation' : ''}`}
                                            dateFormat={'dd/MM/yyyy'}
                                            maxDate={moment().toDate()}
                                            selected={endDate}
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleEndDateChange}
                                        />
                                    )}
                                />
                                <MymonXValidation fieldError={errors.end_date} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group as={Row} className="mb-3" controlId="formSubmitButtons">
                    <Col sm="12" className="text-end">
                        <Button variant="secondary" type="button" onClick={() => {
                            dispatch(setSelectedMedication(undefined))
                            dispatch(setShowMedicationForm(false))
                        }} >
                            Cancel
                        </Button>{' '}
                        <Button variant="primary" type="submit" >
                            Create
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        </>
    )
};

export default CreateOrUpdateMedicationForm;