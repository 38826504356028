import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useAppDispatch } from "../../../../application/store/useStore";
import { getOrgFromJwt } from "../../../../application/common/helpers/jwt-helper";

import { UserSosModelDto } from "../../../../application/features/sos-service/models/user-sos-model-dto";
import moment from "moment";
import CustomModal from "../../../components/mymonx-Emergency-Alert";
import { SosResultDto } from "../../../../application/features/sos-service/models/sos-result-dto";

interface SosProps {
    sosResultProp?: SosResultDto[];
    onSosModalClose?: () => void;
}

const Sos: React.FC<SosProps> = ({ sosResultProp, onSosModalClose }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedSos, setSelectedSos] = useState<UserSosModelDto | undefined>(undefined);

    //const { sosModels, sosResult } = useAppSelector((state: RootState) => state.sosService);

    const dispatch = useAppDispatch();
    const org_id = getOrgFromJwt();

    useEffect(() => {

        if (sosResultProp?.length === 0) {
            return;
        }


    }, [dispatch, org_id]);

    const onDetailClick = (row: SosResultDto) => {

        const sosModel = {
            org_id: row.org_id,
            user_sid: row.user_profile_sid,
            showModel: row.status === 'OPEN',
            sos_id: row.sid,
            from_date: row.sos_date_time,
            notes: row.notes
        } as UserSosModelDto;
        setSelectedSos(sosModel);
        setShowModal(true);
    }

    const onModelClose = () => {
        setSelectedSos(undefined);
        setShowModal(false);
        onSosModalClose?.();  // Signal parent component
    }

    return (
        <>
            <Table responsive bordered>
                <thead key={`client-header`}>
                    <tr key={`client-header-row`}>
                        <th></th>
                        <th>
                            Name
                        </th>
                        <th>
                            Sos Type
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            From Date
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody key={`sos-body`}>
                    {sosResultProp &&
                        sosResultProp?.map((row: SosResultDto, index: number) => (
                            <tr key={`row-${index}`}>
                                <td key={`sos-id-${index}`}>
                                    {index + 1}
                                </td>
                                <td key={`user-sid-${index}`}>
                                    {`${row.first_name} ${row.last_name}`}
                                </td>
                                <td key={`sos-type-${index}`}>
                                    {row.sos_type}
                                </td>
                                <td key={`status-${index}`}>
                                    {row.status}
                                </td>
                                <td key={`from-date-${index}`}>
                                    {moment(row.sos_date_time).format('DD/MM/YYYY HH:mm:ss')}
                                </td>
                                <td key={`show-model-${index}`}>
                                    <button className='btn btn-success btn-sm me-2 mb-2' onClick={() => onDetailClick(row)}>Show Detail</button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            {showModal && selectedSos &&
                <CustomModal
                    key={`modal-${selectedSos.sos_id}`}
                    show={showModal}
                    handleClose={onModelClose}
                    sosModel={selectedSos}
                />
            }
        </>
    )
}

export default Sos;