import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "../../common/models/api-error";
import { getErrorPayload } from "../../common/helpers/error-helper";
import ComplianceServiceApi from "../../../infrastructure/compliance-service";
import { ComplianceRecordDto, CreateComplianceDocumentDto, CreateComplianceRecordDto, GetComplianceDto } from "./models/create-compliance-record-dto";



export const createComplianceDocumentResultAsync = createAsyncThunk<string, CreateComplianceDocumentDto,
    { rejectValue: ApiError | undefined }
>(
    "complianceService/createComplianceDocument",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ComplianceServiceApi.createComplianceDocument(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);

export const createComplianceRecordResultAsync = createAsyncThunk<string, ComplianceRecordDto,
    { rejectValue: ApiError | undefined }
>(
    "complianceService/createComplianceRecord",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ComplianceServiceApi.createComplianceRecord(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const getActiveComplianceResultAsync = createAsyncThunk<CreateComplianceDocumentDto[], GetComplianceDto,
    { rejectValue: ApiError | undefined }
>(
    "configurationService/getActiveCompliance",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await ComplianceServiceApi.getActiveCompliance(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);

