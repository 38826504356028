import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);



const getMonthlyReports = (user_profile_sid: string) => {

    return organisationPortalApi.get<string>(`/api/v0/reports/monthly/${user_profile_sid}`);
}

const getMonthlyReportsByVital = (user_profile_sid: string, vital_type: string) => {

    return organisationPortalApi.get<string>(`/api/v0/reports/vitals-dashboards?user_profile_sid=${user_profile_sid}&vital=${vital_type}`);
}
const ReportServiceApi = {
    getMonthlyReports,
    getMonthlyReportsByVital

}

export default ReportServiceApi;