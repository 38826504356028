import Chart from 'react-apexcharts';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../../../../application/store/useStore';
import { RootState } from '../../../../../../../application/store';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { GraphFilterDto } from '../../../../../../../application/features/health-service/models/graph-filter-dto';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataItem, SeriesData, VitalsGraphRequestDto } from '../../../../../../../application/features/health-service/models/graph-request-dto';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import { resetStepVitalsGraph, setVitalsGraphRequest } from '../../../../../../../application/features/activity-service/activityServiceSlice';
import { getActivityGraphAsync } from '../../../../../../../application/features/activity-service/activityServiceThunk';
import { StepData } from '../../../../../../../application/features/activity-service/models/steps-graph-dto';

interface StepGraphProps {
    id: string;
    userId: string;
}


const StepGraph = ({ id, userId }: StepGraphProps) => {
    const dispatch = useAppDispatch();

    const vitalsGraphRequest = useAppSelector((state: RootState) => state.activityService.vitalsGraphRequest['STEPS']);
    const { stepDataDto, graphFilterDto } = useAppSelector((state: RootState) => state.activityService);
    //const graphFilterDto = useAppSelector((state: RootState) => state.activityService.graphFilterDto);
    const [startDate, setStartDate] = useState<Date | null>(moment().startOf('day').toDate());//moment(moment().diff(6, 'days')).startOf('day').toDate());
    const [endDate, setEndDate] = useState<Date | null>(moment().endOf('day').toDate());
    const [selectedPeriod, setSelectedPeriod] = useState('DAY');
    const [xAxisCategories, setxAxisCategories] = useState<string[]>([]);
    const [sortedData, setSortedData] = useState<StepData[]>([]);
    const {
        control,
        handleSubmit,
        setValue,
    } = useForm<VitalsGraphRequestDto>({
        defaultValues: {
            startDate: moment().startOf('day').format('YYYY-MM-DD'),
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
            vital_type: 'STEPS'

        }
    });
    useEffect(() => {
        dispatch(resetStepVitalsGraph(vitalsGraphRequest.vital_type))
    }, [dispatch]);

    const handleFilterClick = (period: any) => {

        if (userId) {
            let start_date = moment().startOf('day').toDate();
            let end_date = moment().endOf('day').toDate();
            let graphType = 'DAY';
            switch (period) {
                case 'DAY':
                    start_date = moment().startOf('day').toDate();
                    graphType = 'DAY';
                    break;
                case 'WEEK':
                    start_date = moment().subtract(6, 'day').startOf('day').toDate();
                    graphType = 'WEEK';
                    break;
                case 'MONTH':
                    start_date = moment().subtract(29, 'day').startOf('day').toDate();
                    graphType = 'MONTH';
                    break;
                case 'YEAR':
                    start_date = moment().subtract(364, 'day').startOf('day').toDate();
                    graphType = 'YEAR';
                    break;
                case 'RANGE':
                    graphType = 'RANGE';

                    break;
                default:
                    break;
            }

            setStartDate(start_date);
            setEndDate(end_date);
            setSelectedPeriod(period);

            if (graphType !== 'RANGE') {
                const request = {
                    userId: userId,
                    vital_type: vitalsGraphRequest.vital_type,
                    graphType: graphType,
                    startDate: moment(start_date).format('YYYY-MM-DD'),
                    endDate: moment(end_date).format('YYYY-MM-DD'),
                };
                dispatch(setVitalsGraphRequest({ vital_type: request.vital_type, request }));

            }
        }

    };

    useEffect(() => {
        // Reset to initial state when component mounts
        setStartDate(moment().startOf('day').toDate());
        setEndDate(moment().endOf('day').toDate());
        setSelectedPeriod('DAY');

        const defaultRequest = {
            userId: userId,
            vital_type: 'STEPS',
            graphType: 'DAY',
            startDate: moment().startOf('day').format('YYYY-MM-DD'),
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
        };
        dispatch(setVitalsGraphRequest({ vital_type: defaultRequest.vital_type, request: defaultRequest }));

        return () => {
            dispatch(resetStepVitalsGraph('STEPS'));
        };
    }, [dispatch, userId]);



    useEffect(() => {
        fetchGraphData();
    }, [dispatch, vitalsGraphRequest])

    const fetchGraphData = () => {

        if (vitalsGraphRequest && vitalsGraphRequest.userId !== undefined && vitalsGraphRequest.userId !== '') {
            const startDate = new Date(vitalsGraphRequest.startDate);
            const endDate = new Date(vitalsGraphRequest.endDate);
            dispatch(getActivityGraphAsync(vitalsGraphRequest));
        }
    };

    useEffect(() => {
        const interval = setInterval(fetchGraphData, 300000); // 60000ms = 1 minute
        return () => clearInterval(interval); // Cleanup on unmount
    }, [vitalsGraphRequest]);

    const xAxisFormatter = (value: string) => {
        const date = moment(value);

        if (selectedPeriod === 'DAY') {
            return date.format('HH:mm').toString();;
        } else if (selectedPeriod === 'WEEK' || selectedPeriod === 'MONTH' || selectedPeriod === 'YEAR') {
            return date.format('DD/MM').toString();;
        } else if (selectedPeriod === 'RANGE') {
            const startDate = moment(vitalsGraphRequest.startDate);
            const endDate = moment(vitalsGraphRequest.endDate);

            if (startDate.isSame(endDate, 'day')) {
                return date.format('HH:mm').toString();;
            } else if (endDate.diff(startDate, 'days') > 1 && endDate.diff(startDate, 'days') <= 365) {
                return date.format('DD/MM').toString();;
            } else {
                return date.format('YYYY').toString();;
            }
        } else {
            return date.format('YYYY').toString();;
        }
    };

    const handleStartDateChange = (dateChange: Date) => {
        setValue("startDate", moment(dateChange).startOf('day').format('YYYY-MM-DD'), {
            shouldDirty: true
        });
        setStartDate(dateChange);
    };

    const handleEndDateChange = (dateChange: Date) => {
        setValue("endDate", moment(dateChange).endOf('day').format('YYYY-MM-DD'), {
            shouldDirty: true
        });
        setEndDate(dateChange);
    };

    const onSubmit = (requestObj: VitalsGraphRequestDto) => {

        const sDate = moment(requestObj.startDate).startOf('day').format('YYYY-MM-DD');
        const eDate = moment(requestObj.endDate).endOf('day').format('YYYY-MM-DD');
        if (userId) {

            const request = {
                userId: userId,
                vital_type: requestObj.vital_type,
                graphType: selectedPeriod,
                startDate: sDate,
                endDate: eDate,
            };
            dispatch(setVitalsGraphRequest({ vital_type: requestObj.vital_type, request }));

        }

    }

    const combinedOptions: ApexOptions = {
        chart: {
            // id: "combined-bar", // Update the chart ID
            // type: "bar", // Keep the chart type as 'bar'
            id: "line-graph", // Update the chart ID
            type: "line", // Keep the chart type as 'bar'
        },
        xaxis: {
            type: 'datetime', // Keep x-axis as datetime type
            categories: xAxisCategories, // Use the same xAxisCategories for all series
            labels: {
                formatter: xAxisFormatter, // Use the formatter function
                rotate: 0,
                style: {
                    fontSize: '12px',
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
        },
        yaxis: {
            labels: {
                formatter: (value: number) => value.toFixed(0), // Format y-axis labels
            },
        },
        plotOptions: {
            bar: {
                horizontal: false, // Ensure bars are vertical
                columnWidth: '80%', // Adjust the width of the bars
                dataLabels: {
                    position: 'top', // Position labels on top of the bars
                },
            },
        },
        colors: ["#21609a", "#e97232", "#219a35"], // Colors for each series
        dataLabels: {
            enabled: false, // Enable data labels for bars
            formatter: (val: number) => val.toFixed(0), // Format data labels
            style: {
                fontSize: '08px',
                colors: ["#304758"]
            }
        },
        tooltip: {
            y: {
                formatter: (val: number, { seriesIndex }: { seriesIndex: number }) => {
                    // Format tooltip to display appropriate units
                    // Format tooltip to display appropriate units
                    if (seriesIndex === 0) return `${val} Steps`;
                    if (seriesIndex === 1) return `${val} meters`;
                    if (seriesIndex === 2) return `${val} Calories`;

                    // Provide a default string return value
                    return `${val}`;
                }
            }
        }
    };

    const formatCombinedSeriesData = () => {
        if (!stepDataDto?.data) return [];

        // Sort the data by step_time in ascending order
        const sortedData = [...stepDataDto.data].sort((a, b) => new Date(a.step_time).getTime() - new Date(b.step_time).getTime());

        return [
            {
                name: 'Steps',
                data: sortedData.map((entry: any) => ({
                    x: moment(entry.step_time).format('YYYY-MM-DD HH:mm:ss'),
                    y: entry.total_steps
                }))
            }
            // ,
            // {
            //     name: 'Distance',
            //     data: sortedData.map((entry: any) => ({
            //         x: moment(entry.step_time).format('YYYY-MM-DD HH:mm:ss'),
            //         y: entry.total_distance
            //     }))
            // },
            // {
            //     name: 'Calories',
            //     data: sortedData.map((entry: any) => ({
            //         x: moment(entry.step_time).format('YYYY-MM-DD HH:mm:ss'),
            //         y: entry.total_calories
            //     }))
            // }
        ];
    };
    const formatCombinedSeriesOneDayData = (): SeriesData[] => {
        if (!stepDataDto?.data) return [];

        // Sort the data by step_time in ascending order
        const sortedData = [...stepDataDto.data].sort((a, b) => new Date(a.step_time).getTime() - new Date(b.step_time).getTime());

        // Assuming you want to group data by hour
        const hourlyData = groupDataByHour(
            sortedData.map(entry => ({
                time: new Date(entry.step_time),
                steps: entry.total_steps,
                distance: entry.total_distance,
                calories: entry.total_calories
            }))
        );

        return [
            {
                name: 'Steps',
                data: hourlyData.map(item => ({
                    x: item.hour,
                    y: typeof item.totalSteps === 'number' ? item.totalSteps : undefined, // Ensure `y` is a number or undefined
                })),
            },
            {
                name: 'Distance',
                data: hourlyData.map(item => ({
                    x: item.hour,
                    y: typeof item.totalDistance === 'number' ? item.totalDistance : undefined, // Ensure `y` is a number or undefined
                })),
            },
            {
                name: 'Calories',
                data: hourlyData.map(item => ({
                    x: item.hour,
                    y: typeof item.totalCalories === 'number' ? item.totalCalories : undefined, // Ensure `y` is a number or undefined
                })),
            }
        ];
    };

    // Helper function to group data by hour
    const groupDataByHour = (data: Array<{ time: Date; steps: number; distance: number; calories: number; }>) => {
        // Create an object to hold the hourly data
        const hourlyData: Record<string, { hour: string; totalSteps: number; totalDistance: number; totalCalories: number; count: number; }> = {};

        data.forEach(entry => {
            const hour = moment(entry.time).startOf('hour').format('YYYY-MM-DD HH:00:00');

            if (!hourlyData[hour]) {
                hourlyData[hour] = {
                    hour: hour,
                    totalSteps: 0,
                    totalDistance: 0,
                    totalCalories: 0,
                    count: 0
                };
            }

            hourlyData[hour].totalSteps += entry.steps;
            hourlyData[hour].totalDistance += entry.distance;
            hourlyData[hour].totalCalories += entry.calories;
            hourlyData[hour].count += 1;
        });

        return Object.values(hourlyData).map(data => ({
            hour: data.hour,
            totalSteps: data.totalSteps / data.count, // Average per hour
            totalDistance: data.totalDistance / data.count, // Average per hour
            totalCalories: data.totalCalories / data.count // Average per hour
        }));
    };


    // Use the combined series data
    const seriesCombinedData = formatCombinedSeriesData();
    const seriesV2Data = formatCombinedSeriesOneDayData();
    // Extract hours
    const hours = seriesV2Data[0]?.data.map(item => item.x.toString()) || [];
    const findData = (name: string): number[] =>
        (seriesV2Data.find((d: SeriesData) => d.name === name)?.data.map((item: DataItem) => Number(item.y ?? 0))) || [];


    const minValues = findData('Min');
    const maxValues = findData('Max');
    const avgValues = findData('Avg');
    useEffect(() => {
        if (stepDataDto) {
            const sortedData = [...stepDataDto.data].sort((a, b) => new Date(a.step_time).getTime() - new Date(b.step_time).getTime());
            setSortedData(sortedData);
            // Set xAxisCategories based on sorted data
            const xAxisCategories = sortedData.map((stage: StepData) => moment(stage.step_time).format('YYYY-MM-DD HH:mm:ss'));
            setxAxisCategories(xAxisCategories);
        }
    }, [dispatch, stepDataDto]);


    const isGraphDataEmpty = (data: any[]): boolean => {
        return data.length === 0;
    };

    return (
        <>
            <div><h2>Steps </h2></div>
            <div className="d-flex">
                <ButtonGroup>
                    {
                        graphFilterDto && graphFilterDto.map((item: GraphFilterDto, index: number) => {
                            return (
                                <Button
                                    key={`btn-${item.key}-${index}`}
                                    style={{ margin: '1 rem' }}
                                    onClick={() => handleFilterClick(item.value)}
                                    variant={selectedPeriod === item.key ? 'primary' : 'secondary'}
                                >
                                    {item.key}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>

                {
                    selectedPeriod === 'RANGE' ? <>
                        <form className="ms-2 d-flex" id="filter-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue={moment(startDate).format('YYYY-MM-DD')}
                                render={() => (
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={'dd/MM/yyyy'}
                                        maxDate={moment().toDate()}
                                        selected={startDate}
                                        placeholderText="dd/mm/yyyy"
                                        onChange={handleStartDateChange}
                                    />
                                )}
                            />
                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue={moment(endDate).format('YYYY-MM-DD')}
                                render={() => (
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={'dd/MM/yyyy'}
                                        maxDate={moment().toDate()}
                                        selected={endDate}
                                        placeholderText="dd/mm/yyyy"
                                        onChange={handleEndDateChange}
                                    />
                                )}
                            />
                            <Button variant="primary" type="submit" >
                                Filter
                            </Button>
                        </form>
                    </> : <>

                    </>
                }
            </div>
            {
                stepDataDto?.data && isGraphDataEmpty(stepDataDto?.data || []) ?
                    (<div className="col-12 text-center">
                        <div className="alert alert-warning" role="alert">
                            No data found
                        </div>
                    </div>) :
                    (
                        <>
                            {
                                stepDataDto?.data && <div>
                                    <div id="chart">
                                        <Chart options={combinedOptions} series={seriesCombinedData} type="line" height={350} />
                                    </div>
                                    <div id="html-dist"></div>
                                </div>
                            }
                            {
                                stepDataDto && stepDataDto?.data.length &&
                                <div style={{ overflowX: 'auto' }}> <Table striped bordered hover key={`steps-table-${1}`}>
                                    {vitalsGraphRequest?.graphType === 'DAY' ?
                                        (
                                            <>
                                                <thead key={`steps-first-thead`}>
                                                    <th>
                                                    </th>
                                                    {hours.map((hour: string, index: number) => {
                                                        // Use moment to format the date-time string
                                                        const formattedHour = moment(hour).format('HH');
                                                        // Calculate the next hour and format it
                                                        const nextHour = moment(hour).add(-1, 'hour').format('HH');

                                                        return (
                                                            <th key={`day-month-year-${index}`} style={{ border: '1px solid black', padding: '8px', textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                                {nextHour}/ {formattedHour}
                                                            </th>
                                                        );
                                                    })}


                                                </thead>
                                                <tbody>
                                                    <tr key={`row-rr-avg`}>
                                                        <td key={`col-rr-avg`} className='w-25'>
                                                            Average
                                                        </td>

                                                        {avgValues.map((value: number, index: number) => (
                                                            <td key={`col-${value}-rr-avg-${index}`} style={{ background: 'white', color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}

                                                    </tr>

                                                    <tr key={`row-rr-max`}>
                                                        <td key={`col-rr-max`} className='w-25'>
                                                            Max
                                                        </td>
                                                        {maxValues.map((value: number, index: number) => (
                                                            <td key={`col-${value}-rr-max-${index}`} style={{ background: 'white', color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}
                                                    </tr>


                                                    {

                                                        <tr key={`row-rr-min`}>
                                                            <td key={`col-rr-min`} className='w-25'>
                                                                Min
                                                            </td>
                                                            {minValues.map((value: number, index: number) => (
                                                                <td key={`col-${value}-rr-min-${index}`} style={{ background: 'white', color: '#000' }}>
                                                                    {value}
                                                                </td>
                                                            ))}
                                                        </tr>

                                                    }
                                                </tbody>
                                            </>
                                        ) :
                                        (
                                            <>
                                                <thead key={`rr-first-thead`}>
                                                    <tr>
                                                        <th>
                                                        </th>
                                                        {
                                                            sortedData && sortedData?.map((item: StepData, index: number) => {
                                                                return (
                                                                    <>
                                                                        <th key={`day-month-year-${item.step_time}`}>

                                                                            {
                                                                                xAxisFormatter(item.step_time.toString())
                                                                            }

                                                                        </th>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        sortedData && sortedData.length > 0 && (
                                                            <tr key={`row-steps-max`}>
                                                                <td key={`col-steps-max`} className='w-25'>
                                                                    Steps
                                                                </td>
                                                                {sortedData.map((item: StepData, index: number) => {
                                                                    return (
                                                                        <td key={`col-${item}-steps-max-${index}`}>
                                                                            {item.total_steps}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )
                                                    }
                                                    <tr key={`row-respiratory-rate-avg`}>
                                                        <td key={`col-respiratory-rate-avg`} className='w-25'>
                                                            Distance
                                                        </td>
                                                        {
                                                            sortedData && sortedData.map((item: StepData, index: number) => {
                                                                return <td key={`col-${item.total_distance}-respiratory-rate-avg-${index}`} style={{ background: 'white', color: '#000' }}>
                                                                    <p>{(item.total_distance / 1000).toFixed(2)} km's</p>


                                                                </td>
                                                            })
                                                        }
                                                    </tr>
                                                    {
                                                        sortedData && sortedData.length > 0 && (
                                                            <tr key={`row-steps-max`}>
                                                                <td key={`col-steps-max`} className='w-25'>
                                                                    Calories
                                                                </td>
                                                                {sortedData.map((item: StepData, index: number) => {
                                                                    return (
                                                                        <td key={`col-${item}-steps-max-${index}`}>
                                                                            {item.total_calories}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )
                                                    }


                                                </tbody>
                                            </>
                                        )
                                    }


                                </Table>
                                </div>
                            }
                        </>
                    )
            }
        </>
    )
}

export default StepGraph;