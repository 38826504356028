import { Button, CloseButton, Modal } from "react-bootstrap";

interface MymonXConfirmationDialogProps {
    show: boolean;
    title: string;
    description: string;
    cancelButtonText: string;
    confirmButtonText: string;
    onHide?(): void;
    onConfirm?(): void;
}

const MymonXConfirmationDialog = ({ show, title, description, cancelButtonText, confirmButtonText, onHide, onConfirm }: MymonXConfirmationDialogProps) => {
    return (
        <>
            <Modal
                show={show}
                //onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <CloseButton onClick={onHide} />
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {description}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        {cancelButtonText}
                    </Button>
                    <Button variant="primary"  onClick={onConfirm}>
                        {confirmButtonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MymonXConfirmationDialog;