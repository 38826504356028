import Chart from 'react-apexcharts';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../../../../application/store/useStore';
import { RootState } from '../../../../../../../application/store';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { resetEcgVitalsGraph, resetVitalsGraph, setVitalsGraphRequest } from '../../../../../../../application/features/health-service/healthServiceSlice';
import { GraphFilterDto } from '../../../../../../../application/features/health-service/models/graph-filter-dto';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getecgVitalsGraphAsync } from '../../../../../../../application/features/health-service/healthServiceThunk';
import { DataItem, SeriesData, VitalsGraphRequestDto } from '../../../../../../../application/features/health-service/models/graph-request-dto';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';

interface EcgGraphProps {
    id: string;
    userId: string;
}
const EcgGraph = ({ id, userId }: EcgGraphProps) => {
    const dispatch = useAppDispatch();

    const vitalsGraphRequest = useAppSelector((state: RootState) => state.healthService.vitalsGraphRequest['ECG']);
    const { ecgDataDto } = useAppSelector((state: RootState) => state.healthService);
    // const graphFilterDto = useAppSelector((state: RootState) => state.healthService.graphFilterDto);
    // const [startDate, setStartDate] = useState<Date | null>(moment().startOf('day').toDate());//moment(moment().diff(6, 'days')).startOf('day').toDate());
    // const [endDate, setEndDate] = useState<Date | null>(moment().endOf('day').toDate());
    // const [selectedPeriod, setSelectedPeriod] = useState('DAY');
    // const [fs, setFs] = useState(128);
    // State for processed waveform data
    const [seriesData, setSeriesData] = useState<number[] | undefined>([]);


    const {
        control,
        handleSubmit,
        setValue,
    } = useForm<VitalsGraphRequestDto>({
        defaultValues: {
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            vital_type: 'ECG'

        }
    });
    useEffect(() => {
        dispatch(resetEcgVitalsGraph(vitalsGraphRequest.vital_type))
    }, [dispatch]);

    const handleFilterClick = (period: any) => {
        if (userId) {
            let start_date = moment().startOf('day').toDate();
            let end_date = moment().endOf('day').toDate();
            let graphType = 'DAY';
            switch (period) {
                case 'DAY':
                    start_date = moment().startOf('day').toDate();
                    graphType = 'DAY';
                    break;
                case 'WEEK':
                    start_date = moment().subtract(6, 'day').startOf('day').toDate();
                    graphType = 'WEEK';
                    break;
                case 'MONTH':
                    start_date = moment().subtract(29, 'day').startOf('day').toDate();
                    graphType = 'MONTH';
                    break;
                case 'YEAR':
                    start_date = moment().subtract(364, 'day').startOf('day').toDate();
                    graphType = 'YEAR';
                    break;
                case 'RANGE':
                    graphType = 'RANGE';

                    break;
                default:
                    break;
            }

            // setStartDate(start_date);
            // setEndDate(end_date);
            // setSelectedPeriod(period);

            if (graphType !== 'RANGE') {
                const request = {
                    userId: userId,
                    vital_type: vitalsGraphRequest.vital_type,
                    graphType: graphType,
                    startDate: moment(start_date).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(end_date).format('YYYY-MM-DD HH:mm:ss'),
                };
                dispatch(setVitalsGraphRequest({ vital_type: request.vital_type, request }));

            }
        }

    };

    useEffect(() => {
        const defaultRequest = {
            userId: userId,
            vital_type: 'ECG',
            graphType: 'DAY',
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch(setVitalsGraphRequest({ vital_type: defaultRequest.vital_type, request: defaultRequest }));

        return () => {
            dispatch(resetVitalsGraph('ECG'));
        };
    }, [dispatch, userId]);

    useEffect(() => {
        fetchGraphData();
    }, [dispatch, vitalsGraphRequest])

    const fetchGraphData = () => {
        if (vitalsGraphRequest && vitalsGraphRequest.userId !== undefined && vitalsGraphRequest.userId !== '') {
            dispatch(getecgVitalsGraphAsync(vitalsGraphRequest));
        }
    };

    useEffect(() => {
        const interval = setInterval(fetchGraphData, 300000); // 60000ms = 1 minute
        return () => clearInterval(interval); // Cleanup on unmount
    }, [vitalsGraphRequest]);
    useEffect(() => {
        // Process data when ecgDataDto changes
        if (ecgDataDto?.waveform_samples) {
            const numbersArray = JSON.parse(ecgDataDto?.waveform_samples);
            setSeriesData(numbersArray);
        }
    }, [ecgDataDto]); // Runs when ecgDataDto changes

    // Chart options
    const options: ApexOptions = {
        chart: {
            type: 'line',
            height: 350,
        },
        xaxis: {
            // categories: xAxisCategories,
            title: {
                text: moment(ecgDataDto?.start_time).format('YYYY-MM-DD HH:MM:SS'),
            },
            labels: {
                show: false
            }

        },
        stroke: {
            curve: 'smooth',
            width: 3 // Set the width of the lines to 1 to make them thinner
        },
        yaxis: {
            title: {
                text: 'Amplitude',
            },
        },
        title: {
            text: 'Waveform Samples',
            align: 'left',
        },
        dataLabels: {
            enabled: false,
        },
    };

    // Chart series
    const series = [{
        name: 'Waveform Samples',
        data: seriesData ?? [],
    }];
    const isGraphDataEmpty = (data: string | null | undefined): boolean => {
        // Check if the data is null or undefined
        if (data == null) {
            return true;
        }
        // Check if the string is empty
        return data.length === 0;
    };

    return (
        <>
            <div><h2>ECG </h2></div>

            {
                ecgDataDto && isGraphDataEmpty(ecgDataDto?.waveform_samples ?? '') ?
                    (<div className="col-12 text-center">
                        <div className="alert alert-warning" role="alert">
                            No data found
                        </div>
                    </div>) :
                    (
                        <>
                            {
                                ecgDataDto && <div>
                                    <div id="chart">
                                        <Chart options={options} series={series} type="line" height={350} />
                                    </div>
                                    <div id="html-dist"></div>
                                </div>
                            }
                        </>
                    )
            }
        </>
    )
}

export default EcgGraph;
