import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../common/enums/api-status";
import { BaseState } from "../../common/models/base-state";
import { getOrgFromJwt } from "../../common/helpers/jwt-helper";
import { GpsResultDto } from "./models/gps-result-dto";
import { SosResultDto } from "./models/sos-result-dto";
import { acknowledgeSosResultAsync, closeSosResultAsync, getGpsResultAsync, getSosResultAsync, getVitalsResultAsync, saveNotesResultAsync } from "./sosServiceThunk";
import { VitalsResultDto } from "./models/vitals-result-dto";
import { UserSosModelDto } from "./models/user-sos-model-dto";

// Define the interface for SosServiceState
export interface SosServiceState extends BaseState {
    sosResult: SosResultDto[];
    gpsResult: GpsResultDto[];
    notesResult: string | undefined;
    closeResult: string | undefined;
    acknowledgeResult: string | undefined;
    vitalsResult: VitalsResultDto[];
    sosModels: UserSosModelDto[];
    initialClientRequest: { org_id: string };
}

// Initial client request object with org_id
const initialClientRequest = {
    org_id: getOrgFromJwt() || ''
}

// Initial state for SosService
const initialState: SosServiceState = {
    status: ApiStatus.IDLE,
    sosResult: [], // Initialize as null or appropriate initial value
    gpsResult: [], // Initialize as null or appropriate initial value
    vitalsResult: [],
    notesResult: '',
    closeResult: '',
    acknowledgeResult: '',
    sosModels: [],
    initialClientRequest: initialClientRequest,
    apiError: undefined // Assuming BaseState includes apiError
}

// Create the slice
export const sosServiceSlice = createSlice({
    name: 'sosService',
    initialState,
    reducers: {
        // Add reducers here if needed
        setSosResult(state, action: PayloadAction<SosResultDto[]>) {
            state.sosResult = action.payload;
        },
        setGpsResult(state, action: PayloadAction<GpsResultDto[]>) {
            state.gpsResult = action.payload;
        },
        setVitalsResult(state, action: PayloadAction<VitalsResultDto[]>) {
            state.vitalsResult = action.payload;
        },
        setNotesResult(state, action: PayloadAction<string>) {
            state.notesResult = action.payload;
        },
        setCloseResult(state, action: PayloadAction<string>) {
            state.closeResult = action.payload;
        },
        setSosModels(state, action: PayloadAction<UserSosModelDto[]>) {
            state.sosModels = action.payload;
        },
        setAcknowledgeResult(state, action: PayloadAction<string>) {
            state.acknowledgeResult = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(getSosResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getSosResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.sosResult = action.payload;
            })
            .addCase(getSosResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(getGpsResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getGpsResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.gpsResult = action.payload;
            })
            .addCase(getGpsResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(getVitalsResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getVitalsResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.vitalsResult = action.payload;
            })
            .addCase(getVitalsResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(saveNotesResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(saveNotesResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.notesResult = action.payload;
            })
            .addCase(saveNotesResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(closeSosResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(closeSosResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.closeResult = action.payload;
            })
            .addCase(closeSosResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(acknowledgeSosResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(acknowledgeSosResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.acknowledgeResult = action.payload;
            })
            .addCase(acknowledgeSosResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
            ;
    }
});

// Export actions
export const { setSosResult, setGpsResult, setVitalsResult, setNotesResult, setCloseResult, setSosModels, setAcknowledgeResult } = sosServiceSlice.actions;

// Export the reducer
export default sosServiceSlice.reducer;
