// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.has-validation {
    border: 2px solid red !important;
}

td > a {
    text-decoration: none;
    list-style: none;
    color: #000;
}
.react-datepicker-wrapper{
    width:100%;
}
`, "",{"version":3,"sources":["webpack://./src/ui/containers/portal/client-users/style.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,UAAU;AACd","sourcesContent":[".has-validation {\n    border: 2px solid red !important;\n}\n\ntd > a {\n    text-decoration: none;\n    list-style: none;\n    color: #000;\n}\n.react-datepicker-wrapper{\n    width:100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
