import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiError } from "../../common/models/api-error";
import SosServiceApi from "../../../infrastructure/sos-service";
import { getErrorPayload } from "../../common/helpers/error-helper";
import { SosResultDto } from "./models/sos-result-dto";
import { GpsResultDto } from "./models/gps-result-dto";
import { addSuccessNotification } from "../common/notificationSlice";
import { CloseRequestDto, GpsRequestDto, NotesRequestDto } from "./models/gps-request-dto";
import { VitalsResultDto } from "./models/vitals-result-dto";
import { SOSAckRequestDto } from "./models/sos-ack-request-dto";

export const getSosResultAsync = createAsyncThunk<SosResultDto[], string,
    { rejectValue: ApiError | undefined }
>(
    "sosService/getSosResult",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SosServiceApi.getSos(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);

export const getGpsResultAsync = createAsyncThunk<GpsResultDto[], GpsRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "sosService/getGpsResult",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SosServiceApi.getGps(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const getVitalsResultAsync = createAsyncThunk<VitalsResultDto[], GpsRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "sosService/getVitals",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SosServiceApi.getVitals(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const saveNotesResultAsync = createAsyncThunk<string, NotesRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "sosService/saveNotes",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SosServiceApi.saveNotes(request);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);
export const closeSosResultAsync = createAsyncThunk<string, CloseRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "sosService/closeSos",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SosServiceApi.closeSos(request.sos_sid ?? '', request.user_sid);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);

export const acknowledgeSosResultAsync = createAsyncThunk<string, SOSAckRequestDto,
    { rejectValue: ApiError | undefined }
>(
    "sosService/acknowledgeSos",
    async (request, { dispatch, rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await SosServiceApi.acknowledgeSos(request.sos_sid, request.user_sid);
            return fulfillWithValue(response.data);
        } catch (error) {
            const apiError = getErrorPayload(error);
            return rejectWithValue(apiError);
        }
    }
);