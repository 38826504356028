import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AppRoutes from './ui/containers/layout/AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppSelector } from './application/store/useStore';
import { RootState } from './application/store';
import MymonXLoader from './ui/components/mymonx-loader';
import { useState } from 'react';

function App() {
  const { isLoading } = useAppSelector((state: RootState) => state.loading)
  const [showModal, setShowModal] = useState(true);
  

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        newestOnTop={true}
        pauseOnHover
        theme='colored'
      />
      {isLoading && <MymonXLoader isLoading={isLoading} />}
      <AppRoutes />
    </>
  );
}

export default App;
