import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../common/enums/api-status";
import { BaseState } from "../../common/models/base-state";
import { getOrgFromJwt } from "../../common/helpers/jwt-helper";
import { CreateComplianceDocumentDto } from "./models/create-compliance-record-dto";
import { createComplianceDocumentResultAsync, createComplianceRecordResultAsync, getActiveComplianceResultAsync } from "./complianceServiceThunk";

// Define the interface for ConfigurationServiceState
export interface ComplianceServiceState extends BaseState {
    complianceResult: CreateComplianceDocumentDto[] | undefined;

    initialClientRequest: { org_id: string };
}

// Initial client request object with org_id
const initialClientRequest = {
    org_id: getOrgFromJwt() || ''
}

// Initial state for ConfigurationService
const initialState: ComplianceServiceState = {
    status: ApiStatus.IDLE,
    complianceResult: undefined, // Initialize as null or appropriate initial value

    initialClientRequest: initialClientRequest,
    apiError: undefined // Assuming BaseState includes apiError
}

// Create the slice
export const complianceServiceSlice = createSlice({
    name: 'complianceService',
    initialState,
    reducers: {
        // Add reducers here if needed
        setComplianceResult(state, action: PayloadAction<CreateComplianceDocumentDto[]>) {
            state.complianceResult = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks


            .addCase(createComplianceDocumentResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createComplianceDocumentResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(createComplianceDocumentResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(createComplianceRecordResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(createComplianceRecordResultAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(createComplianceRecordResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

            .addCase(getActiveComplianceResultAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getActiveComplianceResultAsync.fulfilled, (state, action) => {

                state.complianceResult = action.payload;
                state.status = ApiStatus.IDLE;
            })
            .addCase(getActiveComplianceResultAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            });
    }
});

// Export actions
export const { setComplianceResult } = complianceServiceSlice.actions;

// Export
// Export the reducer
export default complianceServiceSlice.reducer;
