import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../../../application/store/useStore";
import { RootState } from "../../../../../../application/store";
import { getMedicalDataAsync } from "../../../../../../application/features/staticData-service/saticDataServiceThunk";
import { deleteConditionAsync, deleteMedicationAsync, getConditionAsync, getMedicationAsync } from "../../../../../../application/features/medical-service/medicalServiceThunk";
import { useEffect, useState } from "react";
import { MedicationResultDto } from "../../../../../../application/features/medical-service/models/medicationresultdto";
import { Link } from "react-router-dom";
import { ConditionResultDto } from "../../../../../../application/features/medical-service/models/conditionresultdto";
import CreateOrUpdateConditionPage from "./client-condition-crud/create-or-update-condition";
import { setSelectedCondition, setSelectedMedication, setShowConditionForm, setShowMedicationForm } from "../../../../../../application/features/medical-service/medicalServiceSlice";
import moment from "moment";
import CreateOrUpdateMedicationForm from "./client-medication-crud/create-or-update-medication";
import MymonXDialog from "../../../../../components/mymonx-dialog";

interface ClientMedicationPageProps {
    user_sid?: string;
}


const ClientMedicationPage = ({ user_sid }: ClientMedicationPageProps) => {

    const dispatch = useAppDispatch();
    const { medicationResultDto, isShowMedicationForm, selectedMedication, isShowConditionForm, selectedCondition, conditionResultDto } = useAppSelector((state: RootState) => state.medicalService);
    const [selectedRow, setSelectedRow] = useState<MedicationResultDto | null>(null);
    const [selectedRowCondition, setSelectedRowCondition] = useState<ConditionResultDto | null>(null);


    useEffect(() => {
        if (user_sid !== undefined && user_sid !== '') {
            dispatch(getMedicationAsync(user_sid ?? ""))
            dispatch(getConditionAsync(user_sid ?? ""))
        }


    }, [dispatch, user_sid]);

    const handleMedicationDialogClose = () => {
        dispatch(setSelectedMedication(undefined));
        dispatch(setShowMedicationForm(false));
        if (user_sid !== undefined && user_sid !== '') {
            dispatch(getMedicationAsync(user_sid ?? ""));
        }
    };

    const handleConditionDialogClose = () => {
        dispatch(setSelectedCondition(undefined));
        dispatch(setShowConditionForm(false));
        if (user_sid !== undefined && user_sid !== '') {
            dispatch(getConditionAsync(user_sid ?? ""));
        }
    };
    return (


        <div>




            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="card shadow-sm">
                        <div className="card-header">
                            <div className="align-items-center d-flex justify-content-between">
                                <div>
                                    <h5>Condition</h5>
                                </div>

                                <div>
                                    <Button variant="primary" type="button" onClick={() => {
                                        dispatch(setSelectedCondition(undefined))
                                        dispatch(setShowConditionForm(true))
                                    }}>
                                        Add
                                    </Button>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">


                            <Table responsive bordered>
                                <thead key={`client-header`}>
                                    <tr key={`client-header-row`}>
                                        <th>
                                            Condition
                                        </th>
                                        <th>
                                            Diagnosis Date
                                        </th>

                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody key={`client-body`}>
                                    {conditionResultDto &&
                                        conditionResultDto?.map((row: ConditionResultDto, index: number) => (
                                            <tr key={`row-${index}`}>
                                                <td key={`name-${index}`}>
                                                    {row.condition}
                                                </td>
                                                <td key={`diagnosis-date-${index}`}>
                                                    {`${moment(row.diagnosis_date).format('DD-MM-YYYY')}`}
                                                </td>

                                                <td align="center">
                                                    <span className="pe-2">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                            dispatch(setSelectedCondition(row));
                                                            dispatch(setShowConditionForm(true));
                                                        }} >
                                                            <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                        </svg>
                                                    </span>
                                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                        dispatch(deleteConditionAsync(row.sid));
                                                    }} >
                                                        <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                    </svg>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>

                        </div>

                    </div>
                </div>

                <div className="col-md-6 mb-3">
                    <div className="card shadow-sm">
                        <div className="card-header">
                            <div className="align-items-center d-flex justify-content-between">
                                <div>
                                    <h5>Medication</h5>
                                </div>

                                <div>
                                    <Button variant="primary" type="button" onClick={() => {
                                        dispatch(setSelectedMedication(undefined))
                                        dispatch(setShowMedicationForm(true))
                                    }}>
                                        Add
                                    </Button>
                                </div>

                            </div>
                        </div>

                        <div className="card-body">


                            <Table responsive bordered>
                                <thead key={`client-header`}>
                                    <tr key={`client-header-row`}>
                                        <th>
                                            Medication
                                        </th>
                                        <th>
                                            Start Date
                                        </th>
                                        <th>
                                            End Date
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody key={`client-body`}>
                                    {medicationResultDto && medicationResultDto &&
                                        medicationResultDto?.map((row: MedicationResultDto, index: number) => (
                                            <tr key={`row-${index}`}>
                                                <td key={`name-${index}`}>
                                                    {row.medication}
                                                </td>
                                                <td key={`start-date-${index}`}>
                                                    {`${moment(row.start_date).format('DD-MM-YYYY')}`}
                                                </td>
                                                <td key={`end-date-${index}`}>
                                                    {`${moment(row.end_date).format('DD-MM-YYYY')} `}
                                                </td>
                                                <td align="center">
                                                    <span className="pe-2">
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                            dispatch(setSelectedMedication(row));
                                                            dispatch(setShowMedicationForm(true));
                                                        }} >
                                                            <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                        </svg>
                                                    </span>
                                                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                                        dispatch(deleteMedicationAsync(row.sid));
                                                    }} >
                                                        <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                    </svg>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>


                        </div>

                    </div>
                </div>




            </div>




            <MymonXDialog
                title="Medication Form"
                show={isShowMedicationForm}
                size="lg"
                onHide={handleMedicationDialogClose}
            >
                <CreateOrUpdateMedicationForm user_profile_sid={user_sid ?? ""} selectedMedication={selectedMedication} />
            </MymonXDialog>

            <MymonXDialog
                title="Condition Form"
                show={isShowConditionForm}
                size="lg"
                onHide={handleConditionDialogClose}
            >
                <CreateOrUpdateConditionPage user_profile_sid={user_sid ?? ""} selectedCondition={selectedCondition} />
            </MymonXDialog>
        </div>
    );
};

export default ClientMedicationPage;