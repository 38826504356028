import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../common/enums/api-status";
import { getOrgFromJwt } from "../../common/helpers/jwt-helper";
import { BaseState } from "../../common/models/base-state";
import { MedicationResultDto } from "./models/medicationresultdto";
import { deleteConditionAsync, deleteMedicationAsync, getConditionAsync, getMedicationAsync, postConditionAsync, postMedicationAsync, putConditionAsync, putMedicationAsync } from "./medicalServiceThunk";
import { ConditionResultDto } from "./models/conditionresultdto";



export interface MedicalServiceState extends BaseState {
    medicationResultDto: MedicationResultDto[];
    conditionResultDto: ConditionResultDto[];

    initialClientRequest: { org_id: string };
    isShowMedicationForm: boolean;
    selectedMedication?: MedicationResultDto;

    selectedCondition?: ConditionResultDto;
    isShowConditionForm: boolean;

}
const initialClientRequest = {
    org_id: getOrgFromJwt() || ''
}
const initialState: MedicalServiceState = {
    status: ApiStatus.IDLE,
    initialClientRequest: initialClientRequest,
    apiError: undefined, // Assuming BaseState includes apiError
    medicationResultDto: [], // Initialize as null or appropriate initial value
    conditionResultDto: [],
    isShowMedicationForm: false,
    isShowConditionForm: false
}
export const medicalServiceSlice = createSlice({
    name: 'medicalService',
    initialState,
    reducers: {
        setShowMedicationForm(state, action: PayloadAction<boolean>) {
            state.isShowMedicationForm = action.payload;
        },
        setSelectedMedication(state, action: PayloadAction<MedicationResultDto | undefined>) {
            state.selectedMedication = action.payload;
        },
        setShowConditionForm(state, action: PayloadAction<boolean>) {
            state.isShowConditionForm = action.payload;
        },
        setSelectedCondition(state, action: PayloadAction<ConditionResultDto | undefined>) {
            state.selectedCondition = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(getMedicationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(getMedicationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.medicationResultDto = action.payload;
            })
            .addCase(getMedicationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(postMedicationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(postMedicationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(postMedicationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(putMedicationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(putMedicationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(putMedicationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            // Handle pending, fulfilled, and rejected cases for your async thunks
            .addCase(deleteMedicationAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(deleteMedicationAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(deleteMedicationAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder.addCase(getConditionAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getConditionAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                state.conditionResultDto = action.payload;
            })
            .addCase(getConditionAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

        builder.addCase(postConditionAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(postConditionAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(postConditionAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

        builder.addCase(putConditionAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(putConditionAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(putConditionAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
        builder.addCase(deleteConditionAsync.pending, (state) => {
            state.status = ApiStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(deleteConditionAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(deleteConditionAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
    }
});

// Export actions
export const { setShowMedicationForm, setSelectedMedication, setShowConditionForm, setSelectedCondition } = medicalServiceSlice.actions;

// Export the reducer
export default medicalServiceSlice.reducer;