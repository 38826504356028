import { StepDataResponse } from "../application/features/activity-service/models/steps-graph-dto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";



const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);



const getActivityGraph = (user_profile_sid: string, vital_type: string, graphType: string, startDate: string, endDate: string,) => {

    return organisationPortalApi.get<StepDataResponse>(`/api/v0/activity_graph?user_profile_sid=${user_profile_sid}&period=${graphType}&from_date=${startDate}&to_date=${endDate}&activity=${vital_type}`);
}

const ActivityServiceApi = {
    getActivityGraph
}

export default ActivityServiceApi;