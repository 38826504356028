import { Button, Col, Form, Row } from "react-bootstrap";
import MymonXValidation from "../../../components/mymonx-validation";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { UserProfileDto } from "../../../../application/features/user-profile-service/models/user-profile-dto";
import { Controller, useForm } from "react-hook-form";
import { getProfileAsync, postProfileAsync, updateProfileAsync } from "../../../../application/features/user-profile-service/userProfileServiceThunk";
import { useEffect, useState } from "react";
import { RootState } from "../../../../application/store";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCountriesAsync } from "../../../../application/features/staticData-service/saticDataServiceThunk";
import MymonXReqAsteric from "../../../components/mymonx-req-asteric";

const UserProfile = () => {
    const dispatch = useAppDispatch();
    const { user_profile } = useAppSelector((state: RootState) => state.userProfileService);
    const { currentUser } = useAppSelector((state: RootState) => state.securityService);
    const { contry_data } = useAppSelector((state: RootState) => state.staticDataService);
    const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date(Date.now()));
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<UserProfileDto>({
        defaultValues: { ...user_profile }
    });

    useEffect(() => {
        dispatch(getCountriesAsync({ page: 1, per_page: 100 }))
    }, [dispatch])

    useEffect(() => {
        if (currentUser) {
            setValue('email', currentUser.email);
            dispatch(getProfileAsync(currentUser.id)).then((res: any) => {
                if (res.payload) {
                    const result = res.payload as UserProfileDto;
                    setValue('sid', result.sid);
                    setValue('first_name', result.first_name);
                    setValue('last_name', result.last_name);
                    setValue('email', currentUser.email);
                    setValue('mobile', result.mobile);
                    setValue('mobile_prefix', result.mobile_prefix);
                    setValue('date_of_birth', result.date_of_birth);
                    setDateOfBirth(result.date_of_birth);
                    setValue('gender', result.gender);
                    setValue('weight', result.weight);
                    setValue('weight_unit', result.weight_unit);
                    setValue('height', result.height);
                    setValue('height_unit', result.height_unit);
                    setValue('location', result.location);
                }
            })
        }
    }, [dispatch, currentUser])

    const onSubmit = (request: UserProfileDto) => {
        request.care_team = '';
        request.nationality = '';
        request.health_status = '';
        request.user_sid = currentUser?.id ?? '';
        request.org_id = currentUser?.org_id ?? '';
        if (user_profile && user_profile.sid && currentUser) {
            dispatch(updateProfileAsync(request)).then(res => {
                if (res && res.payload && res.payload) {
                    if (currentUser.email) {
                        dispatch(getProfileAsync(currentUser.email));
                    };
                }
            });
        } else {
            request.sid = uuidv4();
            dispatch(postProfileAsync(request)).then(res => {
                if (res && res.payload && res.payload) {
                    if (currentUser && currentUser.email) {
                        dispatch(getProfileAsync(currentUser.email));
                    };
                }
            });
        }
    }

    const handleDateOfBirthChange = (dateChange: Date) => {
        setValue("date_of_birth", dateChange, {
            shouldDirty: true
        });
        setDateOfBirth(dateChange);
    };

    return (
        <>
            <Form id="client-create-form" className="row" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={12} md={6}>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.email`}>
                            <Form.Label column sm={12} md={4}>
                                Email <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="email"
                                    {...register('email', {
                                        required: {
                                            value: true,
                                            message: 'email is required'
                                        }
                                    })}
                                    className={`${!!errors.email ? 'has-validation' : ''}`}
                                />
                                <MymonXValidation fieldError={errors.email} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                            <Form.Label column sm={12} md={4}>
                                First Name <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="first name"
                                    {...register('first_name', {
                                        required: {
                                            value: true,
                                            message: 'first name is required'
                                        }
                                    })}
                                    className={`${!!errors.first_name ? 'has-validation' : ''}`}
                                />
                                <MymonXValidation fieldError={errors.first_name} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.last_name`}>
                            <Form.Label column sm={12} md={4}>
                                Last Name <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="last name"
                                    {...register('last_name', {
                                        required: {
                                            value: true,
                                            message: 'last name is required'
                                        }
                                    })}
                                />
                                <MymonXValidation fieldError={errors.last_name} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.height`}>
                            <Form.Label column sm={12} md={4}>
                                Height <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Row>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            placeholder="height"
                                            {...register('height', {
                                                required: {
                                                    value: true,
                                                    message: 'height is required'
                                                }
                                            })}
                                        />
                                        <MymonXValidation fieldError={errors.height} ></MymonXValidation>
                                    </Col>
                                    <Col sm="4">
                                        <Form.Select aria-label="Default select example"
                                            {...register('height_unit', {
                                                required: {
                                                    value: true,
                                                    message: 'height unit is required'
                                                }
                                            })}
                                        >
                                            <option value="" selected>Select Height Unit</option>
                                            <option value="m/cm">m/cm</option>
                                            <option value="ft/in">ft/in</option>
                                        </Form.Select>
                                        <MymonXValidation fieldError={errors.height_unit} ></MymonXValidation>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.weight`}>
                            <Form.Label column sm={12} md={4}>
                                Weight <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Row>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            placeholder="weight"
                                            {...register('weight', {
                                                required: {
                                                    value: true,
                                                    message: 'weight is required'
                                                }
                                            })}
                                        />
                                        <MymonXValidation fieldError={errors.weight} ></MymonXValidation>
                                    </Col>
                                    <Col sm="4">
                                        <Form.Select aria-label="Default select example"
                                            {...register('weight_unit', {
                                                required: {
                                                    value: true,
                                                    message: 'weight unit is required'
                                                }
                                            })}
                                        >
                                            <option value="" selected>Select Weight Unit</option>
                                            <option value="kg">kg</option>
                                            <option value="lbs">lbs</option>
                                        </Form.Select>
                                        <MymonXValidation fieldError={errors.weight_unit} ></MymonXValidation>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.date_of_birth`}>
                            <Form.Label column sm={12} md={4}>
                                Date of Birth <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="date_of_birth"
                                    control={control}
                                    defaultValue={dateOfBirth}
                                    render={() => (
                                        <DatePicker
                                            className="form-control w-100"
                                            dateFormat={'dd/MM/yyyy'}
                                            maxDate={moment().toDate()}
                                            selected={dateOfBirth}
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleDateOfBirthChange}
                                        />
                                    )}
                                />
                                <MymonXValidation fieldError={errors.date_of_birth} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.gender`}>
                            <Form.Label column sm={12} md={4}>
                                Gender <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Form.Select aria-label="Default select example"
                                    {...register('gender', {
                                        required: {
                                            value: true,
                                            message: 'gender is required'
                                        }
                                    })}
                                >
                                    <option value="" selected>Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Form.Select>
                                <MymonXValidation fieldError={errors.gender} ></MymonXValidation>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.mobile`}>
                            <Form.Label column sm={12} md={4}>
                                Mobile Number
                            </Form.Label> <MymonXReqAsteric />
                            <Col sm={12} md={8}>
                                <Row>
                                    <Col sm="4">
                                        <Form.Control
                                            type="text"
                                            placeholder="+44"
                                            {...register('mobile_prefix', {
                                                required: {
                                                    value: true,
                                                    message: 'prefix mobile is required'
                                                }
                                            })}
                                        />
                                        <MymonXValidation fieldError={errors.mobile_prefix} ></MymonXValidation>
                                    </Col>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            placeholder="mobile number"
                                            {...register('mobile', {
                                                required: {
                                                    value: true,
                                                    message: 'mobile is required'
                                                }
                                            })}
                                        />
                                        <MymonXValidation fieldError={errors.mobile} ></MymonXValidation>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`client-create-form.location`}>
                            <Form.Label column sm={12} md={4}>
                                Location: <MymonXReqAsteric />
                            </Form.Label>
                            <Col sm={12} md={8}>
                                <Controller
                                    name="location"
                                    control={control}
                                    rules={{ required: "location is required" }}
                                    render={({ field }) => (
                                        <Form.Select {...field} aria-label="Default select location">
                                            <option>Select Location</option>
                                            {contry_data.map((item: any) => (
                                                <option key={item.sid} value={item.sid}>{item.iso_name}</option>
                                            ))}
                                        </Form.Select>
                                    )}
                                />
                                <MymonXValidation fieldError={errors.location} />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>

                {/* <Form.Group className="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-3" controlId="formDateOfBirth">
                    <Form.Label className="d-block">
                        Date of Birth
                    </Form.Label>
                    <Controller
                        name="date_of_birth"
                        control={control}
                        defaultValue={dateOfBirth}
                        render={() => (
                            <DatePicker
                                className="form-control w-100"
                                dateFormat={'dd/MM/yyyy'}
                                maxDate={moment().toDate()}
                                selected={dateOfBirth}
                                placeholderText="dd/mm/yyyy"
                                onChange={handleDateOfBirthChange}
                            />
                        )}
                    />
                    <MymonXValidation fieldError={errors.date_of_birth} ></MymonXValidation>
                </Form.Group> */}
                <Form.Group className="mb-3 col-sm-12 text-end" controlId="formSubmitButtons">
                    <Form.Label className="visibility-hidden d-block mt-4" >
                    </Form.Label>
                    <Button variant="primary" className="btn-lg" type="submit" >
                        Save
                    </Button>
                </Form.Group>
            </Form>
        </>
    )
}

export default UserProfile;