import { useEffect } from "react";
import './style.css';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Tab, Tabs } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../application/store/useStore";
import { RootState } from "../../../../application/store";
import { getProfileAsync } from "../../../../application/features/user-profile-service/userProfileServiceThunk";
import { getDeviceByUserIdAsync } from "../../../../application/features/device-service/deviceServiceThunk"
import ClientOverviewPage from "../../portal/client-users/client-detail/over-view-client";
import HealthMetricsPage from "../../portal/client-users/client-detail/health-metrics";
import ClientAlertsPage from "../../portal/client-users/client-detail/client-alerts";
import ClientMedicationPage from "../../portal/client-users/client-detail/client-medication";


const ConsumerDashboard = () => {
    const { user_profile, userAlerts } = useAppSelector((state: RootState) => state.userProfileService);
    const { device } = useAppSelector((state: RootState) => state.deviceService);

    const { currentUser } = useAppSelector((state: RootState) => state.securityService);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (currentUser && currentUser.id) {
            const userId = currentUser.id;
            dispatch(getProfileAsync(currentUser.id));
            const deviceByUserIdDto = { user_id: userId };
            dispatch(getDeviceByUserIdAsync(deviceByUserIdDto));
        }
    }, [dispatch, currentUser])

    return (
        <>
            <div className="user-profile">
                <h3>
                    {`${user_profile?.first_name} , ${user_profile?.last_name} 
                    ${user_profile?.gender.toLowerCase() === "male" ? '(Mr.)' : '(Ms.)'}`}</h3>
                <div className="tab-container mt-4">
                    <Tabs
                        defaultActiveKey="healthMetrics"
                        id="justify-tab-example"
                        className="tabs flex-wrap mb-3"
                        justify
                    >

                        <Tab eventKey="healthMetrics" title="Health Insights">
                            <HealthMetricsPage key={`health metrics`} id={currentUser?.id} />
                        </Tab>
                        <Tab eventKey="medical" title="Medical">
                            <ClientMedicationPage />
                        </Tab>
                        <Tab eventKey="alertsAndIncidents" title="Alerts and Health Incidents">
                            <ClientAlertsPage />
                        </Tab>
                        <Tab eventKey="overview" title="Profile">
                            {
                                user_profile && userAlerts && userAlerts.data &&
                                <ClientOverviewPage client={user_profile} userAlert={userAlerts.data[0]} device={device} />
                            }
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default ConsumerDashboard;