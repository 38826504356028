import Chart from 'react-apexcharts';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../../../../application/store/useStore';
import { RootState } from '../../../../../../../application/store';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { DataItem, SeriesData, VitalsGraphRequestDto } from '../../../../../../../application/features/health-service/models/graph-request-dto';
import { resetVitalsGraph, setVitalsGraphRequest } from '../../../../../../../application/features/health-service/healthServiceSlice';
import { GraphFilterDto } from '../../../../../../../application/features/health-service/models/graph-filter-dto';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getVitalsGraphAsync } from '../../../../../../../application/features/health-service/healthServiceThunk';

import { ApexOptions } from 'apexcharts';
import _ from 'lodash';

interface GlucoseGraphProps {
    id: string;
    userId: string;
}


const GlucoseGraph = ({ id, userId }: GlucoseGraphProps) => {
    const dispatch = useAppDispatch();


    const vitalsGraphRequest = useAppSelector((state: RootState) => state.healthService.vitalsGraphRequest['BLOOD_GLUCOSE']);
    const vitalsGraphDto = useAppSelector((state: RootState) => state.healthService.vitalsGraphDto['BLOOD_GLUCOSE']);
    const graphFilterDto = useAppSelector((state: RootState) => state.healthService.graphFilterDto);

    const [startDate, setStartDate] = useState<Date | null>(moment().startOf('day').toDate());//moment(moment().diff(6, 'days')).startOf('day').toDate());
    const [endDate, setEndDate] = useState<Date | null>(moment().endOf('day').toDate());
    const [selectedPeriod, setSelectedPeriod] = useState('DAY');

    const glucoseGraphDtoV1 = _.cloneDeep(vitalsGraphDto);

    const {
        control,
        handleSubmit,
        setValue,
    } = useForm<VitalsGraphRequestDto>({
        defaultValues: {
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            vital_type: 'BLOOD_GLUCOSE'

        }
    });

    useEffect(() => {
        dispatch(resetVitalsGraph('BLOOD_GLUCOSE'))
    }, [dispatch]);
    const handleFilterClick = (period: any) => {
        if (userId) {
            let start_date = moment().startOf('day').toDate();
            let end_date = moment().endOf('day').toDate();
            let graphType = 'DAY';
            console.log("perios" + period);
            switch (period) {
                case 'DAY':
                    start_date = moment().startOf('day').toDate();
                    graphType = 'DAY';
                    break;
                case 'WEEK':
                    start_date = moment().subtract(6, 'day').startOf('day').toDate();
                    graphType = 'WEEK';
                    break;
                case 'MONTH':
                    start_date = moment().subtract(29, 'day').startOf('day').toDate();
                    graphType = 'MONTH';
                    break;
                case 'YEAR':
                    start_date = moment().subtract(364, 'day').startOf('day').toDate();
                    graphType = 'YEAR';
                    break;
                case 'RANGE':
                    graphType = 'RANGE';

                    break;
                default:
                    break;
            }

            setStartDate(start_date);
            setEndDate(end_date);
            setSelectedPeriod(period);

            if (graphType !== 'RANGE') {
                const request = {
                    userId: userId,
                    vital_type: vitalsGraphRequest.vital_type,
                    graphType: graphType,
                    startDate: moment(start_date).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(end_date).format('YYYY-MM-DD HH:mm:ss'),
                };
                dispatch(setVitalsGraphRequest({ vital_type: request.vital_type, request }));

            }
        }

    };

    useEffect(() => {
        // Reset to initial state when component mounts
        setStartDate(moment().startOf('day').toDate());
        setEndDate(moment().endOf('day').toDate());
        setSelectedPeriod('DAY');

        const defaultRequest = {
            userId: userId,
            vital_type: 'BLOOD_GLUCOSE',
            graphType: 'DAY',
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch(setVitalsGraphRequest({ vital_type: defaultRequest.vital_type, request: defaultRequest }));

        return () => {
            dispatch(resetVitalsGraph('BLOOD_GLUCOSE'));
        };
    }, [dispatch, userId]);



    useEffect(() => {
        fetchGraphData();
    }, [dispatch, vitalsGraphRequest])



    const fetchGraphData = () => {
        if (vitalsGraphRequest && vitalsGraphRequest.userId !== undefined && vitalsGraphRequest.userId !== '') {
            const startDate = new Date(vitalsGraphRequest.startDate);
            const endDate = new Date(vitalsGraphRequest.endDate);
            dispatch(getVitalsGraphAsync(vitalsGraphRequest));
        }
    };

    useEffect(() => {
        const interval = setInterval(fetchGraphData, 300000); // 60000ms = 1 minute
        return () => clearInterval(interval); // Cleanup on unmount
    }, [vitalsGraphRequest]);
    const handleStartDateChange = (dateChange: Date) => {
        setValue("startDate", moment(dateChange).startOf('day').format('YYYY-MM-DD HH:mm:ss'), {
            shouldDirty: true
        });
        setStartDate(dateChange);
    };

    const handleEndDateChange = (dateChange: Date) => {
        setValue("endDate", moment(dateChange).endOf('day').format('YYYY-MM-DD HH:mm:ss'), {
            shouldDirty: true
        });
        setEndDate(dateChange);
    };

    const onSubmit = (requestObj: VitalsGraphRequestDto) => {

        const sDate = moment(requestObj.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const eDate = moment(requestObj.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        if (userId) {

            const request = {
                userId: userId,
                vital_type: requestObj.vital_type,
                graphType: selectedPeriod,
                startDate: sDate,
                endDate: eDate,
            };
            dispatch(setVitalsGraphRequest({ vital_type: requestObj.vital_type, request }));

        }

    }

    const xAxisFormatter = (value: string) => {
        const date = moment(value);

        if (selectedPeriod === 'DAY') {
            return date.format('HH:mm').toString();;
        } else if (selectedPeriod === 'WEEK' || selectedPeriod === 'MONTH' || selectedPeriod === 'YEAR') {
            return date.format('DD/MM').toString();;
        } else if (selectedPeriod === 'RANGE') {
            const startDate = moment(vitalsGraphRequest.startDate);
            const endDate = moment(vitalsGraphRequest.endDate);

            if (startDate.isSame(endDate, 'day')) {
                return date.format('HH:mm').toString();;
            } else if (endDate.diff(startDate, 'days') > 1 && endDate.diff(startDate, 'days') <= 365) {
                return date.format('DD/MM').toString();;
            } else {
                return date.format('YYYY').toString();;
            }
        } else {
            return date.format('YYYY').toString();;
        }
    };




    const options: ApexOptions = {
        chart: {
            id: "basic-line"
        },
        xaxis: {
            type: 'datetime', // Set x-axis to datetime type
            categories: glucoseGraphDtoV1?.vital_datetime, // Ensure categories are in datetime format
            labels: {
                formatter: xAxisFormatter, // Use the formatter function
                rotate: 0,
                style: {
                    fontSize: '12px',
                    cssClass: 'apexcharts-xaxis-label'
                }
            }
        },
        yaxis: {
            title: {
                text: 'Glucose'
            }
        },
        stroke: {
            curve: 'smooth',
            width: 3 // Set the width of the lines to 1 to make them thinner
        },
        // annotations: {
        //     yaxis: [
        //         {
        //             y: 10,
        //             y2: 200,
        //             fillColor: "#f2ab84"
        //         },
        //         {
        //             y: 6.3,
        //             y2: 9.99,
        //             fillColor: "#f6c7ad"
        //         },
        //         {
        //             y: 2.6,
        //             y2: 6.29,
        //             fillColor: "#f2ab84"
        //         },
        //         {
        //             y: 0,
        //             y2: 2.59,
        //             fillColor: "#ffff99"
        //         }
        //     ]
        // },
        colors: ["#21609a", "#e97232", "#219a35"]
    };

    const formatSeriesDataOneDay = (): SeriesData[] => {
        if (!glucoseGraphDtoV1?.vital_data?.VITAL) return [];

        const xAxisValues = glucoseGraphDtoV1.vital_datetime || [];
        const vitalValues = glucoseGraphDtoV1.vital_data.VITAL || [];

        if (vitalsGraphRequest.graphType === 'DAY') {
            const hourlyData = groupDataByHour(xAxisValues, vitalValues);

            return [
                {
                    name: 'Avg',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.avg === 'number' ? item.avg : undefined, // Ensure `y` is a number or undefined
                    })),
                },
                {
                    name: 'Max',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.max === 'number' ? item.max : undefined, // Ensure `y` is a number or undefined
                    })),
                },
                {
                    name: 'Min',
                    data: hourlyData.map(item => ({
                        x: item.hour,
                        y: typeof item.min === 'number' ? item.min : undefined, // Ensure `y` is a number or undefined
                    })),
                },
            ];
        }

        return []; // Return empty if `graphType` is not 'DAY'
    };
    const groupDataByHour = (dates: Date[], values: number[]) => {
        const hourlyData: any = {};

        dates.forEach((date, index) => {
            const hour = moment(date).startOf('hour').format('YYYY-MM-DD HH:mm');
            if (!hourlyData[hour]) {
                hourlyData[hour] = {
                    values: [],
                };
            }
            hourlyData[hour].values.push(values[index]);
        });

        const result = Object.keys(hourlyData).map(hour => {
            const data = hourlyData[hour].values;
            const avg = _.mean(data);
            const min = _.min(data);
            const max = _.max(data);

            return {
                hour,
                avg: Math.round(avg * 100) / 100,  // Round to 2 decimal places
                min,
                max,
            };
        });

        return result;
    };
    const formatSeriesData = () => {
        if (!glucoseGraphDtoV1?.vital_data?.VITAL) return [];

        // Example x-axis values. Adjust as needed to match the x-axis categories.
        const xAxisValues = glucoseGraphDtoV1.vital_datetime || [];
        // const vitalValues = glucoseGraphDtoV1.vital_data.VITAL || [];

        // if (vitalsGraphRequest.graphType === 'DAY') {
        //     const hourlyData = groupDataByHour(xAxisValues, vitalValues);

        //     return [
        //         {
        //             name: 'Avg',
        //             data: hourlyData.map(item => {
        //                 const nextHour = moment(item.hour, 'YYYY-MM-DD HH:mm').subtract(1, 'hour').format('YYYY-MM-DD HH:mm');
        //                 item.hour = nextHour;
        //                 // Return the adjusted data object
        //                 return {
        //                     x: item.hour,
        //                     y: typeof item.avg === 'number' ? item.avg : undefined, // Ensure `y` is a number or undefined
        //                 };
        //             }),
        //         },

        //     ];
        // }
        const series = [
            {
                name: 'Avg',
                data: glucoseGraphDtoV1.vital_data.VITAL
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: glucoseGraphDtoV1?.vital_data?.VITAL?.[index] // y-axis value for Heart Rate
                // }))
            }
        ];

        // Add the VITAL_MAX series if it exists

        if (Array.isArray(glucoseGraphDtoV1.vital_data.VITAL_MAX) && glucoseGraphDtoV1.vital_data.VITAL_MAX?.length > 0) {

            series.push({
                name: 'Max',
                data: glucoseGraphDtoV1.vital_data.VITAL_MAX
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: glucoseGraphDtoV1?.vital_data?.VITAL_MAX?.[index] // y-axis value for Max Heart Rate
                // }))
            });
        }

        // Add the VITAL_MIN series if it exists
        if (Array.isArray(glucoseGraphDtoV1.vital_data.VITAL_MIN) && glucoseGraphDtoV1.vital_data.VITAL_MIN?.length > 0) {
            series.push({
                name: 'Min',
                data: glucoseGraphDtoV1.vital_data.VITAL_MIN
                // data: xAxisValues.map((xValue, index) => ({
                //     x: xValue,
                //     y: glucoseGraphDtoV1?.vital_data?.VITAL_MIN?.[index] // y-axis value for Min Heart Rate
                // }))
            });
        }

        return series;
    };

    const seriesData = formatSeriesData();
    const seriesV2Data = formatSeriesDataOneDay();
    // Extract hours
    const hours = seriesV2Data[0]?.data.map(item => item.x.toString()) || [];
    const findData = (name: string): number[] =>
        (seriesV2Data.find((d: SeriesData) => d.name === name)?.data.map((item: DataItem) => Number(item.y ?? 0))) || [];


    const minValues = findData('Min');
    const maxValues = findData('Max');
    const avgValues = findData('Avg');
    const isGraphDataEmpty = (data: number[]): boolean => {
        return data.length === 0;
    };
    const getColor = (value: number): string => {
        let color = '#c00000';

        if (value < 3)
            color = '#007FA9';
        else if (value > 3 && value <= 4)
            color = '#58B4E0';
        else if (value > 4 && value <= 8)
            color = '#00b18c';
        else if (value > 8 && value <= 11)
            color = '#e08458';
        else if (value > 11 && value <= 99)
            color = '#A45129';
        return color;
    }
    return (
        <>
            <div><h2>Glucose</h2></div>
            <div className="d-flex">
                <ButtonGroup>
                    {
                        graphFilterDto && graphFilterDto.map((item: GraphFilterDto, index: number) => {
                            return (
                                <Button
                                    key={`btn-${item.key}-${index}`}
                                    style={{ margin: '1 rem' }}
                                    onClick={() => handleFilterClick(item.value)}
                                    variant={selectedPeriod === item.key ? 'primary' : 'secondary'}
                                >
                                    {item.key}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>

                {
                    selectedPeriod === 'RANGE' ? <>
                        <form className="ms-2 d-flex" id="filter-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue={moment(startDate).format('YYYY-MM-DD')}
                                render={() => (
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={'dd/MM/yyyy'}
                                        maxDate={moment().toDate()}
                                        selected={startDate}
                                        placeholderText="dd/mm/yyyy"
                                        onChange={handleStartDateChange}
                                    />
                                )}
                            />
                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue={moment(endDate).format('YYYY-MM-DD')}
                                render={() => (
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={'dd/MM/yyyy'}
                                        maxDate={moment().toDate()}
                                        selected={endDate}
                                        placeholderText="dd/mm/yyyy"
                                        onChange={handleEndDateChange}
                                    />
                                )}
                            />
                            <Button variant="primary" type="submit" >
                                Filter
                            </Button>
                        </form>
                    </> : <>

                    </>
                }
            </div>
            {
                !glucoseGraphDtoV1 || isGraphDataEmpty(glucoseGraphDtoV1?.vital_data.VITAL || []) ?
                    (<div className="col-12 text-center">
                        <div className="alert alert-warning" role="alert">
                            No data found
                        </div>
                    </div>) :
                    (
                        <>
                            {
                                glucoseGraphDtoV1 && <div>
                                    <div id="chart">
                                        <Chart options={options} series={seriesData} type="line" height={350} />
                                    </div>
                                    <div id="html-dist"></div>
                                </div>
                            }

                            {
                                glucoseGraphDtoV1 && glucoseGraphDtoV1?.vital_data.VITAL?.length &&
                                <div style={{ overflowX: 'auto' }}> <Table striped bordered hover key={`glucose-table-${1}`}>
                                    {vitalsGraphRequest?.graphType === 'DAY' ?
                                        (
                                            <>
                                                <thead key={`glucose-first-thead`}>
                                                    <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                                                        Hours

                                                    </th>
                                                    {hours.map((hour: string, index: number) => {
                                                        // Use moment to format the date-time string
                                                        const formattedHour = moment(hour).format('HH');
                                                        // Calculate the next hour and format it
                                                        const nextHour = moment(hour).add(-1, 'hour').format('HH');

                                                        return (
                                                            <th key={`day-month-year-${index}`} style={{ border: '1px solid black', padding: '8px', textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                                {nextHour} - {formattedHour}
                                                            </th>
                                                        );
                                                    })}


                                                </thead>
                                                <tbody>
                                                    <tr key={`row-glucose-avg`}>
                                                        <td key={`col-glucose-avg`} className='w-25'>
                                                            Average
                                                        </td>

                                                        {avgValues.map((value: number, index: number) => (
                                                            <td key={`col-${value}-glucose-avg-${index}`} style={{ background: getColor(value), color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}

                                                    </tr>

                                                    <tr key={`row-glucose-max`}>
                                                        <td key={`col-glucose-max`} className='w-25'>
                                                            Max
                                                        </td>
                                                        {maxValues.map((value: number, index: number) => (
                                                            <td key={`col-${value}-glucose-max-${index}`} style={{ background: getColor(value), color: '#000' }}>
                                                                {value}
                                                            </td>
                                                        ))}
                                                    </tr>


                                                    {

                                                        <tr key={`row-glucose-min`}>
                                                            <td key={`col-glucose-min`} className='w-25'>
                                                                Min
                                                            </td>
                                                            {minValues.map((value: number, index: number) => (
                                                                <td key={`col-${value}-glucose-min-${index}`} style={{ background: getColor(value), color: '#000' }}>
                                                                    {value}
                                                                </td>
                                                            ))}
                                                        </tr>

                                                    }
                                                </tbody>
                                            </>

                                        ) : (
                                            <>
                                                <thead key={`glucose-first-thead`}>
                                                    {
                                                        glucoseGraphDtoV1 && glucoseGraphDtoV1?.vital_data.VITAL && <tr>
                                                            <th>
                                                            </th>
                                                            {
                                                                glucoseGraphDtoV1?.vital_datetime.map((item: Date, index: number) => {
                                                                    return (
                                                                        <>
                                                                            <th key={`day-month-year-${item}`}>
                                                                                {/* {
                                                                        vitalsGraphRequest?.graphType === 'DAY' ? (
                                                                            <>
                                                                                {`${moment(item).local().format('hh:mm A')}`}
                                                                            </>
                                                                        ) : (vitalsGraphRequest?.graphType === 'WEEK' || vitalsGraphRequest?.graphType === 'MONTH') ? (
                                                                            <>
                                                                                {`${moment(item).local().format('DD')}/${moment(item).local().format('MM')}`}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {`${moment(item).local().format('MM')}/${moment(item).local().format('YYYY')}`}
                                                                            </>
                                                                        )
                                                                    } */}
                                                                                {
                                                                                    xAxisFormatter(item.toString())
                                                                                }
                                                                            </th>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    }
                                                </thead>
                                                <tbody>
                                                    <tr key={`row-body-glucose-avg`}>
                                                        <td key={`col-body-glucose-avg`} className='w-25'>
                                                            Average
                                                        </td>
                                                        {
                                                            glucoseGraphDtoV1 && glucoseGraphDtoV1.vital_data && glucoseGraphDtoV1.vital_data.VITAL.map((item: number, index: number) => {
                                                                return <td key={`col-${item}-body-glucose-avg-${index}`} style={{ background: getColor(item), color: '#000' }}>
                                                                    {item}
                                                                </td>
                                                            })
                                                        }
                                                    </tr>
                                                    {
                                                        glucoseGraphDtoV1 && glucoseGraphDtoV1.vital_data.VITAL_MAX && glucoseGraphDtoV1.vital_data.VITAL_MAX.length > 0 && (
                                                            <tr key={`row-hr-max`}>
                                                                <td key={`col-hr-max`} className='w-25'>
                                                                    Max
                                                                </td>
                                                                {glucoseGraphDtoV1.vital_data.VITAL_MAX.map((item: number, index: number) => {
                                                                    return (
                                                                        <td key={`col-${item}-hr-max-${index}`} style={{ background: getColor(item), color: '#000' }} >
                                                                            {item}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        glucoseGraphDtoV1 && glucoseGraphDtoV1.vital_data.VITAL_MIN && glucoseGraphDtoV1.vital_data.VITAL_MIN.length > 0 && (
                                                            <tr key={`row-hr-min`}>
                                                                <td key={`col-hr-min`} className='w-25'>
                                                                    Min
                                                                </td>
                                                                {glucoseGraphDtoV1.vital_data.VITAL_MIN.map((item: number, index: number) => {
                                                                    return (
                                                                        <td key={`col-${item}-hr-min-${index}`} style={{ background: getColor(item), color: '#000' }}>
                                                                            {item}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        )
                                                    }



                                                </tbody></>
                                        )
                                    }


                                </Table>
                                </div>
                            }
                        </>
                    )
            }
        </>
    )
}

export default GlucoseGraph;