const AUTH_TOKEN = 'AUTH_TOKEN';
const ORG_REGISTER = 'ORG_REGISTER';


const setAccessToken = (token: string) => {
    localStorage.setItem(AUTH_TOKEN, token);
}

const setOrg = (org_id: string) => {
    localStorage.setItem(ORG_REGISTER, org_id);
}


const getAccessToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
}

const getOrg = () => {
    return localStorage.getItem(ORG_REGISTER)
}


const removeAccessToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
}

const removeOrg = () => {
    localStorage.removeItem(ORG_REGISTER);
}


const LocalStorageService = {
    setAccessToken,
    setOrg,
    getAccessToken,
    getOrg,
    removeAccessToken,
    removeOrg
}

export default LocalStorageService;