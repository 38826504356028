import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
    return (
        <div className="login-main">
        <Container className="h-100">
            <Outlet></Outlet>
        </Container>
        </div>
    )
}

export default AuthLayout;