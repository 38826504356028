import React, { useState, useEffect } from "react";
import * as _ from "lodash";

import { Row, Col, InputGroup, FormControl, Table, Form, Stack, Badge, Button } from "react-bootstrap";
import MymonXReqAsteric from "../../../../components/mymonx-req-asteric";
import MymonXDialog from "../../../../components/mymonx-dialog";
import { DeviceResultDto } from "../../../../../application/features/device-service/models/DeviceResultDto";
import { RootState } from "../../../../../application/store";
import { useAppDispatch, useAppSelector } from "../../../../../application/store/useStore";
import { createCurrentPositionResultAsync, createFactoryRestoreResultAsync, createFindWatchResultAsync, createRestartResultAsync, createShutdownResultAsync, createTextDeviceResultAsync } from "../../../../../application/features/message-service/messageServiceThunk";
import AlarmConfiguration from "./alarm-configuration";
import { setConfigurationRequest } from "../../../../../application/features/configuration-service/configurationServiceSlice";
import { getContactsAsync } from "../../../../../application/features/contact-service/contactServiceThunk";
import { CreateContactDto } from "../../../../../application/features/contact-service/models/create-contact-dto";
import SosContacts from "./sos-contacts";
import EmergencyContacts from "./emergency-contacts";
import { GetliveinfoAsync } from "../../../../../application/features/device-service/deviceServiceThunk";
import Battery from "../../../../components/mymonx-battery-level";


interface DevicesConfigurationFormProps {
    onHide?(): void;
    onComplete?(): void;
    device: DeviceResultDto | null
    isClose: boolean | false
}


const DevicesConfiguration = ({ onHide, onComplete, device, isClose }: DevicesConfigurationFormProps) => {

    const dispatch = useAppDispatch();
    const { createTextDeviceResult, createFindWatchResult,
        createCurrentPositionResult, createFactoryRestoreResult
        , createRestartResult, createShutdownResult }

        = useAppSelector((state: RootState) => state.messageService);
    const { liveinfo } = useAppSelector((state: RootState) => state.deviceService);

    useEffect(() => {
        fetchLiveInfo();
    }, [dispatch, device?.sid])

    const fetchLiveInfo = async () => {
        await dispatch(GetliveinfoAsync(device?.imei ?? '')).unwrap();
    }


    //    const deviceDto = { imei: device?.imei }; // Create DeviceDto object
    const requestCurrentPosition = async () => {
        await dispatch(createCurrentPositionResultAsync(device?.imei ?? '')).unwrap();
    };
    const requestRestart = async () => {
        await dispatch(createRestartResultAsync(device?.imei ?? '')).unwrap();
    };
    const requestFindWatch = async () => {
        await dispatch(createFindWatchResultAsync(device?.imei ?? '')).unwrap();
    };
    const requestFactoryRestore = async () => {
        await dispatch(createFactoryRestoreResultAsync(device?.imei ?? '')).unwrap();
    };
    const requestShutdown = async () => {
        await dispatch(createShutdownResultAsync(device?.imei ?? '')).unwrap();
    };
    useEffect(() => {
     
        if (device && device.assigned_user_sid) {
            dispatch(getContactsAsync(device?.assigned_user_sid))
        }
    }, [dispatch, device, device?.assigned_user_sid])

    return (
        <>



            <div className="row">

                <div className="col-lg-12 col-md-12">



                    <div className="card mb-3">
                        <div className="card-body fw-medium devic-conf-info">
                            <div className="row">

                                <div className="col-sm-1">
                                    <table  >
                                        <tr>
                                            <td>User Name </td>
                                            <td>{device?.first_name} + {device?.last_name}</td>
                                        </tr>

                                    </table>

                                </div>
                                <div className="col-sm-4">
                                    <div className="d-flex">

                                        <div>
                                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_107_2)">
                                                    <path d="M58.3334 8.33333C60.5435 8.33333 62.6631 9.2113 64.2259 10.7741C65.7887 12.3369 66.6667 14.4565 66.6667 16.6667V22.5667C74.025 25.7833 79.1667 33.125 79.1667 41.6667V58.3333C79.1673 62.3879 77.985 66.3546 75.7647 69.7472C73.5444 73.1398 70.3826 75.811 66.6667 77.4333V83.3333C66.6667 85.5435 65.7887 87.6631 64.2259 89.2259C62.6631 90.7887 60.5435 91.6667 58.3334 91.6667H41.6667C39.4566 91.6667 37.337 90.7887 35.7742 89.2259C34.2113 87.6631 33.3334 85.5435 33.3334 83.3333V77.4333C29.6175 75.811 26.4556 73.1398 24.2353 69.7472C22.015 66.3546 20.8328 62.3879 20.8334 58.3333V41.6667C20.8328 37.6121 22.015 33.6454 24.2353 30.2528C26.4556 26.8601 29.6175 24.189 33.3334 22.5667V16.6667C33.3334 14.4565 34.2113 12.3369 35.7742 10.7741C37.337 9.2113 39.4566 8.33333 41.6667 8.33333H58.3334ZM58.3334 79.1667H41.6667V83.3333H58.3334V79.1667ZM58.3334 29.1667H41.6667C38.4783 29.1665 35.4104 30.3847 33.0906 32.572C30.7708 34.7593 29.3746 37.7504 29.1875 40.9333L29.1667 41.6667V58.3333C29.1665 61.5217 30.3847 64.5896 32.5721 66.9094C34.7594 69.2292 37.7505 70.6254 40.9334 70.8125L41.6667 70.8333H58.3334C61.5218 70.8335 64.5897 69.6153 66.9095 67.428C69.2292 65.2407 70.6255 62.2495 70.8125 59.0667L70.8334 58.3333V41.6667C70.8334 38.3515 69.5164 35.172 67.1722 32.8278C64.828 30.4836 61.6486 29.1667 58.3334 29.1667ZM50 33.3333C51.1051 33.3333 52.1649 33.7723 52.9463 34.5537C53.7277 35.3351 54.1667 36.3949 54.1667 37.5V48.275L58.9834 53.0917C59.7424 53.8775 60.1623 54.93 60.1528 56.0225C60.1434 57.115 59.7052 58.16 58.9326 58.9326C58.1601 59.7051 57.115 60.1433 56.0225 60.1528C54.9301 60.1623 53.8775 59.7423 53.0917 58.9833L47.05 52.9458C46.6568 52.5527 46.3464 52.0845 46.1374 51.5692C45.9285 51.0539 45.8251 50.5019 45.8334 49.9458V37.5C45.8334 36.3949 46.2724 35.3351 47.0538 34.5537C47.8352 33.7723 48.895 33.3333 50 33.3333ZM58.3334 16.6667H41.6667V20.8333H58.3334V16.6667Z" fill="black" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_107_2">
                                                        <rect width="100" height="100" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>


                                        </div>
                                        <table  >
                                            <tr>
                                                <td>IMEI </td>
                                                <td>{device?.imei}</td>
                                            </tr>
                                            <tr>
                                                <td>Battery </td>
                                                <td>{liveinfo?.battery_percent}%</td>
                                            </tr>
                                            <tr>
                                                <td>Signal </td>
                                                {liveinfo?.cellular_signal != null ? (
                                                   <td> <Battery level={liveinfo.cellular_signal} /></td>
                                                ) : (
                                                    <div>No signal data available</div>
                                                )}
                                                
                                            </tr>

                                        </table>
                                    </div>


                                </div>
                                <div className="col-lg-3">
                                    <table  >
                                        <tr>
                                            <td>Version </td>
                                            <td>{device?.vid}</td>
                                        </tr>
                                        <tr>
                                            <td>Mac </td>
                                            <td>{device?.mac}</td>
                                        </tr>

                                    </table>
                                </div>
                                <div className="col-lg-3 text-end">
                                    <button className='btn btn-danger me-2 mb-2' >Un-Assign</button>
                                    <button className='btn btn-danger me-2 mb-2'  >Disable</button>
                                </div>

                            </div>
                        </div>

                    </div>


                    <div className="card mb-3">
                        <div className="card-body fw-medium devic-conf-info">
                            <div className="row">

                                <div className="col-lg-4">
                                    <table  >
                                        <tr>
                                            <td>Reject Strange Calls</td>
                                            <td> <Form>
                                                <Form.Check
                                                    type={"checkbox"}

                                                />
                                            </Form></td>
                                        </tr>
                                        <tr>
                                            <td>Blood Oxygen Calibration</td>
                                            <td> <Form>
                                                <Form.Control
                                                    type="text"


                                                />
                                            </Form></td>
                                        </tr>

                                    </table>
                                    {/*<p className="mb-1"></p>*/}
                                </div>
                                <div className="col-lg-8">
                                    <button className='btn btn-success me-2 mb-2' onClick={requestCurrentPosition} >Request Current Position</button>
                                    <button className='btn btn-primary me-2 mb-2' onClick={requestRestart}>Restart</button>
                                    <button className='btn btn-secondary me-2 mb-2' onClick={requestFindWatch} >Find Watch</button>
                                    <button className='btn btn-danger me-2 mb-2' onClick={requestFactoryRestore}>Factory Restore</button>
                                    <button className='btn btn-danger me-2 mb-2' onClick={requestShutdown} >Shutdown</button>


                                </div>


                            </div>
                        </div>

                    </div>


                    <div className="row">

                        <div className="col-lg-12 col-xl-6 col-md-12">

                            <SosContacts user_sid={device?.assigned_user_sid} isClose={isClose} />

                        </div>



                        <div className="col-lg-12 col-xl-6 col-md-12">
                            <EmergencyContacts user_sid={device?.assigned_user_sid} isClose={isClose} />
                        </div>

                        <div className="col-lg-12 col-xl-12 col-md-12">
                            <AlarmConfiguration user_sid={device?.assigned_user_sid} />
                        </div>

                        {/* <div className="col-lg-12 col-xl-6 col-md-12">
                            <div className="card mb-3">
                                <div className="bg-warning-subtle card-body">
                                    <Row>
                                        <Col sm={12} md={12}>
                                            <h5 className="mb-3">Medication Reminder</h5>
                                        </Col>
                                        <Col md={12} lg={3}>
                                            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                                                <Form.Label column sm={12} md={12}>
                                                    Name <MymonXReqAsteric />
                                                </Form.Label>
                                                <Col sm={12} md={12}>
                                                    <Form.Control
                                                        type="text"
                                                        name="first_name"
                                                        placeholder="Name"
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} lg={2}>
                                            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                                                <Form.Label column sm={12} md={12}>
                                                    Time <MymonXReqAsteric />
                                                </Form.Label>
                                                <Col sm={12} md={12}>
                                                    <Form.Control
                                                        type="time"
                                                        name="first_name"
                                                        placeholder="Time"

                                                    />

                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} lg={5}>
                                            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                                                <Form.Label column sm={12} md={12}>
                                                    Days <MymonXReqAsteric />
                                                </Form.Label>
                                                <Col sm={12} md={12}>
                                                    <div className="w-100 justify-content-between d-flex">
                                                        <div>
                                                            <span>S</span>
                                                            <Form.Check
                                                                type={"checkbox"} />
                                                        </div>
                                                        <div>
                                                            <span>M</span>
                                                            <Form.Check
                                                                type={"checkbox"} />
                                                        </div>
                                                        <div>
                                                            <span>T</span>
                                                            <Form.Check
                                                                type={"checkbox"} />
                                                        </div>
                                                        <div>
                                                            <span>W</span>
                                                            <Form.Check
                                                                type={"checkbox"} />
                                                        </div>
                                                        <div>
                                                            <span>T</span>
                                                            <Form.Check
                                                                type={"checkbox"} />
                                                        </div>
                                                        <div>
                                                            <span>F</span>
                                                            <Form.Check
                                                                type={"checkbox"} />
                                                        </div>
                                                        <div>
                                                            <span>S</span>
                                                            <Form.Check
                                                                type={"checkbox"} />
                                                        </div>



                                                    </div>


                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} lg={2}>
                                            <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                                                <Form.Label column sm={12} md={12}>
                                                    Repeat <MymonXReqAsteric />
                                                </Form.Label>
                                                <Col sm={12} md={12}>
                                                    <Form.Check
                                                        type={"checkbox"} />

                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} lg={12} className="text-end">
                                            <Button variant="primary" type="button" >
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col>
                                            <Table responsive bordered>
                                                <thead >
                                                    <tr>

                                                        <th>
                                                            Name
                                                        </th>
                                                        <th>Time</th>
                                                        <th>
                                                            Days
                                                        </th>
                                                        <th>
                                                            Repeat
                                                        </th>
                                                        <th align="center" className="text-center">Actions</th>

                                                    </tr>
                                                </thead>
                                                <tbody>



                                                    <tr>
                                                        <td>Hasnat Adil</td>
                                                        <td>1234</td>
                                                        <td>034-1234567</td>
                                                        <td>Yes</td>
                                                        <td align="center">

                                                            <span className="pe-2">
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                                </svg>
                                                            </span>
                                                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                            </svg>


                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hasnat Adil</td>
                                                        <td>1234</td>
                                                        <td>034-1234567</td>
                                                        <td>Yes</td>
                                                        <td align="center">

                                                            <span className="pe-2">
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                                </svg>
                                                            </span>
                                                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                            </svg>


                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </Col>
                                    </Row>

                                </div>

                            </div>

                        </div> */}






                    </div>





                </div>



            </div>


        </>
    );
}

export default DevicesConfiguration;