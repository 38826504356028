import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { getSosResultAsync } from "../sos-service/sosServiceThunk";


export const loadingSlice = createSlice({
    name: 'app/loading',
    initialState: {
        isLoading: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSosResultAsync.pending, (state) => {
                state.isLoading = false;
            })
            .addCase(getSosResultAsync.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getSosResultAsync.rejected, (state) => {
                state.isLoading = false;
            });
        builder.addMatcher(isPending, (state) => {
            state.isLoading = true;
        });
        builder.addMatcher(isFulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addMatcher(isRejected, (state) => {
            state.isLoading = false;
        });


    }
})


export default loadingSlice.reducer;