import { useEffect } from "react";
import { RootState } from "../../../../../../application/store";
import { useAppDispatch, useAppSelector } from "../../../../../../application/store/useStore";
import { VitalsSummaryDto } from "../../../../../../application/features/health-service/models/bp-graph-dto";
import moment from "moment";
import { getVitalsSummaryAsync } from "../../../../../../application/features/health-service/healthServiceThunk";

interface ClientSummaryPageProps {
    user_sid?: string;
}

const ClientSummaryPage = ({ user_sid }: ClientSummaryPageProps) => {
    const dispatch = useAppDispatch();
    const { vitalsSummaryDto } = useAppSelector((state: RootState) => state.healthService);

    useEffect(() => {
        if (user_sid !== undefined && user_sid !== '') {
            dispatch(getVitalsSummaryAsync(user_sid ?? ""))
        }
    }, [dispatch, user_sid]);

    const groupByType = (data: VitalsSummaryDto[]) => {
        const grouped = data.reduce((acc, item) => {
            if (!acc[item.type]) {
                acc[item.type] = [];
            }
            acc[item.type].push(item);
            return acc;
        }, {} as { [key: string]: VitalsSummaryDto[] });

        Object.keys(grouped).forEach(type => {
            grouped[type].sort((a, b) => moment(b.vital_datetime).diff(moment(a.vital_datetime)));
        });

        return grouped;
    };
    const groupedData = groupByType(vitalsSummaryDto || []);
    // Sort keys to ensure "SLEEP" is last
    const sortedTypes = groupedData ? Object.keys(groupedData).sort((a, b) => {
        if (a === "SLEEP") return 1;  // Move "SLEEP" to the end
        if (b === "SLEEP") return -1; // Move "SLEEP" to the end
        return 0;
    }) : [];

    const formatDuration = (totalMinutes: number) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours} hour(s) ${minutes} minute(s)`;
    };
    return (
        <>
            <div className="row">
                {(!groupedData || sortedTypes.length === 0) ? (
                    <div className="col-12 text-center">
                        <div className="alert alert-warning" role="alert">
                            No data found
                        </div>
                    </div>
                ) : (
                    sortedTypes.map((type, index) => (
                        <div key={index} className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 mb-3">
                            <div className="card shadow-sm">
                                <div className="card-header">
                                    <div className="align-items-center d-flex justify-content-between">
                                        <div>
                                            <h5>{type}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {groupedData[type].map((row) => (
                                            row.vital_period === "LATEST" ? (
                                                <div key={row.vital_period} className="col-md-6 text-center" >
                                                    <p className="small mb-0">Latest Reading</p>
                                                    <small>{moment(row.vital_datetime || row.sleep_datetime).format('DD/MM/YYYY HH:mm:ss')}</small>

                                                    <div className="border p-3 rounded-3 text-center" style={{ backgroundColor: row.vital_colour }}>
                                                        {type === "BLOOD_PRESSURE" ? (
                                                            <>
                                                                <h5>{row.vital_value_sys != null && row.vital_value_dia != null ? `${row.vital_value_sys}/${row.vital_value_dia}` : "N/A"}</h5>
                                                            </>
                                                        ) : type === "SLEEP" ? (
                                                            <>
                                                                <>
                                                                    <p className="mb-0">
                                                                        <small><strong>Awake Average:</strong></small>   {/* Bold heading in small font */}
                                                                        <small>{row.vital_average != null ? ` ${formatDuration(row.awake_average)}` : "N/A"}</small>  {/* Value in small font */}
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><strong>Sleep Duration:</strong></small>
                                                                        <small>{row.sleep_duration != null ? ` ${formatDuration(row.sleep_duration)}` : "N/A"}</small>
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><strong>Deep Sleep:</strong></small>
                                                                        <small>{row.deep_sleep_average != null ? ` ${formatDuration(row.deep_sleep_average)}` : "N/A"}</small>
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><strong>Light Sleep:</strong></small>
                                                                        <small>{row.light_sleep_average != null ? ` ${formatDuration(row.light_sleep_average)}` : "N/A"}</small>
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><strong>Sleep Score:</strong></small>
                                                                        <small>{row.sleep_score_average != null ? ` ${row.sleep_score_average}` : "N/A"}</small>
                                                                    </p>
                                                                </>

                                                            </>
                                                        ) : type === "ECG" ? (
                                                            <>
                                                                <h5>{row.vital_value != null ? row.vital_value : "N/A"}</h5>
                                                            </>
                                                        )
                                                            : (
                                                                <h5>{row.vital_value != null ? row.vital_value.toFixed(2) : "N/A"}</h5>
                                                            )}

                                                        {row.vital_value != null && (
                                                            <p className="mb-0"><strong>{row.vital_units}</strong></p>
                                                        )}

                                                    </div>
                                                </div>
                                            ) : (
                                                <div key={row.vital_period} className="col-md-6 text-center" >
                                                    <p className="small mb-0">{row.vital_period === "DAY" ? "Daily Average" : row.vital_period === "WEEK" ? "Week Average" : "Month Average"}</p>
                                                    <small>{moment(row.vital_datetime || row.sleep_datetime).format('DD/MM/YYYY HH:mm:ss')}</small>

                                                    <div className="border p-3 rounded-3 text-center" style={{ backgroundColor: row.vital_colour }}>
                                                        {type === "BLOOD_PRESSURE" ? (
                                                            <>
                                                                <h5>{row.vital_average_sys != null && row.vital_average_dia != null ? `${row.vital_average_sys}/${row.vital_average_dia}` : "N/A"}</h5>
                                                            </>
                                                        ) : type === "SLEEP" ? (
                                                            <>

                                                                <>
                                                                    <p className="mb-0">
                                                                        <small><strong>Awake Average:</strong></small>   {/* Bold heading in small font */}
                                                                        <small>{row.vital_average != null ? ` ${formatDuration(row.awake_average)}` : "N/A"}</small>  {/* Value in small font */}
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><strong>Sleep Duration:</strong></small>
                                                                        <small>{row.sleep_duration != null ? ` ${formatDuration(row.sleep_duration)}` : "N/A"}</small>
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><strong>Deep Sleep:</strong></small>
                                                                        <small>{row.deep_sleep_average != null ? ` ${formatDuration(row.deep_sleep_average)}` : "N/A"}</small>
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><strong>Light Sleep:</strong></small>
                                                                        <small>{row.light_sleep_average != null ? ` ${formatDuration(row.light_sleep_average)}` : "N/A"}</small>
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><strong>Sleep Score:</strong></small>
                                                                        <small>{row.sleep_score_average != null ? ` ${row.sleep_score_average}` : "N/A"}</small>
                                                                    </p>
                                                                </>


                                                            </>
                                                        ) : type === "ECG" ? (
                                                            <>
                                                                <h5>{row.vital_value != null ? row.vital_value : "N/A"}</h5>
                                                            </>
                                                        )
                                                            : (
                                                                <>
                                                                    <h5>{row.vital_average != null ? row.vital_average.toFixed(2) : "N/A"}</h5>

                                                                </>
                                                            )}
                                                        {row.vital_average != null && (
                                                            <p className="mb-0"><strong>{row.vital_units}</strong></p>
                                                        )}
                                                        <p className="mb-0">{row.vital_label}</p>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default ClientSummaryPage;
