import { CreateMessageDto } from "../application/features/message-service/models/create-message-dto";
import { config } from "./config";
import { createOrganisationPortalApi } from "./organisation-portal-api";

const organisationPortalApi = createOrganisationPortalApi(config.BaseUrl);


const createTextDevice = (imei: CreateMessageDto) => {
    return organisationPortalApi.post<string>("/api/v0/message/text_device", imei);
}
const createCurrentPosition = (imei: string) => {
    return organisationPortalApi.post<string>(`/api/v0/message/current_position?imei=${imei}`);
}
const createRestart = (imei: string) => {
    return organisationPortalApi.post<string>(`/api/v0/message/restart?imei=${imei}`);
}
const createFindWatch = (imei: string) => {
    return organisationPortalApi.post<string>(`/api/v0/message/find_watch?imei=${imei}`);
}

const createFactoryRestore = (imei: string) => {
    return organisationPortalApi.post<string>(`/api/v0/message/factory_restore?imei=${imei}`);
}
const createShutdown = (imei: string) => {
    return organisationPortalApi.post<string>(`/api/v0/message/shutdown?imei=${imei}`);
}

const MessageServiceApi = {
    createTextDevice,
    createCurrentPosition,
    createRestart,
    createFindWatch,
    createFactoryRestore,
    createShutdown

}

export default MessageServiceApi;