import { useEffect } from "react";
import { useAppDispatch } from "../../../../../application/store/useStore";

import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";

import MymonXValidation from "../../../../components/mymonx-validation";

import { UserProfileGridDto } from "../../../../../application/features/user-profile-service/models/user-profile-grid-dto";
import { InviteClientDto } from "../../../../../application/features/security-service/models/invite-client-dto";
import { enableLoginAsync } from "../../../../../application/features/user-profile-service/userProfileServiceThunk";
import MymonXReqAsteric from "../../../../components/mymonx-req-asteric";

interface InviteClientFormProps {
    onHideUpdateForm?(): void;
    onCompleteUpdateForm?(): void;
    user_profile: UserProfileGridDto | undefined;
}

const InviteClientForm = ({ onHideUpdateForm, onCompleteUpdateForm, user_profile }: InviteClientFormProps) => {
    const dispatch = useAppDispatch();

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<InviteClientDto>({
        // defaultValues: { ...user_profile }
    });

    useEffect(() => {
        if (user_profile) {

            setValue('username', user_profile.email);
        }
    }, [dispatch, user_profile])

    const onSubmit = (request: InviteClientDto) => {

        request.sid = user_profile?.sid ?? "";
        // request.username = user_profile?.email ?? "";
        dispatch(enableLoginAsync(request)).then(res => {

            if (res && res.payload) {
                if (onCompleteUpdateForm) {
                    onCompleteUpdateForm();
                }

            }
        });
    }
    return (
        <>
            <Form id="client-create-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row>

                    <Col>
                        <Row>

                            <Col sm={12} md={12}>
                                <Form.Group as={Row} className="mb-3" controlId={`client-create-form.username`}>
                                    <Form.Label column sm={12} md={4}>
                                        Email<MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={8}>
                                        <Form.Control
                                            type="text"
                                            placeholder="email"
                                            {...register('username', {
                                                required: {
                                                    value: true,
                                                    message: 'email is required'
                                                }
                                            })}
                                            className={`${!!errors.username ? 'has-validation' : ''}`}
                                            disabled
                                        />
                                        <MymonXValidation fieldError={errors.username} ></MymonXValidation>
                                    </Col>
                                </Form.Group>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Form.Group as={Row} className="mb-3" controlId="formSubmitButtons">
                    <Col sm="12" className="text-end">
                        <Button variant="secondary" type="button" onClick={onHideUpdateForm} >
                            Cancel
                        </Button>{' '}
                        <Button variant="primary" type="submit" >
                            Invite
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        </>
    )
}
export default InviteClientForm;