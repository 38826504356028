import { useEffect, useState } from "react";
import { addContactsAsync, deleteContactsAsync, getContactsAsync, updateContactsAsync } from "../../../../../../application/features/contact-service/contactServiceThunk";
import { CreateContactDto } from "../../../../../../application/features/contact-service/models/create-contact-dto";
import { RootState } from "../../../../../../application/store";
import { useAppDispatch, useAppSelector } from "../../../../../../application/store/useStore";
import { setContactList, setSelectedContact, setSelectedSosContact } from "../../../../../../application/features/contact-service/contactServiceSlice";
import { v4 as uuidv4 } from 'uuid';
import MymonXReqAsteric from "../../../../../components/mymonx-req-asteric";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { capitalize } from "lodash";
import MymonXValidation from "../../../../../components/mymonx-validation";
import { useForm } from "react-hook-form";
import MymonXDialog from "../../../../../components/mymonx-dialog";
import { IoIosWarning } from "react-icons/io";

interface EmergencyContactsProps {
    user_sid: string | undefined;
    isClose: boolean | false;
}
const EmergencyContacts = ({ user_sid, isClose }: EmergencyContactsProps) => {
    const { contactList, selectedContact } = useAppSelector((state: RootState) => state.contactService);
    const [showError, setShowError] = useState<boolean>(false);
    const dispatch = useAppDispatch();


    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
    } = useForm<CreateContactDto>({
        defaultValues: {
            sid: "",
            user_sid: "",
            first_name: "",
            last_name: "",
            is_sos: false,
            mobile_prefix: "",
            mobile_number: ""
        }
    });
    useEffect(() => {

        if (isClose) {
            dispatch(setSelectedSosContact(undefined));
        }
    }, [isClose]);

    useEffect(() => {

        if (user_sid) {
            dispatch(getContactsAsync(user_sid))
        }
    }, [dispatch, user_sid]);

    useEffect(() => {
        if (selectedContact) {
            setValue('sid', selectedContact.sid);
            setValue('user_sid', selectedContact.user_sid);
            setValue('first_name', selectedContact.first_name);
            setValue('last_name', selectedContact.last_name);
            setValue('is_sos', selectedContact.is_sos);
            setValue('mobile_number', selectedContact.mobile_number);
            setValue('mobile_prefix', selectedContact.mobile_prefix);
        }
    }, [selectedContact]);

    const OnSubmit = (request: CreateContactDto) => {

        request.user_sid = user_sid ?? '';
        let newContactList = [...contactList];
        if (newContactList.length > 6) {
            setShowError(true);
            reset({
                sid: "",
                user_sid: "",
                first_name: "",
                last_name: "",
                is_sos: true,
                mobile_prefix: "",
                mobile_number: ""
            });
            return;
        }
        const index = newContactList.findIndex(contact => contact.sid === request.sid);
        if (index > -1) {
            newContactList[index] = request;
            dispatch(updateContactsAsync(newContactList)).then(res => {
                if (res.payload) {
                    dispatch(setSelectedContact(undefined));
                    reset({
                        sid: "",
                        user_sid: "",
                        first_name: "",
                        last_name: "",
                        is_sos: false,
                        mobile_prefix: "",
                        mobile_number: ""
                    });
                    dispatch(getContactsAsync(user_sid ?? ''))
                }
            });
        } else {
            let newContactList = [request];
            dispatch(addContactsAsync(newContactList)).then(res => {
                if (res.payload) {
                    dispatch(setSelectedContact(undefined));
                    reset({
                        sid: "",
                        user_sid: "",
                        first_name: "",
                        last_name: "",
                        is_sos: true,
                        mobile_prefix: "",
                        mobile_number: ""
                    });
                    dispatch(getContactsAsync(user_sid ?? ''))
                }
            })
        }

    };

    const handleDelete = (sid: string) => {
        const updatedContactList = contactList.filter(contact => contact.sid !== sid);
        dispatch(setContactList(updatedContactList));
        dispatch(deleteContactsAsync(sid));
    };

    const handleUpdate = (editContact: CreateContactDto) => {

        dispatch(setSelectedContact(editContact));
    };

    return (
        <>
            <div className="card mb-3">
                <div className="bg-info bg-opacity-10 card-body">
                    <Form noValidate onSubmit={handleSubmit(OnSubmit)}>
                        <Row>
                            <Col sm={12} md={12}>
                                <h5 className="mb-3">Phone Contact</h5>
                            </Col>
                            <Col md={12} lg={3}>
                                <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                                    <Form.Label column sm={12} md={12}>
                                        First Name <MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={12}>
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            {...register('first_name', {
                                                required: {
                                                    value: true,
                                                    message: 'First name is required'
                                                }
                                            })}
                                            className={`${!!errors.first_name ? 'has-validation' : ''}`}
                                        />
                                        <MymonXValidation fieldError={errors.first_name}></MymonXValidation>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={3}>
                                <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                                    <Form.Label column sm={12} md={12}>
                                        Last Name <MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={12}>
                                        <Form.Control
                                            type="text"

                                            placeholder="Last Name"
                                            {...register('last_name', {
                                                required: {
                                                    value: true,
                                                    message: 'Last name is required'
                                                }
                                            })}
                                            className={`${!!errors.last_name ? 'has-validation' : ''}`}
                                        />
                                        <MymonXValidation fieldError={errors.last_name}></MymonXValidation>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={2}>
                                <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                                    <Form.Label column sm={12} md={12}>
                                        Prefix <MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={12}>
                                        <Form.Control
                                            type="text"
                                            placeholder="+44"
                                            {...register('mobile_prefix', {
                                                required: {
                                                    value: true,
                                                    message: 'Mobile prefix is required'
                                                }
                                            })}
                                            className={`${!!errors.mobile_prefix ? 'has-validation' : ''}`}
                                        />
                                        <MymonXValidation fieldError={errors.mobile_prefix}></MymonXValidation>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={4}>
                                <Form.Group as={Row} className="mb-3" controlId={`client-create-form.first_name`}>
                                    <Form.Label column sm={12} md={12}>
                                        Number <MymonXReqAsteric />
                                    </Form.Label>
                                    <Col sm={12} md={12}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Mobile Number"
                                            {...register('mobile_number', {
                                                required: {
                                                    value: true,
                                                    message: 'Mobile number is required'
                                                }
                                            })}
                                            className={`${!!errors.mobile_number ? 'has-validation' : ''}`}
                                        />
                                        <MymonXValidation fieldError={errors.mobile_number}></MymonXValidation>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={12} className="text-end">
                                {
                                    selectedContact === undefined && <Button variant="primary" type="submit">
                                        Add
                                    </Button>
                                }

                                {
                                    selectedContact !== undefined && <Button variant="primary" type="submit">
                                        Update
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                    <Row className="mt-3">
                        <Col>
                            <Table responsive bordered>
                                <thead >
                                    <tr>

                                        <th>
                                            Name
                                        </th>
                                        <th>Prefix</th>
                                        <th>
                                            Phone Number
                                        </th>
                                        <th>
                                            Type
                                        </th>
                                        <th align="center" className="text-center">Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        contactList && contactList.filter((contact: CreateContactDto) => !contact.is_sos)
                                            .map((contact: CreateContactDto, index: number) => {
                                                return (
                                                    <tr>
                                                        <td>{`${contact.first_name} ${contact.last_name}`}</td>
                                                        <td>{contact.mobile_prefix}</td>
                                                        <td>{contact.mobile_number}</td>
                                                        <td>{capitalize(contact.is_sos.toString())}</td>
                                                        <td align="center">
                                                            <span className="pe-2">
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUpdate(contact)} >
                                                                    <path d="M1 15H2.098L12.796 4.30202L11.698 3.20402L1 13.902V15ZM0 16V13.48L13.18 0.288015C13.2833 0.196682 13.3967 0.126015 13.52 0.0760154C13.6433 0.0260154 13.7723 0.000681818 13.907 1.51514e-05C14.0417 -0.000651515 14.1717 0.020682 14.297 0.0640153C14.4237 0.106015 14.5403 0.182015 14.647 0.292015L15.714 1.36602C15.824 1.47202 15.8993 1.58868 15.94 1.71602C15.98 1.84268 16 1.96935 16 2.09602C16 2.23202 15.9773 2.36202 15.932 2.48602C15.886 2.60935 15.8133 2.72235 15.714 2.82502L2.519 16H0ZM12.238 3.76202L11.698 3.20402L12.796 4.30202L12.238 3.76202Z" fill="#52B2E4" />
                                                                </svg>
                                                            </span>
                                                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(contact.sid)} >
                                                                <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#DC3545" />
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
                <MymonXDialog
                    title="Warning"
                    show={showError}
                    size="lg"
                    onHide={() => {
                        setShowError(false);

                    }}
                    contentClassName="sos-alert-modal">
                    <div>
                        <div className="d-flex gap-5">
                            <div><IoIosWarning size={30} /></div>
                            <div> <p><b>You can only add upto 6 SOS contacts</b></p>

                            </div>
                        </div>

                    </div>

                </MymonXDialog>
            </div>
        </>
    )

}
export default EmergencyContacts;
