import { Spinner } from "react-bootstrap";
import "./style.css";

export interface MymonXLoaderProps {
    isLoading: boolean;
}

const MymonXLoader = ({isLoading}: MymonXLoaderProps) => {
    return isLoading ?  (
        <>
            <div id="backdrop" >
                <div className="text-center-loader loading">
                    <div className="spinner-border" role="status" color="primary">
                    </div>
                </div>
            </div>
        </>
    ) : (
        <></>
    )
}

export default MymonXLoader;