import { Button, Col, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../application/store/useStore";
import MymonXValidation from "../../../../components/mymonx-validation";
import { useEffect } from "react";
import { getRoleDropdownAsync } from "../../../../../application/features/role-service/roleServiceThunk";
import { getRoleFromJwt } from "../../../../../application/common/helpers/jwt-helper";
import { RootState } from "../../../../../application/store";
import { RoleDto } from "../../../../../application/features/role-service/models/role-grid-dto";
import { CreateUserDto } from "../../../../../application/features/user-profile-service/models/create-user-dto";
import { UpdateUserCredentialsDto } from "../../../../../application/features/security-service/models/register-credentials-dto";
import MymonXReqAsteric from "../../../../components/mymonx-req-asteric";
import { updateUserAsync } from "../../../../../application/features/users-service/usersServiceThunk";

interface UpdateUserFormProps {
    onHide?(): void;
    onComplete?(): void;

}

const UpdateUserForm = ({ onHide, onComplete }: UpdateUserFormProps) => {
    const dispatch = useAppDispatch();
    const role_id = getRoleFromJwt();
    const { roleDropdown } = useAppSelector((state: RootState) => state.roleService);
    const { currentUser } = useAppSelector((state: RootState) => state.securityService);
    const { user_result } = useAppSelector((state: RootState) => state.usersService);

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<CreateUserDto>({
        defaultValues: { ...user_result }
    });
    useEffect(() => {
        if (role_id) {
            dispatch(getRoleDropdownAsync(role_id));
        }
    }, [dispatch, role_id]);
    useEffect(() => {
        if (user_result) {
            setValue('email', user_result.username);
            setValue('sid', user_result.sid);
            setValue('first_name', user_result.first_name);
            setValue('last_name', user_result.last_name);
            setValue('org_id', user_result.org_id);
            setValue('role', user_result.roles);
        }
    }, [dispatch, user_result])


    const onSubmit = (request: CreateUserDto) => {
        const registerObj = {
            sid: request.sid,
            username: request.email,
            first_name: request.first_name,
            last_name: request.last_name,
            password: '',
            organisation_id: currentUser?.org_id,
            role_id: request.role
        } as UpdateUserCredentialsDto;

        dispatch(updateUserAsync(registerObj)).then(res => {
            if (res && res.payload) {
                if ((res.payload)) {
                    if (onComplete) {
                        onComplete();
                    }
                }
            }
        });
    }
    return (
        <>
            <Form id="client-update-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label column sm="3">
                        First Name <MymonXReqAsteric />
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            placeholder="first name"
                            {...register('first_name', {
                                required: {
                                    value: true,
                                    message: 'first name is required'
                                }
                            })}
                            className={`${!!errors.first_name ? 'has-validation' : ''}`}
                        />
                        <MymonXValidation fieldError={errors.first_name} ></MymonXValidation>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label column sm="3">
                        Last Name <MymonXReqAsteric />
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="text"
                            placeholder="last name"
                            {...register('last_name', {
                                required: {
                                    value: true,
                                    message: 'last name is required'
                                }
                            })}
                            className={`${!!errors.last_name ? 'has-validation' : ''}`}
                        />
                        <MymonXValidation fieldError={errors.last_name} ></MymonXValidation>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" controlId={`client-create-form.email`}>
                    <Form.Label column sm={12} md={3}>
                        Email <MymonXReqAsteric />
                    </Form.Label>
                    <Col sm={12} md={9}>
                        <Form.Control
                            type="text"
                            placeholder="email"
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: 'email is required'
                                }
                            })}
                            className={`${!!errors.email ? 'has-validation' : ''}`}
                        />
                        <MymonXValidation fieldError={errors.email} ></MymonXValidation>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" controlId={`client-create-form.location`}>
                    <Form.Label column sm={12} md={3}>
                        User Role: <MymonXReqAsteric />
                    </Form.Label>
                    <Col sm={12} md={9}>
                        <Controller
                            name="role"
                            control={control}
                            rules={{ required: "role is required" }}
                            render={({ field }) => (
                                <Form.Select {...field} aria-label="Default select role">
                                    <option>Select Role</option>
                                    {roleDropdown.map((item: RoleDto) => (
                                        <option key={item.sid} value={item.sid}>{item.name}</option>
                                    ))}
                                </Form.Select>
                            )}
                        />
                        <MymonXValidation fieldError={errors.role} />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formSubmitButtons">

                    <Col sm="12" className="text-end">
                        <Button variant="secondary" type="button" onClick={onHide} >
                            Cancel
                        </Button>{' '}
                        <Button variant="primary" type="submit" >
                            Update
                        </Button>
                    </Col>

                </Form.Group>
            </Form>
        </>
    )
}

export default UpdateUserForm;